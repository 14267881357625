export const zslResourceItem = [
    {value: 13, label: "中山陵陵寝预约"},
    {value: 14, label: "中山陵音乐台"},
    {value: 15, label: "音乐台+小交通"},
    {value: 16, label: "音乐台+讲解器租赁"},
    {value: 17, label: "音乐台+小交通+讲解器租赁"},
    {value: 21, label: "中山陵陵寝+中山陵音乐台"},
    {value: 22, label: "中山陵陵寝+音乐台+小交通"},
    {value: 23, label: "中山陵陵寝+音乐台+讲解器租赁"},
    {value: 24, label: "中山陵陵寝+音乐台+小交通+讲解器租赁"},
    {value: 25, label: "小交通"},
    {value: 26, label: "讲解器"}
]

export const zslResourceTypes = function () {
    let obj = {}
    zslResourceItem.forEach(item => {
        obj[item.value] = item.label
    })
    return obj
}

