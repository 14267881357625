export const payWays = [
    {
        label: '微信',
        value: 1
    },
    {
        label: '支付宝',
        value: 2
    },
    {
        label: '余额',
        value: 3
    },
    {
        label: '授信',
        value: 4
    }
]
export const orderPayWays = [
    {
        label: '微信',
        value: 16
    },
    {
        label: '支付宝',
        value: 21
    },
    {
        label: '微信',
        value: 23
    }
]
export const flowTypes = [
    {
        label: '收款',
        value: 1
    },
    {
        label: '退款',
        value: 2
    },
    {
        label: '取消费',
        value: 3
    }
]