<style lang="scss" scoped>
</style>
<template>
    <com-list-search>
        <div slot="list-search-before">
            <el-form :inline="true">
                <el-form-item label="批次"></el-form-item>
                <el-form-item :label="query.date | dateCus('yyyy-MM-dd')"></el-form-item>
                <el-form-item>
                    {{query.status === '1' ? '已确认' : '未确认'}}
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="form.orderId" type="text" placeholder="自订单号 "></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div slot="list-search-after">
            <el-button @click="handleExport" v-if="query.type==='0'">对账单导出</el-button>
        </div>
    </com-list-search>

</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../../components/comListSearch/index.vue'

    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    orderId: ''
                }
            }
        },
        //计算属性
        computed: {
            query() {
                const query = this.$route.query
                query.date = Number(query.date)
                return query;
            }
        },
        components: {comListSearch},
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {
            search() {
                this.$emit('search', this.form)
            },
            handleExport() {
                this.$emit('export', this.form)
            }

        },
        //监听模版变量
        watch: {}

    }
</script>
