<style lang="scss" scoped>
.search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.market-date {
    position: relative;
    margin: 0 10px 10px 0;
    display: inline-block;

    &-after {
        position: absolute;
        //z-index: 1;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        padding-left: 30px;
        color: #606266;
    }

    &-before {
        border-radius: 4px;
        border: 1px solid #DCDFE6;

        &::v-deep {
            .el-input__inner {
                opacity: 0;
            }
        }
    }
}

.market-order-data {
    display: flex;

    .order-data-li {
        margin-right: 15px;
        width: 170px;
    }

    .order-data-box {
        border-radius: 8px;
        height: 85px;
        margin-bottom: 15px;
        padding: 15px 20px;

        h4 {
            font-size: 14px;
            text-align: center;
            font-weight: 600;
        }
    }

    .box-num {
        height: 70px
    }

    .today-num {
        font-size: 22px;
        font-weight: 500;
        height: 26px;
        line-height: 26px;
    }

    .box-compare {
        display: flex;
        font-size: 12px;
        line-height: 16px;
        justify-content: space-between;
    }

    .compare-num {
        color: rgba(0, 0, 0, .3);
    }

    .compare-plus {
        color: #EB7E65;
    }

    .compare-minus {
        color: #54AFAE;
    }

}
</style>


<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.label" :name="item.value">
                <div class="search-box">
                    <div>
                        <div class="market-date">
                            <div class="market-date-after">
                                {{ selectDate }}
                            </div>
                            <el-date-picker
                                class="market-date-before"
                                v-model="form.date"
                                type="date"
                                size="small"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                @change="changeDatePicker"
                                :clearable="false">
                            </el-date-picker>
                        </div>
                        <el-button type="text" @click="handleRefresh(activeName)">刷新 <i
                            :class="loading ? 'el-icon-loading':'el-icon-refresh'"></i></el-button>
                    </div>
                    <el-button type="text" @click="handleGoDataDetails">查看详情</el-button>
                </div>

                <ul class="market-order-data" v-if="activeName === '1'">
                    <li class="order-data-li" v-for="item in orderData" :key="item.value">
                        <div
                            class="order-data-box"
                            :style="{background: item.bgColor}"
                            v-for="list in item.list"
                            :key="list.value"
                        >
                            <div class="box-num">
                                <p class="today-num">{{ list.num }}<span v-if="item.value === '3'">%</span></p>
                                <ul class="box-compare">
                                    <li>较昨日：</li>
                                    <li :class="{'compare-plus':list.yesterdayLift && list.yesterdayNum !== 0,'compare-minus':!list.yesterdayLift && list.yesterdayNum !== 0, 'compare-num':list.yesterdayNum === 0}">
                                        {{ list.yesterdayLift && list.yesterdayNum > 0 ? '+' : '' }}{{
                                            list.yesterdayNum
                                        }}<span v-if="item.value === '3'">%</span>
                                    </li>
                                </ul>
                                <ul class="box-compare">
                                    <li>较上周：</li>
                                    <li :class="{'compare-plus':list.lastWeekLift && list.lastWeekNum !== 0,'compare-minus':!list.lastWeekLift && list.lastWeekNum !== 0, 'compare-num':list.lastWeekNum === 0}">
                                        {{ list.lastWeekLift && list.lastWeekNum > 0 ? '+' : '' }}{{ list.lastWeekNum }}<span
                                        v-if="item.value === '3'">%</span>
                                    </li>
                                </ul>
                            </div>
                            <h4>{{ list.label }}</h4>
                        </div>
                    </li>
                </ul>
                <ve-histogram
                    v-if="activeName === '2'"
                    :data="chartData"
                    :settings="chartSettings"
                    :extend="extend"
                    :colors="bgColorArr">
                </ve-histogram>
            </el-tab-pane>
        </el-tabs>
    </div>

</template>

<script type="text/ecmascript-6">
import dayjs from "dayjs";
// import http from '@/common/js/http'
import VeHistogram from 'v-charts/lib/histogram.common'
import {dateTimeStamp, formatDayjsDate, weekItem} from '@/common/js/dayjsDate/index'
import http from "@/common/js/http";
import {decimalMul} from "@/common/js/decimal";

const tabsList = [
    {
        value: '1',
        label: '订单数据',
    },
    {
        value: '2',
        label: '中山陵票务分时余量监控',
    },
]

const orderDataList = [
    {
        value: '1',
        bgColor: '#E0ECFF',
        label: '团队',
        list: [
            {
                value: '1-1',
                label: '预约团队数(团)'
            },
            {
                value: '1-2',
                label: '应入园团队数(团)'
            },
            {
                value: '1-3',
                label: '实际入园团队数(团)'
            },
        ]
    },
    {
        value: '2',
        bgColor: '#FFEFDF',
        label: '游客',
        list: [
            {
                value: '2-1',
                label: '预约游客数(人)'
            },
            {
                value: '2-2',
                label: '应入园游客数(人)'
            },
            {
                value: '2-3',
                label: '实际入园游客数(人)'
            },
        ]
    },
    {
        value: '3',
        bgColor: '#FFEFDF',
        label: '订单',
        list: [
            {
                value: '3-1',
                label: '订单取消率'
            },
            {
                value: '3-2',
                label: '订单NoShow率'
            },
            {
                value: '3-3',
                label: '订单完成率'
            },
        ]
    },
    {
        value: '4',
        bgColor: '#F2E8FF',
        label: '金额',
        list: [
            {
                value: '4-1',
                label: '订单金额(¥)'
            },
        ]
    },
]

export default {
    name: '',
    data() {
        return {
            tabsList,
            orderDataList,
            pickerOptions: {
                disabledDate: (current) => {
                    return current > dayjs().valueOf() + 60 * 60 * 1000 * 24 * 7
                }
            },
            orderData: [],  // 订单数据大盘展示
            loading: false,
            activeName: '1',
            form: {
                date: dayjs().format(),
            },
            bgColorArr: ['#73A0FA'],
            chartData: {
                columns: ['timePeriod', 'num'],
                rows: []
            },
            chartSettings: {
                labelMap: {
                    timePeriod: '日期',
                    num: '剩余票量'
                },
            },
            extend: {
                series: {
                    type: 'bar',
                    barWidth: 90,

                    label: {show: true, position: "inside", color: '#ffffff',}
                }
            }
        }
    },
    components: {
        VeHistogram
    },
    //计算属性
    computed: {
        selectDate() {
            let date = formatDayjsDate(this.form.date, 'YYYY-MM-DD')
            let week = this.filterFormatDesc(dayjs(date).day(), weekItem)
            console.log(date, week);
            date = date + ' ' + week
            return date
        },
        dateForm() {
            let form = {
                startTime: dateTimeStamp(this.form.date, '00:00:00'),
                endTime: dateTimeStamp(this.form.date, '23:59:59'),
            }
            return form
        }
    },
    //主件被加载完成
    mounted() {
        this.init()
    },
    methods: {
        init() {

            this.handleRefresh(this.activeName)
        },
        handleClick() {
            this.init()
        },
        changeDatePicker(v) {
            this.form.date = v
            this.init()
        },
        handleRefresh(type) {
            switch (type) {
                case '1':
                    this.zslOrderInfoNumData()
                    break;
                case '2':
                    this.zslProductInventory()
                    break
            }
        },
        // 跳转订单数据详情
        handleGoDataDetails() {
            this.$router.push({path: '/zslDataDetails'})
        },
        // + true  -false
        isLift(value) {
            let v = value.toString()
            let s = v.indexOf('-', 0)
            return s === 0 ? false : true
        },
        // 中山陵订单数据大盘
        async zslOrderInfoNumData() {
            this.loading = true
            const res = await http('/galaxyZslTicketOrderApi/zslOrderInfoNumData', this.dateForm, 'POST')
            if (res.success && res.data) {
                let resData = res.data
                let orderDataItem = orderDataList.map(item => {
                    item.list.map(cItem => {
                        switch (cItem.value) {
                            case '1-1':
                                cItem.num = resData.orderTeamNum
                                cItem.yesterdayNum = resData.orderTeamNumYday
                                cItem.yesterdayLift = this.isLift(resData.orderTeamNumYday)
                                cItem.lastWeekNum = resData.orderTeamNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.orderTeamNumLastweek)
                                break;
                            case '1-2':
                                cItem.num = resData.shouldOrderTeamNum
                                cItem.yesterdayNum = resData.shouldOrderTeamNumYday
                                cItem.yesterdayLift = this.isLift(resData.shouldOrderTeamNumYday)
                                cItem.lastWeekNum = resData.shouldOrderTeamNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.shouldOrderTeamNumLastweek)
                                break;
                            case '1-3':
                                cItem.num = resData.actualOrderTeamNum
                                cItem.yesterdayNum = resData.actualOrderTeamNumYday
                                cItem.yesterdayLift = this.isLift(resData.actualOrderTeamNumYday)
                                cItem.lastWeekNum = resData.actualOrderTeamNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.actualOrderTeamNumLastweek)
                                break;
                            case '2-1':
                                cItem.num = resData.orderTouristNum
                                cItem.yesterdayNum = resData.orderTouristNumYday
                                cItem.yesterdayLift = this.isLift(resData.orderTouristNumYday)
                                cItem.lastWeekNum = resData.orderTouristNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.orderTouristNumLastweek)
                                break;
                            case '2-2':
                                cItem.num = resData.shouldOrderTouristNum
                                cItem.yesterdayNum = resData.shouldOrderTouristNumYday
                                cItem.yesterdayLift = this.isLift(resData.shouldOrderTouristNumYday)
                                cItem.lastWeekNum = resData.shouldOrderTouristNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.shouldOrderTouristNumLastweek)
                                break;
                            case '2-3':
                                cItem.num = resData.actualOrderTouristNum
                                cItem.yesterdayNum = resData.actualOrderTouristNumYday
                                cItem.yesterdayLift = this.isLift(resData.actualOrderTouristNumYday)
                                cItem.lastWeekNum = resData.actualOrderTouristNumLastweek
                                cItem.lastWeekLift = this.isLift(resData.actualOrderTouristNumLastweek)
                                break;
                            case '3-1':
                                if (resData.orderCancelRate > 0) cItem.num = decimalMul(resData.orderCancelRate, 100)
                                else cItem.num = 0
                                if (resData.orderCancelRateYday > 0) cItem.yesterdayNum = decimalMul(resData.orderCancelRateYday, 100)
                                else cItem.yesterdayNum = 0
                                cItem.yesterdayLift = this.isLift(resData.orderCancelRateYday)
                                if (resData.orderCancelRateLastweek > 0) cItem.lastWeekNum = decimalMul(resData.orderCancelRateLastweek, 100)
                                else cItem.lastWeekNum = 0
                                cItem.lastWeekLift = this.isLift(resData.orderCancelRateLastweek)
                                break;
                            case '3-2':
                                if (resData.noShowRate > 0) cItem.num = decimalMul(resData.noShowRate, 100)
                                else cItem.num = 0
                                if (resData.noShowRateYday > 0) cItem.yesterdayNum = decimalMul(resData.noShowRateYday, 100)
                                else cItem.yesterdayNum = 0
                                cItem.yesterdayLift = this.isLift(resData.noShowRateYday)
                                if (resData.noShowRateLastweek > 0) cItem.lastWeekNum = decimalMul(resData.noShowRateLastweek, 100)
                                else cItem.lastWeekNum = 0
                                cItem.lastWeekLift = this.isLift(resData.noShowRateLastweek)
                                break;
                            case '3-3':
                                if (resData.orderCompletionRate > 0) cItem.num = decimalMul(resData.orderCompletionRate, 100)
                                else cItem.num = 0
                                if (resData.orderCompletionRateYday > 0) cItem.yesterdayNum = decimalMul(resData.orderCompletionRateYday, 100)
                                else cItem.yesterdayNum = 0
                                cItem.yesterdayLift = this.isLift(resData.orderCompletionRateYday)
                                if (resData.orderCompletionRateLastweek > 0) cItem.lastWeekNum = decimalMul(resData.orderCompletionRateLastweek, 100)
                                else cItem.lastWeekNum = 0
                                cItem.lastWeekLift = this.isLift(resData.orderCompletionRateLastweek)
                                break;
                            case '4-1':
                                cItem.num = resData.orderAmount
                                cItem.yesterdayNum = resData.orderAmountYday
                                cItem.yesterdayLift = this.isLift(resData.orderAmountYday)
                                cItem.lastWeekNum = resData.orderAmountLastweek
                                cItem.lastWeekLift = this.isLift(resData.orderAmountLastweek)
                                break;
                        }
                        return cItem
                    })
                    return item
                })
                this.orderData = orderDataItem
            }

            this.loading = false
        },
        // 获取产品库存
        async zslProductInventory() {
            this.loading = true
            let inventoryDay = formatDayjsDate(this.form.date, 'YYYY-MM-DD')
            // let form = {"inventoryDay":"2021-07-08","resourceId":"24154165156","thirdResourceId":"15r7tlu79q400"}
            let form = {
                inventoryDay
            }
            const res = await http('/galaxyZslTicketOrderApi/getProductInventory', form, 'POST')
            if (res.success && res.data) {
                let list = res.data.map(item => {
                    let obj = {
                        timePeriod: item.starttime + '-' + item.endtime,
                        num: item.num
                    }
                    return obj
                })
                this.chartData.rows = list
            }

            this.loading = false
        }
    },
    watch: {}
}
</script>
