<style lang="scss" scoped>
    .eTrip-section-cont {
        padding: 20px 0;
    }

    .eTrip-footer-cont {
        display: flex;
        justify-content: flex-end;
    }

    /deep/ .common-list-search .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="">
                                <el-select v-model="form.createBy" placeholder="订单来源">
                                    <el-option :label="vm.label" :value="vm.value" v-for="vm in createByTypes"
                                               :key="vm.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="form.type" placeholder="流水类型">
                                    <el-option :label="vm.label" :value="vm.value" v-for="vm in flowTypes"
                                               :key="vm.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="form.payWay" placeholder="支付方式">
                                    <el-option :label="vm.label" :value="vm.value" v-for="vm in payWays"
                                               :key="vm.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-date-picker v-model="form.createDate"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="流水开始"
                                                end-placeholder="流水结束">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="">
                                <el-date-picker v-model="form.orderCreate"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="下单开始"
                                                end-placeholder="下单结束">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="主订单ID" v-model="form.ticketOrderNo"/>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="子订单ID" v-model="form.subOrderId"/>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="旅行社名称" v-model="form.merchantName"/>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="团队编号" v-model="form.groupNo"/>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="resetForm()">重置</el-button>
                        <el-button type="primary" @click="handleSearch" :loading="loading">筛选</el-button>
                        <excel-export-btn
                            v-if="userInfo.roleName === '总部财务'"
                            style="margin-top: 18px"
                            type="primary"
                            title="流水明细导出"
                            fileTitle="流水明细"
                            :form="exportForm"
                            :url="exportTicketFlow"
                        >
                        </excel-export-btn>
                    </div>
                </com-list-search>
                <el-table :data="result.data" style="width: 100%;margin-bottom: 40px">
                    <el-table-column label="日期时间" width="100px">
                        <template slot-scope="scope">
                            {{scope.row.createDate | dateCus}}
                        </template>
                    </el-table-column>
                    <el-table-column label="主订单下单时间" width="100px">
                        <template slot-scope="scope">
                            {{scope.row.orderCreateDate | dateCus}}
                        </template>
                    </el-table-column>
                    <el-table-column label="主订单ID" prop="ticketOrderNo" width="100px">
                    </el-table-column>
                    <el-table-column label="子订单ID">
                        <template slot-scope="scope">
                            {{JSON.parse(scope.row.paySubOrderIdList).join(',')}}
                        </template>
                    </el-table-column>
                    <el-table-column label="订单来源" width="100px">
                        <template slot-scope="scope">
                            {{scope.row.createBy | formatDesc(createByTypes)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="公司名称" prop="merchantName"></el-table-column>
                    <el-table-column label="团队编号" prop="groupNo"></el-table-column>
                    <el-table-column label="流水类型">
                        <template slot-scope="scope">
                            {{scope.row.type | formatDesc(flowTypes)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="支付方式">
                        <template slot-scope="scope">
<!--                            {{scope.row.payWay | formatDesc(payWays)}}-->
                            {{scope.row.paymentChannel | formatDesc(payChannelItem)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="金额（￥）" prop="payAccount"></el-table-column>
                    <el-table-column label="流水号" prop="id"></el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <el-pagination @current-change="handlePaginationChange" :current-page.sync="form.pageIndex"
                               :page-size="form.pageSize" layout="total, prev, pager, next" :total="result.total">
                </el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'
    import {combinationForm} from '@/common/js/common'
    import dataInfo from '@/data/orderData/data'
    import {payWays, flowTypes} from '../data/index'
    import {exportTicketFlow} from '@/www/urls/zslManage'
    import excelExportBtn from '@/components/excel-export-btn/index'

    export default {
        //定义模版数据
        data() {
            return {
                exportTicketFlow,
                loading: false,
                form: {
                    pageIndex: 1,
                    pageSize: 10
                },
                exportForm: {},
                result: {
                    data: [],
                    total: 0
                },
                createByTypes: [
                    {
                        value: 1,
                        label: '导游端'
                    },
                    {
                        value: 0,
                        label: 'TDS'
                    }
                ],
                flowTypes,
                payChannelItem: dataInfo.payChannelItem,
                payWays
            }
        },
        components: {comListSearch, excelExportBtn},
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            }
        },
        //主件被加载完成
        mounted() {
            this.getData()
        },
        //定义事件方法
        methods: {
            async getData() {
                let form = {
                    ...this.form
                }
                if (form.createDate) {
                    form.createDateStart = +new Date(form.createDate[0])
                    form.createDateEnd = +new Date(form.createDate[1]) + (24 * 60 * 60 * 1000) - 1000
                    delete form.createDate
                }
                if (form.orderCreate) {
                    form.orderCreateDateStart = +new Date(form.orderCreate[0])
                    form.orderCreateDateEnd = +new Date(form.orderCreate[1]) + (24 * 60 * 60 * 1000) - 1000
                    delete form.orderCreate
                }
                this.exportForm = form
                this.loading = true
                const ret = await this.http('/galaxyZslTicketOrderApi/flowRecordPageList', combinationForm(form), 'POST')
                if (ret.success && ret.data) {
                    this.result = ret.data
                } else this.result = []
                this.loading = false;
            },
            //分页
            handlePaginationChange(page) {
                this.form.pageIndex = page;
                this.getData();
            },
            //重置
            resetForm() {
                this.form = {
                    pageIndex: this.form.pageIndex,
                    pageSize: this.form.pageSize
                }
            },
            //筛选
            handleSearch() {
                this.form.pageIndex = 1;
                this.getData();
            }
        },
        //监听模版变量
        watch: {}

    }
</script>