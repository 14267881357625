<template>
    <el-dialog title="积分明细" :visible.sync="visible" width="600px">
        <p style="margin-bottom: 20px">旅行社名称：{{ travelAgencyInfo.companyName }}</p>
        <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
            <p><b>当前等级：{{ travelAgencyInfo.creditGrade | formatDesc(creditRatingOptions) }}</b></p>
            <p><b>当前积分：{{ travelAgencyInfo.creditScore }}分</b></p>
        </div>
        <el-table border :data="tableData.data" height="calc(100vh - 500px)">
            <el-table-column label="积分变更项目">
                <template slot-scope="scope">
                    <div style="display: flex">
                        <div style="font-size: 11px;line-height: 2em;flex: 1">
                            <p style="font-size: 14px">{{ scope.row.name }}</p>
                            <p>{{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm') }} </p>
                            <p v-if="expandRowId === scope.row.id">
                                {{ scope.row.info }}
                            </p>
                        </div>
                        <el-button type="text" size="mini" @click="handleExpand(scope.row)"
                                   style="color: #999;font-weight: normal;font-size: 10px;margin-left: 20px">
                            {{ expandRowId === scope.row.id ? '收起' : '展开' }}<i class="icon"
                                                                                   :class="expandRowId === scope.row.id?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                        </el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="120px" label="积分变更数" prop="score" align="right">
                <template slot-scope="scope">
                    <span
                        :class="scope.row.score>0?'color-success':'color-error'">{{
                            scope.row.score > 0 ? '+' : ''
                        }}{{ scope.row.score }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: flex-end">
            <el-pagination :total="tableData.total" :page-size="form.pageSize"
                           :current-page="form.pageIndex"
                           :pager-count="5"
                           @current-change="handlePageChange"></el-pagination>
        </div>
    </el-dialog>
</template>
<script>
import {defineComponent} from 'vue'
import {creditRatingOptions} from '@/data/creditRating'
import {getCreditDetailListApi} from "@/www/urls/credit";

export default defineComponent({
    name: "NonPerformanceRecordsDialog",
    data() {
        return {
            visible: false,
            loading: false,
            creditRatingOptions,
            form: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: {
                total: 0,
                data: []
            },
            expandRowId: '',
            travelAgencyInfo: {}
        }
    },
    methods: {
        show(record) {
            this.travelAgencyInfo = record;
            this.visible = true;
            this.form.id = record.creditId;
            this.getCreditDetailList();
        },
        handleExpand(row) {
            this.expandRowId = this.expandRowId === row.id ? '' : row.id
        },
        async getCreditDetailList() {
            try {
                this.loading = true
                const ret = await getCreditDetailListApi(this.form);
                if (ret.success) {
                    this.tableData = ret.data;
                }
            } finally {
                this.loading = false
            }
        },
        handlePageChange(page) {
            this.form.pageIndex = page
            this.getCreditDetailList();
        }
    }
})
</script>

<style scoped lang="scss">

</style>
