<style lang="scss" scoped>
.item-date-width{
    margin-right: 10px;
    width: 260px;
}
.search-date{
    .el-button {
        color: gray;
        padding: 0 5px;
        &.select {
            color: #319BF7;
        }
    }
}
</style>


<template>
    <div class="search-date">
        <el-date-picker
            class="item-date-width"
            v-model="form.salesDate"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="截止日期"
            size="small"
            :picker-options="pickerOptions"
            @change="changeDatePicker"
            :clearable="false">
        </el-date-picker>
        <el-button
            type="text"
            v-for="item in dateBtnItem"
            :key="item.value"
            :class="{'select' : dateSelect === item.value}"
            @click="handleDateBtn(item)">
            {{ item.label }}
        </el-button>
    </div>

</template>

<script type="text/ecmascript-6">

import {
    dateTimeStamp,
    lastMonthArr,
    latestMonthArr,
    latestWeekArr,
    thisMonthArr,
    yesterdayArr
} from "@/common/js/dayjsDate";
const dateBtnList = [{value: 0, label: '近7日'}, {value: 1,label: '近30日'}, {value: 2,label: '本月'}, {value: 3,label: '上月'},]
export default {
    name: '',
    data() {
        return {
            dateSelect: 0,
            pickerOptions: {
                disabledDate: (current) => {
                    return current > +new Date()
                }
            },
            form: {
                salesDate: latestWeekArr()
            },
        }
    },
    props: {
        isYesterday: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {
        dateBtnItem(){
            let item = dateBtnList
            let yesterdayItem = [{
                value: 4,
                label: '昨日'
            }]
            if(this.isYesterday) item = [...yesterdayItem, ...item]
            return item
        }
    },
    //主件被加载完成
    mounted() {
    },
    methods: {
        handleDateBtn(item) {
            this.dateSelect = item.value
            switch (item.value) {
                case 0:
                    this.form.salesDate = latestWeekArr()
                    break;
                case 1:
                    this.form.salesDate = latestMonthArr()
                    break;
                case 2:
                    this.form.salesDate = thisMonthArr()
                    break;
                case 3:
                    this.form.salesDate = lastMonthArr()
                    break
                case 4:
                    this.form.salesDate = yesterdayArr()
                    break
            }
            this.$emit('update:dateBtn', this.form.salesDate)
        },

        changeDatePicker(arr){
            this.dateSelect = ''
            let date = [dateTimeStamp(arr[0], '00:00:00'), dateTimeStamp(arr[1], '23:59:59')]
            this.form.salesDate = date
            this.$emit('update:dateBtn', this.form.salesDate)
        }
    },
    watch: {},
}
</script>
