<style lang="scss" scoped>
.item-date-width{
    margin-right: 10px;
    width: 260px;
}
.date-btn{
    &.el-button {
        color: gray;
        padding: 0 5px;
        &.select {
            color: #319BF7;
        }
    }
}
.caption-btn{
    margin-left: 35px;
    color: gray;
}
</style>


<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleTabs">
            <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.label" :name="item.value">
                <el-date-picker
                    class="item-date-width"
                    v-model="form.salesDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="起始日期"
                    end-placeholder="截止日期"
                    :picker-options="pickerOptions"
                    size="small"
                    @change="changeDatePicker"
                    :clearable="false">
                </el-date-picker>
                <el-button
                    class="date-btn"
                    type="text"
                    v-for="item1 in dateBtnList"
                    :key="item1.value"
                    :class="{'select' : dateSelect === item1.value}"
                    @click="handleDateBtn(item1)">
                    {{ item1.label }}
                </el-button>
                <el-popover
                    placement="bottom-start"
                    width="230"
                    trigger="hover">
                    <div>
                        <p class="mb5" v-for="(textItem, textIdx) in item.instructions" :key="textIdx">{{ textItem }}</p>
                    </div>
                    <el-button slot="reference" type="text" class="caption-btn">图表说明 <i class="el-icon-warning-outline"></i></el-button>
                </el-popover>
<!--                <el-button @click="handleSearch">hahah</el-button>-->
                <excel-export-btn
                    style="margin-left: 35px"
                    type="primary"
                    size="small"
                    title="导出报表"
                    :form="dateForm"
                    :fileTitle="item.label"
                    :url="item.reportUrl"
                >
                </excel-export-btn>
                <div class="mt10">
                    <rank-table :obj="item" :list="list" :loading="loading"></rank-table>
                </div>
            </el-tab-pane>
        </el-tabs>
<!--        <pagination-foot-->
<!--            style="border-top: 0"-->
<!--            ref="paginationFoot"-->
<!--            :total="pageInfo.total"-->
<!--            @update:pageInfo="handlePageInfo">-->
<!--        </pagination-foot>-->
    </div>

</template>

<script type="text/ecmascript-6">
import http from '@/common/js/http'
import {dateTimeStamp, lastMonthArr, thisMonthArr} from "@/common/js/dayjsDate";
import excelExportBtn from '@/components/excel-export-btn/index'
import rankTable from './components/rank-table/index'
// import paginationFoot from '@/components/pagination-foot/index'

const dateBtnList = [{value: 1, label: '本月'}, {value: 2, label: '上月'},]
const tabsList = [
    {
        value: '1',
        label: '旅行社地域',
        instructions: [
            '数量排名：所选日期范围内，旅行社注册城市数量排名情况',
            '占比，即整体占比，公式：上述数量/平台旅行社总数*100%'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/getOrgInfoLeaderboardForCity',
        reportUrl: '/galaxyZslTicketOrderApi/exportGalaxyOrgInfoNumForCity',
    },
    {
        value: '2',
        label: '旅行社实际入园团队数',
        instructions: [
            '实际入园团队数，指当前旅行社在指定日期入园且“已完成”的订单数量',
            '整体占比，公式：实际入园团队数/该时段所有旅行社 实际入园团队数合计*100%，保留2位小数'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/zslOrderInfoTeamList',
        reportUrl: '/galaxyZslTicketOrderApi/exportZslOrderInfoTeamList',
    },
    {
        value: '3',
        label: '旅行社实际入园游客数',
        instructions: [
            '实际入园游客数：指当前旅行社在指定日期入园且“已完成”的订单中已核销的游客数量',
            '游客总数，指当前旅行社在指定日期入园的有效订单所包含的游客总数',
            '入园数占比，公式：实际入园游客数/游客总数*100%，保留2位小数',
            '整体占比，公式：实际入园游客数/该时段所有旅行社 实际入园的游客数合计*100%，保留2位小数'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/zslOrderInfoTouristNumList',
        reportUrl: '/galaxyZslTicketOrderApi/exportZslOrderInfoTouristNumList',
    },
    {
        value: '4',
        label: '旅行社订单金额',
        instructions: [
            '有效订单金额，指当前旅行社在指定日期下单且状态“待使用+已完成+已过期”的订单总额',
            '整体占比，公式：有效订单金额/所有旅行社的有效订单金额合计*100%，保留2位小数'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/zslOrderInfoAmountList',
        reportUrl: '/galaxyZslTicketOrderApi/exportZslOrderInfoAmountList',
    },
    {
        value: '5',
        label: '旅行社订单取消',
        instructions: [
            '已取消订单数，指当前旅行社在指定日期入园且订单状态为“已取消”的订单数量',
            '订单总数，指当前旅行社在指定日期入园的订单总数量',
            '取消占比，公式：已取消订单数/订单总数*100%，保留2位小数',
            '整体占比，公式：已取消订单数/所有旅行社已取消订单数的合计*100%，保留2位小数'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/zslOrderInfoCancelList',
        reportUrl: '/galaxyZslTicketOrderApi/exportZslOrderInfoCancelList',
    },
    {
        value: '6',
        label: '旅行社履约情况',
        instructions: [
            '已使用订单数，即当前旅行社在指定时段入园且订单状态为“已使用”的订单数量',
            '有效订单总数，指当前旅行社在指定日期入园的订单总数量',
            '履约情况，公式：已使用订单数/有效订单总数*100%，保留2位小数'
        ],
        tableUrl: '/galaxyZslTicketOrderApi/zslOrderInfoCompletionList',
        reportUrl: '/galaxyZslTicketOrderApi/exportZslOrderInfoCompletionList',
    },
]

export default {
    name: '',
    data() {
        return {
            dateSelect: 1,
            pickerOptions: {
                disabledDate: (current) => {
                    return current > +new Date()
                }
            },
            form: {
                salesDate: thisMonthArr()
            },
            dateBtnList,
            tabsList,
            activeName: '1',
            list: [],
            loading: false,
            pageInfo: {},
        }
    },
    components: {
        excelExportBtn,
        rankTable,
        // paginationFoot
    },
    //计算属性
    computed: {
        dateForm() {
            let form = {
                startTime: this.form.salesDate[0],
                endTime: this.form.salesDate[1],
            }
            return form
        }
    },
    //主件被加载完成
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.handleRank(this.activeName)
        },
        // handleSearch() {
        //     this.$refs.paginationFoot.changeCurrent(1)
        // },
        // handlePageInfo(pageInfo) {
        //     this.pageInfo = {
        //         ...this.pageInfo,
        //         ...pageInfo
        //     }
        //     // this.searchClick();
        // },

        handleDateBtn(item) {
            this.dateSelect = item.value
            switch (item.value) {
                case 1:
                    this.form.salesDate = thisMonthArr()
                    break;
                case 2:
                    this.form.salesDate = lastMonthArr()
                    break;
            }
            this.init()
        },
        changeDatePicker(arr){
            let date = [dateTimeStamp(arr[0], '00:00:00'), dateTimeStamp(arr[1], '23:59:59')]
            this.form.salesDate = date
            this.init()
        },
        handleTabs() {
            this.init()
        },
        // 获取旅行社排行  旅行社地域
        async handleRank(index) {
            this.loading = true
            let selectItem = tabsList.find(item => item.value === index)
            let {
                tableUrl
            } = selectItem
            const res = await http(tableUrl, this.dateForm, 'POST')
            if (res.success && res.data) {
                this.list = res.data
            }
            this.loading = false
        }
    },
    watch: {},
}
</script>
