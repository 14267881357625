<style lang="scss" scoped>

</style>


<template>
    <div>
        <rank-table1 v-if="obj.value === '1'" :list="list" :loading="loading"></rank-table1>
        <rank-table2 v-if="obj.value === '2'" :list="list" :loading="loading"></rank-table2>
        <rank-table3 v-if="obj.value === '3'" :list="list" :loading="loading"></rank-table3>
        <rank-table4 v-if="obj.value === '4'" :list="list" :loading="loading"></rank-table4>
        <rank-table5 v-if="obj.value === '5'" :list="list" :loading="loading"></rank-table5>
        <rank-table6 v-if="obj.value === '6'" :list="list" :loading="loading"></rank-table6>
    </div>

</template>

<script type="text/ecmascript-6">
import rankTable1 from './rank-table1'
import rankTable2 from './rank-table2'
import rankTable3 from './rank-table3'
import rankTable4 from './rank-table4'
import rankTable5 from './rank-table5'
import rankTable6 from './rank-table6'

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        obj: {
            type: Object,
            default: () => ({})
        },
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        rankTable1,
        rankTable2,
        rankTable3,
        rankTable4,
        rankTable5,
        rankTable6,
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
