export const creditRatingOptions = [
    {
        label: '优秀',
        value: 1,
        key: 'excellent',
        color: 'color-success'
    },
    {
        label: '良好',
        value: 2,
        key: 'good',
    },
    {
        label: '一般',
        value: 3,
        key: 'fair'
    },
    {
        label: '不佳',
        value: 4,
        key: 'poor',
        color: 'color-danger'
    }
]

export const creditStatusOptions = [
    {
        label: '已上线',
        value: 1
    },
    {
        label: '已下线',
        value: 2
    }
]

export const customerServiceInterventionOrderStatus = [
    {
        label: '待审核',
        value: 74,
        color: 'color-danger'
    },
    {
        label: '待确认',
        value: 75,
        color: 'color-waring'
    },
    {
        label: '未通过',
        value: 76,
        color: 'color-error'
    },
    {
        label: '已通过',
        value: 99
    },
]

export const sexs = [
    {
        label: "男",
        value: 1
    },
    {
        label: "女",
        value: 0
    }
]

// 证件类型
export const certificateList = [
    {value: 1, label: '身份证'},
    {value: 20, label: '港澳通行证'},
    {value: 2, label: '护照（外籍）'},
    {value: 4, label: '军官证'},
    {value: 5, label: '士兵证'},
    {value: 8, label: '台胞证'},
    {value: 26, label: '导游证'}
];

