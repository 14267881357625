<style lang="sass" scoped>
</style>
<template>
    <div>
        <el-input size="mini" v-if="query.type==='1'" @blur="handleBlur" v-model="form.value"></el-input>
        <span v-else>
            {{form.value}}
        </span>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    value: ''
                }
            }
        },
        props: {
            type: {
                type: Number,
                value: 0
            },
            id: {
                type: String,
                value: ''
            },
            name: {
                type: String,
                value: ''
            },
            value: {
                type: Number,
                value: null
            }
        },
        //计算属性
        computed: {
            query() {
                const query = this.$route.query
                query.date = Number(query.date)
                return query;
            }
        },
        //主件被加载完成
        mounted() {
            this.form.value = this.value || '';
        },
        //定义事件方法
        methods: {
            handleBlur() {
                let form = {id: this.id};
                form[this.name] = this.form.value;
                this.$emit('input', this.form.value)
                this.submit(form)
            },
            async submit(form) {
                let url = ''
                switch (this.type) {
                    //更新检票记录
                    case 0:
                        url = '/galaxyZslTicketOrderApi/updateScanQrConfirmNum'
                        break;
                    //更新小结
                    case 1:
                        url = '/galaxyZslTicketOrderApi/updateScanAccountDetailConfirmNum';
                        break;
                    //更新对账管理
                    case 2:
                        url = '/galaxyZslTicketOrderApi/updateTicketScanAccountManager'
                        break;
                }
                const ret = await this.http(url, form, 'POST');
                if (ret.success && ret.data) {
                    this.$message.success('数据已保存')
                } else {
                    this.$message.error(ret.errors[0].message || '修改失败！')
                }
            }
        },
        //监听模版变量
        watch: {
            value: function (n) {
                console.log(n);
            }
        }

    }
</script>
