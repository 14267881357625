<template>
    <el-main class="com-eTrip-section section-has-footer booking-and-modification-rules">
        <div class="eTrip-section-cont">
            <el-form size="small" :model="form" ref="formRef" v-loading="loading" :disabled="!editable">
                <fieldset>
                    <h4>1、团队人数上限</h4>
                    <el-form-item v-for="vm in creditRatingOptions" :key="vm.value"
                                  :prop="'totalPeople.'+[vm.key]"
                                  label-width="200px"
                                  :rules="[{required:true,message:'请输入值'}]">
                        <span class="mr10" slot="label">{{ vm.label + '：团队人数上限不超过' }}</span>
                        <el-input-number style="width: 200px" v-model="form.totalPeople[vm.key]"
                                         :min="1"></el-input-number>
                        <span class="ml10">人</span>
                    </el-form-item>
                </fieldset>
                <fieldset>
                    <h4>2、可下单时间</h4>
                    <div v-for="vm in creditRatingOptions" :key="vm.value">
                        <div v-if="vm.label==='不佳'">
                            <span>不佳：不可预约陵寝</span>
                        </div>
                        <div style="display:flex;align-items: center" v-else>
                            <span class="mb15">{{ vm.label }}：</span>
                            <el-form-item :prop="'createOrderTime.'+vm.key+'.day'"
                                          :rules="[{required:true,message:'请选择'}]">
                                <ApiSelect :options="advanceDaysOptions"
                                           filterable
                                           clearable
                                           v-model="form.createOrderTime[vm.key].day"></ApiSelect>
                            </el-form-item>
                            <el-form-item class="ml10"
                                          :prop="'createOrderTime.'+vm.key+'.your'"
                                          :rules="[{required:true,message:'请选择'}]">
                                <ApiSelect :options="hoursOptions"
                                           filterable
                                           clearable
                                           v-model="form.createOrderTime[vm.key].your"></ApiSelect>
                            </el-form-item>
                            <span class="mb15  ml10 mr10">前可订，可提前</span>
                            <el-form-item :prop="'createOrderTime.'+vm.key+'.inAdvance'"
                                          :rules="[{required:true,message:'请选择'}]">
                                <ApiSelect :options="daysOptions"
                                           filterable
                                           clearable
                                           v-model="form.createOrderTime[vm.key].inAdvance"></ApiSelect>
                            </el-form-item>
                            <span class="mb15  ml10 mr10">预约陵寝</span>
                        </div>
                    </div>
<!--                    <div class="mt20" style="display: flex">-->
<!--                        <h5 class="mb10">取消后预约受限的场景</h5>-->
<!--                        <div style="display: flex;align-items: center">-->
<!--                            <span class="mb15  mr10">○ 场景一：最近</span>-->
<!--                            <el-form-item prop="cancel.limitTimeA" :rules="[{required:true,message:'请选择'}]">-->
<!--                                <el-select style="width: 120px" filterable v-model="form.cancel.limitTimeA">-->
<!--                                    <el-option v-for="vm in 99" :key="vm" :value="vm">{{ vm }}</el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                            <span class="mb15  ml10 mr10">小时有操作取消订单，且该订单取消时间≥该订单无责取消最晚时间，且该订单的入园日期和时段与当前订单所要预约的目标日期和时段均一致</span>-->
<!--                        </div>-->
<!--                        <div style="display: flex;align-items: center">-->
<!--                            <span class="mb15  mr10"> ○ 场景二：最近</span>-->
<!--                            <el-form-item prop="cancel.limitTimeB" :rules="[{required:true,message:'请选择'}]">-->
<!--                                <el-select style="width: 120px" filterable v-model="form.cancel.limitTimeB">-->
<!--                                    <el-option v-for="vm in 99" :key="vm" :value="vm">{{ vm }}</el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                            <span class="mb15  mr10 ml10">小时有操作取消订单，且该订单取消时间＜该订单无责取消最晚时间，且该订单的入园日期和时段与当前订单所要预约的目标日期和时段均一致</span>-->
<!--                        </div>-->
<!--                    </div>-->
                </fieldset>
                <fieldset>
                    <h4>3、团队查重</h4>
                    <el-form-item v-for="vm in creditRatingOptions"
                                  :key="vm.value"
                                  :prop="'groupRepeat.'+[vm.key]"
                                  label-width="200px"
                                  :rules="[{required:true,message:'请输入值'}]">
                        <span class="mr10" slot="label">{{ vm.label + '：团队重复率不超过' }}</span>
                        <el-input-number style="width: 200px" v-model="form.groupRepeat[vm.key]"
                                         :min="0" :max="100"></el-input-number>
                        <span class="ml10">%</span>
                    </el-form-item>
                </fieldset>
                <fieldset>
                    <h4>
                        4、无责取消
                        <font style="font-size: 13px" class="color-danger">此处规则修改后，将于每周一0时生效</font>
                    </h4>
                    <div v-for="vm in creditRatingOptions" :key="vm.value">
                        <div style="display:flex;align-items: center">
                            <span class="mb15 mr10">{{ vm.label }}：单日可无责取消</span>
                            <el-form-item :prop="'cancel.'+vm.key+'.cancelCount'"
                                          :rules="[{required:true,message:'请选择'}]">
                                <ApiSelect :options="cancelNumOptions"
                                           filterable
                                           clearable
                                           v-model="form.cancel[vm.key].cancelCount"></ApiSelect>
                            </el-form-item>
                            <span class="mb15  ml10 mr10"> ，每周可额外无责取消</span>
                            <el-form-item :prop="'cancel.'+vm.key+'.weekCancelCount'"
                                          :rules="[{required:true,message:'请选择'}]">
                                <ApiSelect :options="cancelNumOptions"
                                           filterable
                                           clearable
                                           v-model="form.cancel[vm.key].weekCancelCount"></ApiSelect>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center">
                        <span class="mb15 mr10"> 无责取消最晚时间：入园</span>
                        <el-form-item prop="cancel.lastCancelDay" :rules="[{required:true,message:'请选择'}]">
                            <ApiSelect disabled filterable :options="lastCancelDays" v-model="form.cancel.lastCancelDay"></ApiSelect>
                        </el-form-item>
                        <span class="mb15  ml10 mr10">天</span>
                        <el-form-item prop="cancel.lastCancelHour" :rules="[{required:true,message:'请选择'}]">
                            <ApiSelect filterable :options="lastCancelHours" v-model="form.cancel.lastCancelHour"></ApiSelect>
                        </el-form-item>
                        <span class="mb15  ml10 mr10">时</span>
                    </div>
                </fieldset>
                <fieldset>
                    <h4>5、删改订单</h4>
                    <div v-for="vm in creditRatingOptions" :key="vm.value">
                        <div style="display:flex;align-items: center">
                            <span class="mb15 mr10">{{ vm.label }}：每订单入园时段可修改</span>
                            <el-form-item :prop="'updateRule.'+vm.key+'.timeUpdateCount'"
                                          :rules="[{required:true,message:'请输入'}]">
                                <el-input-number style="min-width: 130px"
                                                 :min="0"
                                                 v-model="form.updateRule[vm.key].timeUpdateCount"></el-input-number>
                            </el-form-item>
                            <span class="mb15  ml10 mr10"> 次 ，每订单（含子订单）游客信息可删改</span>
                            <el-form-item :prop="'updateRule.'+vm.key+'.tripUserUpdateCount'"
                                          :rules="[{required:true,message:'请输入'}]">
                                <el-input-number style="min-width: 130px"
                                                 :min="0"
                                                 v-model="form.updateRule[vm.key].tripUserUpdateCount"></el-input-number>
                            </el-form-item>
                            <span class="mb15  ml10 mr10">次 ，删改比例不超过</span>
                            <el-form-item :prop="'updateRule.'+vm.key+'.updateRatio'"
                                          :rules="[{required:true,message:'请输入'}]">
                                <el-input-number style="min-width: 130px" v-model="form.updateRule[vm.key].updateRatio"
                                                 :min="0" :max="100"></el-input-number>
                            </el-form-item>
                            <span class="mb15 ml10">%</span>
                        </div>
                    </div>
                </fieldset>
            </el-form>
        </div>
        <el-footer class="footer-bar">
            <p>预订和修改规则编辑</p>
            <el-button v-if="editable" type="primary" style="width: 200px" @click="handleSubmit" :loading="loading">
                保存
            </el-button>
            <el-button v-else type="primary" style="width: 200px" @click="handleEdit" :loading="loading">
                编辑
            </el-button>
        </el-footer>
    </el-main>
</template>
<script>
import {defineComponent} from 'vue'
import {creditRatingOptions} from '@/data/creditRating'
import {createCreditRuleApi, selectCreditRuleApi, updateCreditRuleApi} from "@/www/urls/credit";

export default defineComponent({
    data() {
        return {
            loading: false,
            editable: false,
            advanceDaysOptions: '入园当天、入园前1日、入园前2日、入园前3日、入园前4日、入园前5日、入园前6日'.split('、').map((label, value) => ({
                label,
                value
            })),
            lastCancelHours: new Array(24).fill('').map((item, i) => ({
                label: i,
                value: i
            })),
            lastCancelDays: new Array(4).fill('').map((item, i) => ({
                label: i === 0 ? '当天' : `前${i}天`,
                value: i
            })),
            hoursOptions: new Array(24).fill('').map((item, i) => ({
                label: `${i + 1}时`,
                value: i + 1
            })),
            daysOptions: new Array(14).fill('').map((item, i) => ({
                label: `${i + 1}天`,
                value: i + 1
            })),
            cancelNumOptions: new Array(100).fill('').map((item, i) => ({
                label: `${i}次`,
                value: i
            })),
            creditRatingOptions,
            form: {
                totalPeople: {// 团队人数
                    excellent: undefined,
                    good: undefined,
                    fair: undefined,
                    poor: undefined
                },
                createOrderTime: {//可下单时间
                    excellent: {
                        day: undefined,//   提前时间
                        your: undefined,//  提前小时
                        inAdvance: undefined// 提前预约
                    },
                    good: {
                        day: undefined,//   提前时间
                        your: undefined,//  提前小时
                        inAdvance: undefined// 提前预约
                    },
                    fair: {
                        day: undefined,//   提前时间
                        your: undefined,//  提前小时
                        inAdvance: undefined// 提前预约
                    },
                    // poor: {
                    //     day: undefined,//   提前时间
                    //     your: undefined,//  提前小时
                    //     inAdvance: undefined// 提前预约
                    // }
                },
                groupRepeat: {//团队查重
                    excellent: undefined,
                    good: undefined,
                    fair: undefined,
                    poor: undefined
                },
                cancel: {
                    excellent: {
                        cancelCount: undefined,//    每日无责
                        weekCancelCount: undefined//  周无责
                    },
                    good: {
                        cancelCount: undefined,//    每日无责
                        weekCancelCount: undefined//  周无责
                    },
                    fair: {
                        cancelCount: undefined,//    每日无责
                        weekCancelCount: undefined//  周无责
                    },
                    poor: {
                        cancelCount: undefined,//    每日无责
                        weekCancelCount: undefined//  周无责
                    },
                    lastCancelDay: 1, //最晚取消天
                    lastCancelHour: undefined, //最晚取消小时
                    limitTimeA: undefined,
                    limitTimeB: undefined,
                },
                updateRule: {
                    excellent: {
                        timeUpdateCount: undefined,//   更新时间次数
                        tripUserUpdateCount: undefined,// 更新人次数
                        updateRatio: undefined// 更新比例
                    },
                    good: {
                        timeUpdateCount: undefined,//   更新时间次数
                        tripUserUpdateCount: undefined,// 更新人次数
                        updateRatio: undefined// 更新比例
                    },
                    fair: {
                        timeUpdateCount: undefined,//   更新时间次数
                        tripUserUpdateCount: undefined,// 更新人次数
                        updateRatio: undefined// 更新比例
                    },
                    poor: {
                        timeUpdateCount: undefined,//   更新时间次数
                        tripUserUpdateCount: undefined,// 更新人次数
                        updateRatio: undefined// 更新比例
                    }
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const ret = await this.getData()
            if (ret && this.$route.query.type === '1')
                this.editable = true;
        },
        async getData() {
            try {
                const ret = await selectCreditRuleApi();
                if (ret.success) {
                    let f = {};
                    for (let key in ret.data) {
                        try {
                            f[key] = JSON.parse(ret.data[key]);
                        } catch (err) {
                            f[key] = ret.data[key]
                        }
                    }
                    this.credit_rule = f;
                    if (this.credit_rule.id)
                        this.form = Object.assign({}, this.form, this.credit_rule);
                    return true
                } else {
                    this.$message.error(ret.errors[0].message)
                    return false
                }
            } catch (err) {
                this.$message.error(err.toString())
                return false
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            this.loading = true
            try {
                let f = {};
                for (let key in this.form) {
                    if (typeof this.form[key] === 'object')
                        f[key] = JSON.stringify(this.form[key]);
                    else
                        f[key] = this.form[key]
                }
                await this.$refs['formRef'].validate();
                await this.$confirm('保存后立即生效', '确认保存？')
                if (this.credit_rule)
                    await updateCreditRuleApi(f)
                else
                    await createCreditRuleApi(f)
                this.$message.success('保存成功！')
                this.editable = false;
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        handleEdit() {
            this.editable = true;
            this.getData();
        }
    }
})
</script>


<style scoped lang="scss">
.booking-and-modification-rules {
    fieldset {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 20px;
        padding-left: 45px;

        h4 {
            margin-bottom: 10px;
            margin-left: -25px;
        }

        span {
            font-size: 14px;
            color: #333;
        }

    }

    .footer-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.06);
        width: calc(100% + 60px);
        height: 100%;
        margin-left: -30px;
        padding: 0 30px;
    }
}
</style>
