<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table
            :data="listArr"
            v-loading="loading"
            border
            style="width: auto;display: inline-block"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80">
            </el-table-column>
            <el-table-column
                prop="orgName"
                label="旅行社名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="actualOrderTeamNum"
                label="已使用订单数"
                width="180">
            </el-table-column>
            <el-table-column
                prop="shouldOrderTeamNum"
                label="有效订单总数"
                width="180">
            </el-table-column>
            <el-table-column
                label="履约情况"
                width="180">
                <template slot-scope="scope">
                    <span>{{scope.row.orderCompletionRate + '%'}}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">

import {decimalMul} from "@/common/js/decimal";

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {
        listArr() {
            let data = this.list.map(item => {
                if (item.orderCompletionRate > 0) item.orderCompletionRate = decimalMul(item.orderCompletionRate, 100)
                else item.orderCompletionRate = 0
                return item
            })
            return data
        }
    },
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
