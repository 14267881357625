<style lang="scss" scoped>
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont order-list">
            <report-container
                title="实时大盘"
            >
                <real-time-market></real-time-market>
            </report-container>
            <report-container
                title="各产品日销量动态图"
            >
                <panel-count-num></panel-count-num>
            </report-container>
            <report-container
                title="产品月销售报表"
            >
                <monthly-sales-report></monthly-sales-report>
            </report-container>
            <report-container
                title="旅行社概况"
            >
                <travel-agency-profile></travel-agency-profile>
            </report-container>
            <report-container
                title="旅行社排行"
            >
                <travel-agency-rank></travel-agency-rank>
            </report-container>
            <!--            <report-container-->
            <!--                title="旅行社月度报表"-->
            <!--            >-->
            <!--                <monthly-agency-report></monthly-agency-report>-->
            <!--            </report-container>-->
            <!--            <report-container-->
            <!--                title="目前各产品累计总销量"-->
            <!--                sub-title="(按预订份数统计)"-->
            <!--            >-->
            <!--                <total-sales-volume></total-sales-volume>-->
            <!--            </report-container>-->
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
import ReportContainer from './components/report-container/index'
import MonthlySalesReport from './monthly-sales-report.vue'
// import BrokenlineDiagram from './brokenline-diagram.vue'
// import MonthlyAgencyReport from './monthly-agency-report.vue'
// import TotalSalesVolume from './total-sales-volume.vue'
import PanelCountNum from './panel-count-num.vue'
import TravelAgencyProfile from './travel-agency-profile.vue'
import TravelAgencyRank from './travel-agency-rank.vue'
import RealTimeMarket from './real-time-market.vue'

export default {
    //定义模版数据
    data() {
        return {}
    },
    //计算属性
    computed: {},
    components: {
        ReportContainer,
        PanelCountNum,
        MonthlySalesReport,
        // BrokenlineDiagram,
        // MonthlyAgencyReport,
        // TotalSalesVolume,
        TravelAgencyProfile,
        TravelAgencyRank,
        RealTimeMarket
    },
    //主件被加载完成
    mounted() {
    },
    //定义事件方法
    methods: {},
    //监听模版变量
    watch: {}

}
</script>
