import http from '@/common/js/http'

// 旅行社信息补全通过
export const passCompleted = async (form) => {
    return await http('/galaxyOrgInfoApi/passCompleted', form, "POST");
};

// 旅行社信息补全拒绝
export const refuseCompleted = async (form) => {
    return await http('/galaxyOrgInfoApi/refuseCompleted', form, "POST");
};