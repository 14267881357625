import http from '@/common/js/http'

/**
 * 中山陵-bms景区订单列表流水导出
 **/
export const exportTicketFlow = '/galaxyZslTicketOrderApi/exportTicketFlowRecordList'


/**
 * 中山陵-客服介入列表
 **/
export const getListTicketCustomerOrderinfoListApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/listTicketCustomerOrderInfoList', form, "POST");
};

/**
 * 中山陵-重复率查询
 **/
export const repeatGroupCheckApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/repeatGroupCheck', form, "POST");
};

/**
 * 中山陵-客服介入详情
 **/
export const getTicketCustomerOrderDetailApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/ticketCustomerOrderDetail', form, "POST");
};
/**
 * 中山陵-审核
 **/
export const confirmTicketCustomerOrderApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/confirmTicketCustomerOrder', form, "POST");
};
/**
 * 中山陵-审核
 **/
export const refuseTicketCustomerOrderApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/refuseTicketCustomerOrder', form, "POST");
};
/**
 * 中山陵-客服介入二次审核
 **/
export const reconfirmTicketCustomerOrderApi = async (form) => {
    return await http('/galaxyZslTicketOrderApi/reconfirmTicketCustomerOrder', form, "POST");
};
