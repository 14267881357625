<style lang="scss" scoped>
.edit-dialog{
    display: inline-block
}
</style>
<template>
    <div class="container edit-dialog">
        <el-button @click="dialogVisible=true"
                   v-if="info.accountStatus===0||info.accountStatus===1"
                   type="text">编辑
        </el-button>
        <el-dialog :visible.sync="dialogVisible"
                   append-to-body
                   width="600px">
            <div slot="title">
                编辑
                <p style="margin-top: 10px">
                    批次：{{info.accountDate | dateCus('yyyy-MM-dd')}}
                </p>
            </div>
            <div style="padding: 0 16px">
                <el-form ref="form" size="mini" labelWidth="110px" :model="form" :rules="rules" v-loading="loading">
                    <el-form-item>
                        <el-row>
                            <el-col :span="12">
                                结算数量
                            </el-col>
                            <el-col :span="12">
                                金额
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="900游预审：">
                        <el-row>
                            <el-col :span="12">
                                {{info.totalNum}}份
                            </el-col>
                            <el-col :span="12">
                                {{info.orderSettleAmount | currency(1, '¥')}}
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="中山陵预审：">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item prop="zslNum">
                                    <el-input type="number" placeholder="填入数量" v-model="form.zslNum">
                                        <span slot="append">份</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item prop="zslAmount">
                                    <el-input type="number" placeholder="填入金额" v-model="form.zslAmount">
                                        <span slot="append">元</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="确认结算：">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item prop="useNum">
                                    <el-input type="number" placeholder="填入数量" v-model="form.useNum">
                                        <span slot="append">份</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item prop="scanSettleAmount">
                                    <el-input type="number" placeholder="填入金额" v-model="form.scanSettleAmount">
                                        <span slot="append">元</span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="备注说明：">
                        <el-input v-model="form.remark" type="textarea" :rows="2"
                                  placeholder="如有数量不一致，请在此备注说明"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                dialogVisible: false,
                loading: false,
                form: {
                    zslNum: null,
                    zslAmount: null,
                    useNum: null,
                    scanSettleAmount: null
                },
                rules: {
                    zslNum: [
                        {required: true, message: '请输入数量', trigger: 'blur'}
                    ],
                    zslAmount: [
                        {required: true, message: '请填写金额', trigger: 'blur'}
                    ],
                    useNum: [
                        {required: true, message: '请输入数量', trigger: 'blur'}
                    ],
                    scanSettleAmount: [
                        {required: true, message: '请填写金额', trigger: 'blur'}
                    ]
                }
            }
        },
        props: {
            info: {
                type: Object,
                default: () => ({})
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {
            this.setForm();
        },
        //定义事件方法
        methods: {
            handleSubmit() {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketCheckRecordManager', {
                            id: this.info.id,
                            ...this.form
                        }, 'POST')
                        if (ret.success && ret.data) {
                            this.$emit("editSuccess")
                            this.dialogVisible = false;
                        } else this.$message.error(ret.errors[0].message)
                        this.loading = false;
                    }
                })
            },
            setForm() {
                const {orderSettleAmount, totalNum, zslNum, zslAmount, useNum, scanSettleAmount} = this.info;
                this.form = {
                    zslNum: zslNum || totalNum,
                    zslAmount: zslAmount || orderSettleAmount,
                    useNum: useNum || totalNum,
                    scanSettleAmount: scanSettleAmount || orderSettleAmount
                }
            }
        },
        watch: {
            info() {
                this.setForm();
            }
        }
    }
</script>