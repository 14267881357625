<style lang="scss" scoped>
    /deep/ .show:last-child {
        background-color: #FFEBEA;
    }
</style>

<template>

    <div style="margin-top: 10px">
        <p v-if="listData.length===0"
           style="font-size: 16px;text-align: center;color: #999;padding: 40px">
            暂无数据
        </p>
        <div v-else v-for="(vm,index) in listData" :key="vm.id">
            <el-table
                    :row-class-name="vm.show?'show':''"
                    :data="vm.accountBackendDtoList"
                    border
                    :highlight-current-row="false"
                    size="mini"
                    :show-header="index===0"
                    style="width: 100%">
                <el-table-column width="75px">
                    <template slot-scope="scope" label="序号">
                        <el-button v-if="scope.row.isSubTotalData===1"
                                   type="text"
                                   @click="handleTotalClick(index)">
                            小计  <i class="icon" :class="vm.show?'el-icon-arrow-up':'el-icon-arrow-down'"></i>

                        </el-button>
                        <span v-else>{{scope.$index + 1}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="stationName"
                        label="站点名称">
                </el-table-column>
                <el-table-column
                        prop="resourceName"
                        label="资源名称" width="70px">
                </el-table-column>
                <el-table-column
                        label="子订单号">
                    <template slot-scope="scope">
                        <span>{{ scope.row.subOrderNo || scope.row.subOrderId}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="merchantName"
                        label="旅行社">
                </el-table-column>
                <el-table-column
                        prop="guiderName"
                        label="导游" width="60px">
                </el-table-column>
                <el-table-column
                        prop="userNumber"
                        label="检票工号" width="50px">
                </el-table-column>
                <el-table-column
                        label="检票时间" width="55px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.scanTime | dateCus('hh:mm')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unitPrice"
                        label="结算单价" width="55px">
                </el-table-column>
                <el-table-column
                        prop="scanMum"
                        label="检票份数" width="55px">
                </el-table-column>
                <el-table-column
                        prop="confirmNum"
                        label="确认份数">
                    <template slot-scope="scope">
                        <edit-input name="confirmNum"
                                    :type="scope.row.isSubTotalData||0"
                                    :id="scope.row.id"
                                    :value="scope.row.confirmNum"></edit-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="isDifferent"
                        label="份数差异">
                    <template slot-scope="scope">
                        <div v-if="query.type==='1'">
                            <el-radio-group style="display: flex;width: 70px"
                                            v-model="scope.row.isDifferent"
                                            @change="$refs['isDifferent'][index].submit({id:scope.row.id,isDifferent:scope.row.isDifferent})">
                                <el-radio :label="1" style="margin-right: 5px">
                                    有
                                </el-radio>
                                <el-radio :label="0">无</el-radio>
                            </el-radio-group>
                            <edit-input
                                    ref="isDifferent"
                                    style="visibility: hidden;position: absolute"
                                    name="isDifferent"
                                    :type="scope.row.isSubTotalData||0"
                                    :id="scope.row.id"
                                    :value="scope.row.isDifferent">
                            </edit-input>
                        </div>
                        <span v-else>{{scope.row.isDifferent === 1 ? '有' : '无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="结算金额" prop="scanAmount" width="70px">
                </el-table-column>
                <el-table-column label="确认结算金额">
                    <template slot-scope="scope">
                        <edit-input name="confirmAmount"
                                    :type="scope.row.isSubTotalData||0"
                                    :id="scope.row.id"
                                    :value="scope.row.confirmAmount"></edit-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>


</template>

<script type="text/ecmascript-6">
    import EditInput from './edit-input.vue'

    export default {
        //定义模版数据
        data() {
            return {
                listData: []
            }
        },
        props: {
            list: {
                type: Array,
                value: []
            }
        },
        components: {EditInput},
        //计算属性
        computed: {
            query() {
                const query = this.$route.query
                query.date = Number(query.date)
                return query;
            },
        },
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {
            handleTotalClick(index) {
                let list = this.listData;
                list[index].show = !list[index].show;
                if (list[index].show)
                    list[index].accountBackendDtoList = this.list[index].accountBackendDtoList;
                else
                    list[index].accountBackendDtoList = list[index].accountBackendDtoList.slice(-1)
                this.listData = JSON.parse(JSON.stringify(list));
            },

        },
        //监听模版变量
        watch: {
            list(n) {
                let list = JSON.parse(JSON.stringify(n));
                list = list.map((item) => {
                    item.accountBackendDtoList = item.accountBackendDtoList.slice(-1);
                    return item;
                })
                this.listData = list;
            }
        }

    }
</script>
