import dayjs from 'dayjs'

/**
 * 格式化时间
 */
export const formatDayjsDate = function (date, fmt = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(date).format(fmt);
}

/**
 * 时间戳
 * @param: [date] 日期
 * @param: [time] 时间 例：23:59:59
 */
export const dateTimeStamp = function (date, time) {
    let stamp = formatDayjsDate(date)
    if (time) stamp = formatDayjsDate(date, 'YYYY-MM-DD') + ' ' + time
    return dayjs(stamp).valueOf();
}

/**
 * 当前日期
 */
export const todayDate = formatDayjsDate(dayjs().format(), 'YYYY-MM-DD HH:mm:ss')

/**
 * 最近日期
 * @param: [num] 几个月或者几天
 * @param: [type] 月：month 天：day
 * */
export const subtractDate = function (num, type) {
    return dayjs().subtract(num, type).format();
}

/**
 * 昨天
 */
export const yesterdayDate = subtractDate(1, 'day')

/**
 * 昨天 Arr
 * */
export const yesterdayArr = function () {
    let startDate = dateTimeStamp(yesterdayDate, '00:00:00')
    let endDate = dateTimeStamp(yesterdayDate, '23:59:59')
    let date = [startDate, endDate]
    return date
}

/**
 * 最近一周
 * */
export const latestWeekArr = function () {
    let sevenDays = dateTimeStamp(subtractDate(7, 'day'), '00:00:00')
    let yesterday = dateTimeStamp(yesterdayDate, '23:59:59')
    let date = [sevenDays, yesterday]
    return date
}

/**
 * 最近一月
 * */
export const latestMonthArr = function (){
    let aMonthAgo = dateTimeStamp(subtractDate(1, 'month'), '00:00:00')
    let yesterday = dateTimeStamp(yesterdayDate, '23:59:59')
    let date = [aMonthAgo, yesterday]
    return date
}

/**
 * 当前月份
 */
export const currentMonth = dayjs(todayDate).get('month')

/**
 * 本月
 * */
export const thisMonthArr = function (){
    const thisMonth = dayjs().set('month', currentMonth)
    let monthStart = dayjs(thisMonth).startOf('month').format()
    monthStart = dateTimeStamp(monthStart, '00:00:00')
    let yesterday = dateTimeStamp(yesterdayDate, '23:59:59')
    let date = [monthStart, yesterday]
    return date
}

/**
 * 上月
 * */
export const lastMonthArr = function (){
    const lastMonth = dayjs().set('month', currentMonth - 1)
    let lastMonthStart = dayjs(lastMonth).startOf('month').format()
    lastMonthStart = dateTimeStamp(lastMonthStart, '00:00:00')
    let lastMonthEnd = dayjs(lastMonth).endOf('month').format()
    lastMonthEnd = dateTimeStamp(lastMonthEnd, '23:59:59')
    let date = [lastMonthStart, lastMonthEnd]
    return date
}

/**
 * 计算年  每年1月1号至12月底
 * @param: [year] 年
 * **/
export const startEndYearArr = function (year){
    let selectYear = dayjs(year).get('year')  // 选中年
    let currentYear = dayjs(todayDate).get('year')  // 当前年
    let yearStart = dayjs(year).startOf('year').format() // 年开始1月1号
    yearStart = dateTimeStamp(yearStart, '00:00:00')
    let yearEnd = dayjs(year).endOf('year').format()  // 年结束12月31号
    if(selectYear === currentYear) yearEnd = yesterdayDate
    yearEnd = dateTimeStamp(yearEnd, '23:59:59')
    let date = [yearStart, yearEnd]
    return date
}

export const weekItem = [
    {
        value: 0,
        label: '周日'
    },
    {
        value: 1,
        label: '周一'
    },
    {
        value: 2,
        label: '周二'
    },
    {
        value: 3,
        label: '周三'
    },
    {
        value: 4,
        label: '周四'
    },
    {
        value: 5,
        label: '周五'
    },
    {
        value: 6,
        label: '周六'
    }
];

