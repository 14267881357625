<template>
    <span>
        <span @click="handleShow" v-loading="loading">
            <slot></slot>
        </span>
        <el-dialog
            append-to-body
            title="单品明细"
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            width="800px">
            <el-form inline>
                <el-form-item label="单品名称：">
                    {{resourceName}}
                    <!--<el-select v-model="form.resourceType" disabled>-->
                    <!--<el-option-->
                    <!--v-for="item in options"-->
                    <!--:key="item.value"-->
                    <!--:label="item.label"-->
                    <!--:value="item.value">-->
                    <!--</el-option>-->
                    <!--</el-select>-->
                </el-form-item>
                <el-form-item>
                     <el-date-picker
                         v-model="form.accountDate"
                         type="date"
                         disabled
                         placeholder="选择日期">
                     </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="handleSearch" :loading="loading">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="list"
                      style="width: 100%"
                      :border="true"
                      show-summary
                      row-key="id"
                      :summary-method="getSummaries">
                   <el-table-column prop="id" label="单品订单ID">
                </el-table-column>
                 <el-table-column label="主订单ID">
                     <template slot-scope="scope">
                           <el-link type="primary" @click="handleGoToOrderList(scope.row.ticketOrderNo)">
                               {{scope.row.ticketOrderNo}}
                            </el-link>
                     </template>
                </el-table-column>
                 <el-table-column width="120px" prop="subOrderNo" label="子订单ID">
                </el-table-column>
                  <el-table-column prop="totalNum" label="数量">
                </el-table-column>
                   <el-table-column prop="remark" label="备注说明">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <add-remark @success="getData"
                                    :id="scope.row.id"
                                    :content="scope.row.remark"></add-remark>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script type="text/ecmascript-6">
    import AddRemark from './add-remark.vue'

    const options = [
        {
            label: '中山陵陵寝+中山陵音乐台',
            value: 21
        }, {
            label: '中山陵音乐台',
            value: 14
        }, {
            label: '小交通',
            value: 25
        }, {
            label: '讲解器',
            value: 26
        }
    ]
    export default {
        //定义模版数据
        data() {
            return {
                options,
                loading: false,
                dialogVisible: false,
                form: {
                    resourceType: 14,
                    accountDate: ''
                },
                list: []
            }
        },
        props: {
            id: String,
            date: Number,
            resourceType: String,
            resourceName: String
        },
        components: {
            AddRemark
        },
        mounted() {
            this.form.scanAccountManagerId = this.id;
            this.form.resourceType = Number(this.resourceType);
            this.form.accountDate = new Date(this.date);
        },
        //定义事件方法
        methods: {
            async getData() {
                this.loading = true;
                const ret = await this.http(`/galaxyZslTicketOrderApi/getTicketResourceDetail`, {
                    ...this.form,
                    accountDate: +new Date(this.form.accountDate)
                }, 'POST');
                if (ret.success && ret.data) {
                    this.list = ret.data;
                    this.dialogVisible = true;
                }
                else this.$message.error(ret.errors[0].message)
                this.loading = false;
            },
            handleShow() {
                this.getData();
            },
            handleSearch() {
                this.getData();
            },
            getSummaries({columns, data}) {
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '总计';
                        return
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (index === 3)
                        return sums[index] = values.reduce((a, b) => a + b, 0)

                })
                return sums;
            },
            //前往订单列表
            handleGoToOrderList(id) {
                window.open(`#/zslOrderList?orderId=${id}`)
            }
        }

    }
</script>