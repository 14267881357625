import Decimal from 'decimal.js'

//加法
export const decimalAdd = function (a, b) {
    return new Decimal(a).add(new Decimal(b)).toNumber()
}

//减法
export const decimalSub = function (a, b) {
    return new Decimal(a).sub(new Decimal(b)).toNumber()
}

//乘法
export const decimalMul = function (a, b) {
    return new Decimal(a).mul(new Decimal(b)).toNumber()
}

//除法
export const decimalDiv = function (a, b) {
    return new Decimal(a).div(new Decimal(b)).toNumber()
}