<style lang="scss" scoped>

.data-table {
    tr {
        background: #fff;
        min-height: 30px;
        font-size: 12px;

        td {
            padding: 8px 5px;
            border: 1px solid #F0F8FF;
            min-width: 70px;
            max-width: 120px;
            line-height: 21px;
            word-wrap: break-word;
            font-size: 14px;
        }

    }

    .font-title-w6 {
        font-weight: 600;
    }
}
</style>


<template>
    <div>
        <table class="data-table">
            <tr>
                <td>预约团队数</td>
                <td>{{ form.orderTeamNum || 0 }}</td>
                <td>预约游客数</td>
                <td>{{ form.orderTouristNum || 0 }}</td>
                <td>订单取消率</td>
                <td>{{ form.orderCancelRate || 0 }}%</td>
                <td rowspan="3">订单金额</td>
                <td rowspan="3">¥{{form.orderAmount || 0 }}</td>
            </tr>
            <tr>
                <td>应入园团队数</td>
                <td>{{ form.shouldOrderTeamNum || 0 }}</td>
                <td>应入园游客数</td>
                <td>{{ form.shouldOrderTouristNum || 0 }}</td>
                <td>订单NoShow率</td>
                <td>{{ form.noShowRate || 0 }}%</td>
            </tr>
            <tr>
                <td>实际入园团队数</td>
                <td>{{ form.actualOrderTeamNum || 0 }}</td>
                <td>实际入园游客数</td>
                <td>{{ form.actualOrderTouristNum || 0 }}</td>
                <td>订单完成率</td>
                <td>{{ form.orderCompletionRate || 0 }}%</td>
            </tr>

        </table>
    </div>

</template>

<script type="text/ecmascript-6">

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {},
    methods: {},
    watch: {},
}
</script>
