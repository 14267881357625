<style lang="scss" scoped>
    .dialog-middle-section {
        ::v-deep {
            .notice-title{
                //height: 40px;
                margin: 10px 0;
                font-size: 24px;
                color: #303133;
                font-weight: 500;
                vertical-align: bottom;
                padding: 0 20px;
            }
            .el-dialog__title{
                display: none;
            }
            .el-dialog__header{
                padding: 0;
            }
            .el-dialog__headerbtn{
                top: 10px;
                right: 8px;
                z-index: 99;
            }
            .notice-container {
                border-radius: 8px;
                font-size: 16px;
                //min-height: 200px;
                max-height: 500px;
                overflow: hidden;
                //overflow-y: scroll;
                overflow-y: visible;
                line-height: 22px;
                text-align: justify;
                padding: 10px 20px 20px 20px;

                h4 {
                    margin: 20px 0 10px 0;
                    font-size: 18px;
                }

                h5 {
                    margin: 10px 0;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            .el-dialog__headerbtn {
                i {
                    font-size: 26px;
                }
            }

            .el-dialog__body {
                padding: 0;
                margin-top: 40px;
                //padding: 10px 20px 20px 20px;
            }
            .el-dialog__footer {
                //padding: 0;
                padding: 0 20px 20px 20px;
            }
            .notice-footer{
                padding: 20px;
                text-align: right;
            }
        }
    }
</style>
<style lang="scss">

/**
*
* dialog 弹窗修改
*
**/
.common-dialog-middle-center {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>
<template>
    <div>
        <el-dialog
            :title="title"
            :width="width + 'px'"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            custom-class="common-dialog-middle-center"
            class="dialog-middle-section"
            :visible="show"
            @close="handleClose">
            <div class="notice-title">
                {{title}}
                <span
                    v-if="titleDescribe"
                    class="font-s14 color-grey-99 ml5">
                    {{titleDescribe}}
                </span>
            </div>
            <div class="notice-container">
                <slot name="dialog-section"></slot>
            </div>
            <div :class="{'notice-footer':isFooter}">
                <slot name="dialog-footer"></slot>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">

    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            titleDescribe: {
                type: String,
                default: ''
            },
            width: {
                type: Number,
                default: 800
            },
            show: {
                type: Boolean,
                default: false
            },
            isFooter: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleClose() {
                this.$emit("onClose")
            }
        }
    }
</script>
