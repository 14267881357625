<script>
import AuditList from "@/www/pages/auditManage/auditList/index.vue";

export default {
  name: "companyReview",
  components: {
    AuditList
  },
}
</script>

<template>
  <AuditList userType="1000" ref="auditList"></AuditList>
</template>

<style scoped lang="scss">

</style>
