<style lang="scss" scoped>
.ticket-record::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        height: 32px;
        margin-bottom: 0;
    }

    .common-list-search {
        /*padding: 10px 20px 20px 20px;*/
        .list-search-before {
            width: 100%;
        }

        .list-search-after {
            width: 0;
            display: none;
        }
    }
}

.eTrip-footer-cont {
    padding-right: 0;
}
</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont ticket-record">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-row>
                                <el-col>
                                    <el-form-item style="width: 220px">
                                        <el-autocomplete
                                            v-model="searchForm.createOrginfoName"
                                            :fetch-suggestions="querySearchAsync"
                                            placeholder="下单机构"
                                            @select="selectAgency">
                                            <template slot-scope="{ item }">
                                                <div class="list-pop-bot">
                                                    <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                                    <span>{{ item.merchantName }}</span>
                                                </div>
                                            </template>
                                        </el-autocomplete>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input placeholder="母订单号" style="width: 120px"
                                                  v-model="searchForm.orderNo"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input placeholder="子订单号" style="width: 120px"
                                                  v-model="searchForm.subOrderId"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input placeholder="申请人姓名" style="width: 120px"
                                                  v-model="searchForm.applyUserName"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input placeholder="申请人手机号" style="width: 120px" maxlength="11"
                                                  v-model="searchForm.applyUserMobile"></el-input>
                                    </el-form-item>
                                    <el-form-item style="width: 100px">
                                        <el-col>
                                            <!--<span>状态:</span>-->
                                            <el-select v-model="searchForm.auditStatus">
                                                <el-option value="" label="全部"></el-option>
                                                <el-option value="1" label="未审核"></el-option>
                                                <el-option value="2" label="已退款"></el-option>
                                                <el-option value="3" label="已撤销"></el-option>
                                            </el-select>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                                <el-col style="margin-top: 10px">
                                    <el-form-item style="width: 360px">
                                        <el-date-picker v-model="searchForm.createTime"
                                                        type="daterange" value-format="yyyy-MM-dd"
                                                        range-separator="至"
                                                        start-placeholder="选择退款申请时间日期"
                                                        end-placeholder="选择退款申请时间日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item style="width: 360px">
                                        <el-date-picker v-model="searchForm.operateTime"
                                                        type="daterange" value-format="yyyy-MM-dd"
                                                        range-separator="至"
                                                        start-placeholder="选择退款审核时间日期"
                                                        end-placeholder="选择退款审核时间日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button style="border-color: #319BF7;color: #319BF7" @click="handleReset()">
                                            重置
                                        </el-button>
                                        <el-button @click="search()" type="primary">搜索</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </com-list-search>
                <div class="mt10 el-table_border_none">
                    <el-table
                        border
                        :data="refundList"
                        v-loading="loading"
                        style="width: 100%">
                        <el-table-column prop="resourceName"
                                         label="产品名称"
                                         width="180">
                        </el-table-column>
                        <el-table-column
                            label="母订单号"
                            width="100" prop="orderNo">
                        </el-table-column>
                        <el-table-column width="160" prop="subOrderId"
                                         label="子定单号">
                        </el-table-column>
                        <el-table-column width="85" prop="num"
                                         label="预定份数">
                        </el-table-column>
                        <el-table-column width="85" prop="useNum"
                                         label="检票份数">
                        </el-table-column>
                        <el-table-column width="85" prop="refundNum"
                                         label="退款份数">
                        </el-table-column>
                        <el-table-column width="100" prop="applyUserName"
                                         label="申请人姓名">
                        </el-table-column>
                        <el-table-column width="120" prop="applyUserMobile"
                                         label="申请人手机号">
                        </el-table-column>
                        <el-table-column width="180" prop="companyName"
                                         label="下单机构">
                        </el-table-column>
                        <el-table-column width="200"
                                         label="退款申请时间">
                            <template slot-scope="scope">
                                <p>{{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="200"
                                         label="退款审核时间">
                            <template slot-scope="scope">
                                <p>{{ scope.row.operateTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="90" label="状态">
                            <template slot-scope="scope">
                                <p v-if="scope.row.auditStatus==1" style="color: #ff3b30">未审核</p>
                                <p v-if="scope.row.auditStatus==2">已处理</p>
                                <div v-if="scope.row.auditStatus==3">已撤销
                                    <el-tooltip :content="scope.row.operateReason" placement="bottom" effect="light">
                                        <i class="icon icon-not-pass"></i>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="200px"
                                         label="操作">
                            <template slot-scope="scope">
                                <div>
                                    <el-button type="text" :disabled="scope.row.auditStatus!=1"
                                               @click="cancel(scope.row)">撤销申请
                                    </el-button>
                                    <el-button type="text" :disabled="scope.row.auditStatus!=1"
                                               @click="approved(scope.row)">处理申请
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="currentPageChange"
                        :current-page.sync="tablePage.pageIndex"
                        :page-size="tablePage.pageSize"
                        layout="total, prev, pager, next"
                        :total="tablePage.totalPage">
                    </el-pagination>
                </div>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../components/comListSearch/index.vue'

export default {
    //定义模版数据
    data() {
        return {
            tableH: 'calc(100vh - 306px)',
            searchForm: {
                auditStatus: ''
            },
            resourceOptions: [{type: 1, title: '小交通'}, {type: 2, title: '音乐台'}, {type: 3, title: '讲解器'}],
            platformOptions: [
                {
                    type: 1, title: '中山陵停车场站'
                },
                {
                    type: 2, title: '中山陵东站'
                },
                {
                    type: 3,
                    title: '灵谷寺站'
                },
                {type: 4, title: '音乐台站'},
                {type: 5, title: '东沟租赁站'},
                {type: 6, title: '灵谷寺租赁站'}
            ],
            refundList: [],
            loading: false,
            tablePage: {  // 列表分页
                pageSize: 10,  // 每页显示条目个数
                totalPage: 1,  // 总条目个数
                pageIndex: 1,  // 当前页数
            },
        }
    },
    components: {comListSearch},
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    //主件被加载完成
    mounted: function () {
        this.getScanQrCodeInfo(1)

    },
    //定义事件方法
    methods: {
        currentPageChange(index) {
            this.tablePage.pageIndex = index;
            this.getScanQrCodeInfo(index);
        },
        handleReset() {
            this.searchForm = {
                auditStatus: ''
            }
            this.getScanQrCodeInfo(1)
        },
        search() {
            this.getScanQrCodeInfo(this.tablePage.pageIndex)
        },
        async getScanQrCodeInfo(pageIndex) {  //
            let {
                    createTime,
                    operateTime,
                    applyUserName,
                    applyUserMobile,
                    orderNo,
                    subOrderId,
                    auditStatus,
                    createOrderOrganId
                } = this.searchForm,
                form = {
                    pageSize: 10,
                    pageIndex: pageIndex,
                    applyUserName,
                    applyUserMobile,
                    orderNo,
                    subOrderId,
                    auditStatus,
                    createOrderOrganId
                };
            if (createTime && createTime.length > 0) {
                form.createTimeStart = +new Date(this.searchForm.createTime[0]);
                form.createTimeEnd = +new Date(this.searchForm.createTime[1] + " 23:59:59");
            }
            if (operateTime && operateTime.length > 0) {
                form.operateTimeStart = +new Date(this.searchForm.operateTime[0]);
                form.operateTimeEnd = +new Date(this.searchForm.operateTime[1] + " 23:59:59");
            }
            this.loading = true;
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketOrderAuditRefundList', form, 'POST');
            if (res.success && res.data) {
                this.refundList = res.data.data;
                this.tablePage.totalPage = res.data.total;
            } else {
                this.messageError('系统出错，请稍后!');
            }

            this.loading = false;

        },
        cancel(data) {
            this.$prompt(`<p>用户申请退款，订单号<span style="color: #319BF7">${data.orderNo}</span>，退款份数<span style="color: #319BF7">${data.refundNum}</span>份，是否确认撤销该申请？</p><p>撤销申请：</p>`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '撤销理由',
                dangerouslyUseHTMLString: true
            }).then(async ({value}) => {
                if (value) {
                    const form = {
                        id: data.id,
                        operateReason: value
                    }
                    const res = await this.http("/galaxyZslTicketOrderApi/refuseTicketOrderAuditRefund", form, "POST");
                    console.log(res);
                    if (res.success) {
                        this.$message({
                            type: 'success',
                            message: '撤销成功'
                        });
                        this.getScanQrCodeInfo(this.tablePage.pageIndex)
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请输入撤销理由'
                    });
                }


            }).catch((e) => {
                console.log(12312312, e);
            });
        },
        approved(data) {
            this.$confirm(`<p>用户申请退款，订单号<span style="color: #319BF7">${data.orderNo}</span>，退款份数<span style="color: #319BF7">${data.refundNum}</span>份，是否确认处理该申请？</p>`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true
            }).then(async () => {
                const form = {
                    id: data.id
                }
                const res = await this.http("/galaxyZslTicketOrderApi/passTicketOrderAuditRefund", form, "POST");
                console.log(res);
                if (res.success) {
                    this.$message({
                        type: 'success',
                        message: '退款成功'
                    });
                    this.getScanQrCodeInfo(this.tablePage.pageIndex)
                }
            }).catch((e) => {
                console.log(12312312, e);
            });
        },
        async querySearchAsync(query, cb) {
            if (query && query.length > 1) {
                this.querySearch(query, cb, 1)
            }
        },
        async querySearch(query, cb, index) {
            let url = '';
            let form = {};
            let postType = 'GET';
            switch (index) {
                case 1:
                    url = '/galaxyMerchant/merchantInfo';
                    form = {
                        merchantName: query
                    }
                    break;
            }
            let res = await this.http(url, form, postType);
            if (res.success && res.data) {
                // let mechanismList = res.data;
                let mechanismList = query ? res.data.filter(this.createFilter(query, index)) : res.data;
                cb(mechanismList);
            } else {
                this.messageError('系统出错，请稍后!');
            }
        },
        createFilter(itemStr, index) {
            return (restaurant) => {
                let result = false;
                switch (index) {
                    case 1:
                        result = (restaurant.merchantName.indexOf(itemStr) >= 0);
                        break;
                }
                return result;
            };
        },
        async selectAgency(val) {
            this.searchForm.createOrginfoName = val.merchantName;
            this.searchForm.createOrderOrganId = val.id;
        },
    },
    //监听模版变量
    watch: {}

}
</script>

