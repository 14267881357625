/**
* Created by hansxing on 2018/8/2.
*/
<style lang="scss" scoped>
.audit-list {

    .audit-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/

        .not-through-popover {
            display: inline-block;
            cursor: pointer;

            .el-popover__reference {
                color: #FF3B30;
            }
        }
    }
}

.not-through-popover-cont {
    color: #FF3B30;

    .el-popover__title {
        color: #FF3B30;
    }
}

</style>
<style lang="scss">
.audit-list {
    .common-list-search {
        .el-form-item__label, .el-form-item__content {
            display: inline-block;
        }

        .list-search-before {
            /*.el-form-item__content{*/
            /*width: 100%;*/
            /*}*/
        }

        .list-search-before {
            width: calc(100% - 180px);
        }

        .list-search-after {
            width: 180px;
        }
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont audit-list">

                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" label-position="right" size="small">
                            <el-form-item label="提交日期：" class="el-label_w" style="width: 432px">
                                <el-date-picker v-model="subForm.approvalDate" class="radio-change-date"
                                                value-format="yyyy-MM-dd"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item class="radio-change-group" style="min-width: 278px;width: auto">
                                <el-radio-group v-model="subForm.approvalStatus">
                                    <el-radio-button :label="0">全部</el-radio-button>
                                    <el-radio-button :label="5">
                                        待初审<span>{{ headCount.approvalStatusFirstCount }}</span>
                                    </el-radio-button>
                                    <el-radio-button :label="2">
                                        待复审<span>{{ headCount.approvalStatusInitCount }}</span>
                                    </el-radio-button>
                                    <el-radio-button :label="3">
                                        已通过<span>{{ headCount.approvalStatusPassedCount }}</span>
                                    </el-radio-button>
                                    <el-radio-button :label="4">
                                        未通过<span>{{ headCount.approvalStatusDeniedCount }}</span>
                                    </el-radio-button>
                                    <el-radio-button :label="6">初审未通过
                                        <!--                                        <span>{{headCount.approvalStatusDeniedCount}}</span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item class="radio-change-group" style="width: 166px">
                                <el-radio-group class="screen-contract-effective" v-model="subForm.approvalType">
                                    <el-radio-button :label="0">全部</el-radio-button>
                                    <el-radio-button :label="1">新增
                                    </el-radio-button>
                                    <el-radio-button :label="2">修改
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item class="radio-change-group" style="width: auto">
                                <el-radio-group class="screen-contract-effective" v-model="subForm.orgType">
                                    <el-radio-button label="">全部</el-radio-button>
                                    <el-radio-button label="0100000000">旅行社
                                    </el-radio-button>
                                    <el-radio-button label="0000000001">其他
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item class="radio-change-group" v-if="!userType" style="width: auto">
                                <el-radio-group class="screen-contract-effective" v-model="subForm.userType">
                                    <el-radio-button label="">全部</el-radio-button>
                                    <el-radio-button label="1000">一级用户
                                    </el-radio-button>
                                    <el-radio-button label="0100">二级用户
                                    </el-radio-button>
                                    <el-radio-button label="0010">供应商
                                    </el-radio-button>
                                    <el-radio-button label="0001">运营商
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                        <el-form :inline="true" label-position="right" size="small">
                            <el-form-item label="关键词：" label-width="82px" class="el-label_w" style="width: 282px">
                                <el-input placeholder="机构ID" v-model="subForm.orgId"></el-input>
                            </el-form-item>
                            <el-form-item style="width: 200px">
                                <el-select v-model="subForm.companyName" @change="changeCompany" filterable
                                           placeholder="请输入公司名称">
                                    <el-option
                                        v-for="item in companyList"
                                        :key="item.id"
                                        :label="item.companyName"
                                        :value="item.companyName">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item style="width: 260px">
                                <el-input placeholder="提交人姓名" v-model="subForm.submitUserId"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="screenResetClick()">重置</el-button>
                        <el-button type="primary" @click="getList(1)">搜索</el-button>
                    </div>
                </com-list-search>


                <div class="audit-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              style="width: 100%"
                              v-loading="comListTableLoading"
                              :data="approvalPageList">
                        <el-table-column
                            fixed
                            prop="orgId"
                            label="机构ID"
                            align="center"
                            width="110">
                        </el-table-column>
                        <el-table-column
                            prop="companyName"
                            label="公司名称"
                            align="center"
                            min-width="220">
                        </el-table-column>
                        <el-table-column
                            label="审核类型"
                            align="center"
                            min-width="120">
                            <template slot-scope="scope">
                                <span v-if="scope.row.approvalType==1">新增</span>
                                <span v-if="scope.row.approvalType==2">修改</span>
                            </template>

                        </el-table-column>
                        <el-table-column
                            label="提交人"
                            align="center"
                            min-width="120"
                            prop="createUser">
                        </el-table-column>
                        <el-table-column
                            label="提交时间"
                            align="center"
                            min-width="180"
                            prop="createTime"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="审核状态"
                            align="center"
                            min-width="120">
                            <template slot-scope="scope">
                                <p v-if="scope.row.approvalStatus == 2">待复审</p>
                                <p v-if="scope.row.approvalStatus == 5">待初审</p>
                                <p v-if="scope.row.approvalStatus == 6">初审未通过</p>
                                <!--<p v-if="scope.row.approvalStatus == 3">已通过</p>-->
                                <!--<p v-if="scope.row.approvalStatus == 4">未通过</p>-->
                                <el-popover class="not-through-popover"
                                            v-if="scope.row.approvalStatus == 4||scope.row.approvalStatus==3"
                                            placement="bottom-end"
                                            :title="`${scope.row.approvalStatus == 4 ? '未通过' : '通过'}原因`"
                                            width="200"
                                            trigger="hover"
                                            :popper-class="scope.row.approvalStatus == 4?'not-through-popover-cont':null"
                                            :content="scope.row.approvalDesc||scope.row.passDesc||'无'">
                                    <p slot="reference">
                                       <span :style="{color:scope.row.approvalStatus == 3?'#606266':'#FF3B30'}">
                                           {{ scope.row.approvalStatus == 4 ? '未通过' : '已通过' }}
                                       </span>
                                        <i class="icon icon-not-adopt-tips"
                                           :style="{color:scope.row.approvalStatus == 3?'#606266':'#FF3B30'}"></i>
                                    </p>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <el-button @click="seeListInfoClick(scope.row)"
                                           v-if="(scope.row.approvalStatus==2 && userInfo.userType != 12&&!userType) || scope.row.approvalStatus==5 "
                                           type="text">详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                @current-change="handleTablePageNumberChange"
                                :current-page.sync="subForm.currentPage"
                                :page-size="subForm.pageSize"
                                layout="total, prev, pager, next"
                                :total="subForm.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </el-main>
    </div>


</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../components/comListSearch/index.vue'

export default {
    name: 'auditList',
    props: {
        userType: String
    },
    //定义模版数据
    data() {
        return {
            subForm: {
                approvalDate: [],
                approvalStatus: 0,
                approvalType: 0,
                orgType: '',
                userType: '',
                companyName: '',
                orgId: '',
                pageIndex: 1,
                submitUserId: '',
                pageSize: 10,
                currentPage: 1,
                totalPage: 0
            },
            companyList: [],
            comListTableLoading: false,
            approvalPageList: [],
            headCount: {
                approvalStatusDeniedCount: 0,
                approvalStatusPassedCount: 0,
                approvalStatusInitCount: 0,
                approvalStatusFirstCount: 0
            }
        }
    },
    components: {comListSearch},
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
    },
    activated() {
        this.getList();
    },
    //主件被加载完成
    mounted: function () {
        this.getCompany();
    },
    //定义事件方法
    methods: {
        async getCompany() {
            let form = {
                pageIndex: 1,
                pageSize: 9999
            };
            try {
                let res = await
                    this.http('/galaxyOrgInfoApi/orgInfoApiPageList', form, 'POST');
                if (res.success) {
                    let resData = res.data.data;
                    resData.forEach(item => {
                        item.value = item.companyName;
                    })
                    this.companyList = resData;
                }
            } catch (e) {
                console.log(e);
            }
        },
        changeCompany(val) {
            this.subForm.companyName = val
        },
        screenResetClick() {  // 重置
            this.subForm = {
                approvalDate: [],
                approvalStatus: 0,
                approvalType: 0,
                orgType: '',
                userType: '',
                companyName: '',
                orgId: '',
                pageIndex: 0,
                submitUserId: '',
                pageSize: 10
            }
            this.getList();
        },
        async getList(page) {
            this.comListTableLoading = true;
            let {
                    approvalStatus,
                    approvalType,
                    companyName,
                    orgId,
                    currentPage,
                    pageSize,
                    submitUserId,
                    orgType,
                    userType
                } = this.subForm,
                form = {
                    approvalStatus,
                    approvalType,
                    pageIndex: page || currentPage,
                    pageSize,
                };
            if (companyName) form.companyName = companyName;
            if (orgId) form.orgId = orgId;
            if (orgType) form.orgType = orgType;
            if (submitUserId) form.submitUserId = submitUserId;
            if (userType) form.userType = userType;
            if (this.userType) form.userType = this.userType
            if (this.subForm.approvalDate && this.subForm.approvalDate.length > 0) {
                form.approvalDateBegin = this.subForm.approvalDate[0] + ' 00:00:00';
                form.approvalDateEnd = this.subForm.approvalDate[1] + ' 23:59:59';
            }
            try {
                let res = await this.http('/galaxyApproval/approvalPageList', form, 'POST');
                if (res.success) {
                    this.comListTableLoading = false;
                    this.approvalPageList = res.data.pageList.data;
                    this.subForm.totalPage = res.data.pageList.total;
                    res.data.statusCount.forEach(item => {
                        if (item.approval_status == 5) this.headCount.approvalStatusFirstCount = item.count;
                        if (item.approval_status == 2) this.headCount.approvalStatusInitCount = item.count;
                        if (item.approval_status == 3) this.headCount.approvalStatusPassedCount = item.count;
                        if (item.approval_status == 4) this.headCount.approvalStatusDeniedCount = item.count;
                    })
                }
//                    else {
                // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
            } catch (e) {
                console.log(e);
                this.messageError();
            }


        },
        seeListInfoClick(data) {
            this.$router.push('/auditDetail/' + data.id + '/' + data.approvalType);
        },
        editListInfoClick(id) {
            this.$router.push('/editMechanism/' + id);
        },
        handleTablePageNumberChange(val) {
            this.subForm.currentPage = val;
            this.getList();
        },
        async getPageHead() {
            try {
                let res = await this.http('/galaxyOrgInfoApi/doGalaxyOrgInfoApiPageHeadCount');
                if (res.success) {
                    this.headCount = res.data.pageList;
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    //监听模版变量
    watch: {}

}
</script>

