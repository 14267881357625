<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table
            :data="listArr"
            v-loading="loading"
            style="display: inline-block;width: auto"
            :default-sort="{order: 'descending'}"
        >
            <el-table-column
                prop="date"
                label="日期"
                sortable
                width="130">
                <template slot-scope="scope">
                    {{scope.row.orderUseTime | dateCus('yyyy.MM.dd')}}
                </template>
            </el-table-column>
            <el-table-column label="预约入园" align="center">
                <el-table-column
                    label="团队数"
                    sortable
                    width="90"
                    prop="orderTeamNum">
                </el-table-column>
                <el-table-column
                    label="人数"
                    sortable
                    width="90"
                    prop="orderTouristNum">
                </el-table-column>
            </el-table-column>
            <el-table-column label="应入园" align="center">
                <el-table-column
                    label="团队数"
                    sortable
                    width="90"
                    prop="shouldOrderTeamNum">
                </el-table-column>
                <el-table-column
                    label="人数"
                    sortable
                    width="90"
                    prop="shouldOrderTouristNum">
                </el-table-column>
            </el-table-column>
            <el-table-column label="实际入园" align="center">
                <el-table-column
                    label="团队数"
                    sortable
                    width="90"
                    prop="actualOrderTeamNum">
                </el-table-column>
                <el-table-column
                    label="人数"
                    sortable
                    width="90"
                    prop="actualOrderTouristNum">
                </el-table-column>
            </el-table-column>
            <el-table-column
                prop="orderCancelRate"
                label="入园订单取消率"
                sortable
                width="160">
                <template slot-scope="scope">
                    {{scope.row.orderCancelRate}}%
                </template>
            </el-table-column>
            <el-table-column
                prop="noShowRate"
                label="订单NoShow率"
                sortable
                width="150">
                <template slot-scope="scope">
                    {{scope.row.noShowRate}}%
                </template>
            </el-table-column>
            <el-table-column
                prop="orderCompletionRate"
                label="订单完成率"
                sortable
                width="130">
                <template slot-scope="scope">
                    {{scope.row.orderCompletionRate}}%
                </template>
            </el-table-column>
            <el-table-column
                prop="orderAmount"
                label="订单金额"
                sortable
                width="120">
                <template slot-scope="scope">
                    ¥{{scope.row.orderAmount || 0 }}
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">

import {decimalMul} from "@/common/js/decimal";

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {
        listArr(){
            let arr = this.list.map(item => {
                if(item.orderCancelRate > 0) item.orderCancelRate = decimalMul(item.orderCancelRate, 100)
                else item.orderCancelRate = 0
                if(item.noShowRate > 0) item.noShowRate = decimalMul(item.noShowRate, 100)
                else item.noShowRate = 0
                if(item.orderCompletionRate > 0) item.orderCompletionRate = decimalMul(item.orderCompletionRate, 100)
                else item.orderCompletionRate = 0
                return item
            })
            return arr
        }
    },
    //主件被加载完成
    mounted() {},
    methods: {},
    watch: {},
}
</script>
