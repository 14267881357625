<template>
    <div class="rule-configuration">
        <ul class="rule-configuration_list" v-loading="loading">
            <li class="rule-configuration_item" v-for="vm in rules" :key="vm.title">
                <p>{{ vm.title }}</p>
                <div>
                    <el-link type="primary" @click="handleNext(vm.path,0)" class="rule-configuration_link">查看
                    </el-link>
                    <el-link type="primary" @click="handleNext(vm.path,1)" class="rule-configuration_link ml10">编辑
                    </el-link>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "RuleConfiguration",
    data() {
        return {
            loading: false,
            rules: [
                {
                    title: '预订和修改规则',
                    path: '/bookingAndModificationRules'
                }, {
                    title: '未履约规则',
                    path: '/unfulfilledRules'
                },
                {
                    title: '积分规则',
                    path: '/integralRule'
                }]
        }
    },
    mounted() {
    },
    methods: {
        handleNext(path, type) {
            this.$router.push(`${path}?type=${type}`);
        }
    }
})
</script>

<style scoped lang="scss">
.rule-configuration {
    padding: 20px;

    .rule-configuration_list {
        border: 1px solid #D8DCE6;

        .rule-configuration_item {
            display: flex;
            justify-content: space-between;
            padding: 13px 15px;
            font-size: 15px;
            font-weight: 600;

            .rule-configuration_link {
                font-size: 15px;
                font-weight: 600;
                //color: #333;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #D8DCE6;
            }
        }
    }
}
</style>
