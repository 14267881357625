<style lang="scss" scoped>
.notice-list {
    margin-top: 20px;

    .notice-add-btn {
        text-align: center;
        margin-top: 15px;
    }

    .btns {
        button {
            padding: 7px 20px;
            color: #1890FF;
            font-size: 14px;
            font-weight: 400;

            &.primary {
                background-color: #1890FF;
                color: #fff;
            }

            &.danger {
                color: #FF3B30;
            }
        }
    }
}

#notice-list /deep/ {
    .el-dialog__wrapper {
        overflow: initial;
    }
}

</style>
<style lang="scss">
.notice-table {
    .table-info-operation {
        .el-button {
            padding: 0;
        }
    }
}

.tox.tox-silver-sink.tox-tinymce-aux,.tox .tox-dialog-wrap {
    z-index: 3000;
}

</style>
<template>
    <div id="notice-list">
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont notice-list">
                <div class="notice-table el-table_border_none">
                    <el-table
                        border
                        :data="list"
                        style="width: 100%">
                        <el-table-column
                            type="index"
                            label="序号"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            prop="noticeNo"
                            label="公告编号"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="noticeSubject"
                            label="公告主题">
                        </el-table-column>
                        <el-table-column
                            label="公告发布时间"
                            width="180">
                            <template slot-scope="scope">
                                <p>{{ scope.row.createTime | dateCus("yyyy-MM-dd hh:mm") }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="状态"
                            width="80">
                            <template slot-scope="scope">
                                <p>{{ scope.row.noticeStatus === 1 ? '已上线' : '未上线' }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column :class-name="('table-info-operation')"
                                         fixed="right"
                                         label="操作"
                                         width="300">
                            <template slot-scope="scope">
                                <div class="btns clear">
                                    <el-button
                                        type="text"
                                        @click.native.prevent="handleEdit(scope.row)">
                                        查看
                                    </el-button>
                                    <el-button
                                        type="text"
                                        :class="scope.row.noticeStatus===1?'danger':''"
                                        @click.native.prevent="handleChangeStatus(scope.row)">
                                        {{ scope.row.noticeStatus === 1 ? '下线' : '上线' }}
                                    </el-button>
                                    <el-button
                                        type="text"
                                        class="danger"
                                        @click.native.prevent="handleDelete(scope.row)">
                                        删除
                                    </el-button>
                                </div>


                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="notice-add-btn">
                    <el-button type="primary" icon="el-icon-plus"
                               @click="handleAddClick">
                        新增公告
                    </el-button>
                </div>
            </div>
        </el-main>
        <!--编辑资源   v-loading="dialogFormLoading" -->
        <el-dialog
            custom-class="edit-resource-dialog"
            :visible.sync="dialogFormVisible"
            top="0"
            right="0"
            title="编辑公告内容"
            :fullscreen="false"
            :show-close="false"
            width="60%"
            :before-close="onDialogFormClose"
            :lock-scroll="true">
            <!--<p>编辑资源内容 </p>-->
            <el-form ref="form" :model="form" label-width="180px" :rules="rules" size="small">
                <el-form-item label="主题：" prop="noticeSubject" required>
                    <el-input v-model="form.noticeSubject" placeholder="50 个汉字内" maxlength="50"
                              style="width: 600px"></el-input>
                </el-form-item>
                <el-form-item label="内容详情：" prop="noticeContent" required>
                    <tinymce id="product-info-tinymce" :height="600" v-model="form.noticeContent"></tinymce>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <p>{{ form.id ? '编辑公告' : '添加公告' }}</p>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">

import tinymce from '../../../components/tinymce-vue/index.vue';


export default {
    //定义模版数据
    data() {
        return {
            list: [],
            dialogFormVisible: false,
            buttons: {
                currentId: null,
                addDisabled: false,
                upOrDownDisabled: false,
                delBtnDisabled: false
            },
            form: {
                noticeSubject: '',
                noticeContent: ''
            },
            rules: {
                noticeSubject: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value)
                                callback("填写公告主题")
                            else
                                callback();
                        }, trigger: 'blur'

                    }
                ],
                noticeContent: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value)
                                callback("填写公告内容")
                            else
                                callback();
                        }, trigger: 'blur'

                    }
                ]
            }
        }
    },

    components: {
        tinymce
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.getData();
    },
    //定义事件方法
    methods: {
        async getData() {
            this.loadingShow()
            const ret = await this.http('/galaxyZslTicketOrderApi/listTicketNotice', {}, 'POST');
            this.loadingHide();
            if (ret.success && ret.data)
                this.list = ret.data;
        },
        async onDialogFormClose() {
            try {
                await this.$confirm('公告还未保存，是否退出?', '退出编辑', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.dialogFormVisible = false;
            } catch (e) {
                console.log(e);
            }
        },
        handleSubmit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    try {
                        await this.$confirm('确定保存公告吗?', '退出编辑', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        this.loadingShow()
                        const ret = await this.http('/galaxyZslTicketOrderApi/addTicketNotice', this.form, 'POST');
                        this.loadingHide();
                        if (ret.success && ret.data) {
                            this.$message.success('添加公告成功')
                            this.dialogFormVisible = false;
                            this.getData();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            })

        },
        async handleAddClick() {
            this.form = {
                noticeSubject: '',
                noticeContent: ''
            }
            this.dialogFormVisible = true;
        },
        handleEdit({id, noticeSubject, noticeContent}) {
            this.form = {
                id,
                noticeSubject,
                noticeContent
            }
            this.dialogFormVisible = true;
        },
        async handleChangeStatus({id, noticeStatus}) {
            this.loadingShow()
            const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketNotice', {
                id,
                noticeStatus: noticeStatus === 1 ? 0 : 1
            }, 'POST');
            this.loadingHide();
            if (ret.success && ret.data) {
                this.$message.success('操作成功!')
                this.getData();
            }
        },
        async handleDelete({id}) {
            try {
                await await this.$confirm('确定删除这条公告吗?', '删除公告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.loadingShow()
                const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketNotice', {
                    id,
                    isDelete: 1
                }, 'POST');
                this.loadingHide();
                if (ret.success && ret.data) {
                    this.$message.success('删除公告成功!')
                    this.getData();
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    //监听模版变量
    watch: {}

}
</script>

