<style lang="scss" scoped>
.container {
    padding: 12px;
    background-color: #fff;
    border-bottom: 1px solid #f8f8f8;

    form {
        background-color: #f8f8f8;
        padding: 12px;
    }

    /deep/ .el-form-item {
        margin-bottom: 0;
    }
}
</style>
<template>
    <div class="container">
        <el-form inline>
            <el-form-item label="当前预付款余额：">
                <span :style="{color:recordAmountStatus.color}">
                    ¥{{ recordAmountStatus.text }}
                </span>
            </el-form-item>
            <el-form-item label="确认结算金额（已勾选）：" v-if="isFinance"
                          style="margin-left: 40px">
                {{ settlementForm.totalAmount | currency(1, '¥') }}
                <span v-if="settlementForm.totalAmount===0" style="font-size: 14px;color: #999">
                                    请勾选需要结算的批次
                                </span>
            </el-form-item>
            <el-form-item v-if="isFinance" style="margin-left: 40px">
                <el-button size="mini"
                           type="primary"
                           @click="handleSubmit">财务结算
                </el-button>
                <el-button size="mini"
                           type="primary"
                           @click="handleAddBalance()">
                    增加余额
                </el-button>
            </el-form-item>
            <el-form-item v-if="isFinance" style="margin-left: 40px">
                <el-link type="primary" href="#/settlementRecord">查看结汇记录</el-link>
            </el-form-item>
        </el-form>
        <dialog-middle-section :show="balanceShow" :width="350" title="增加余额" @onClose="handleCancelBalance">
            <div slot="dialog-section">
                <ul>
                    <li class="box-flex align-center mb10">
                        当前预付款余额：¥{{ recordAmountStatus.text }}
                    </li>
                    <li class="box-flex align-center">
                        增加金额：
                        <el-input v-model="amount" placeholder="请输入增加金额" size="small" style="width: 180px"></el-input>
                        元
                    </li>
                </ul>
                <div class="align-r mt20">
                    <el-button @click="handleCancelBalance" size="small">取消</el-button>
                    <el-popover
                        placement="bottom"
                        width="160"
                        v-model="balanceVisible">
                        <p v-if="amount" class="mb15 mt10">
                            增加金额：{{ amount }}元
                        </p>
                        <div style="text-align: right; margin: 0">
                            <el-button @click="handleCancelBalance" size="small">取消</el-button>
                            <el-button type="primary" @click="handleSubmitBalance()" size="small" :loading="balanceLoading">确定</el-button>
                        </div>
                        <el-button slot="reference" size="small" class="ml10">确定</el-button>
                    </el-popover>
                </div>

            </div>
        </dialog-middle-section>
        <el-dialog
            title="确认财务结算？"
            :visible.sync="dialogVisible"
            width="30%">
            <p>确认结算金额：{{ settlementForm.totalAmount | currency(1, '¥') }}</p>
            <p>结算后预付款余额：{{ settlementAmount | currency(1, '¥') }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmitSettlement" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import dialogMiddleSection from '@/components/dialog-middle-section/index'
import {keepTwoDecimal} from "@/common/js/Utils";

export default {
    //定义模版数据
    data() {
        return {
            dialogVisible: false,
            loading: false,
            balanceShow: false,
            balanceVisible: false,
            balanceLoading: false,
            amount: '',
            recordAmount: 0
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    components: {
        dialogMiddleSection
    },
    //计算属性
    computed: {
        settlementForm() {
            const checkedList = this.list.filter((item) => item.checked);
            const totalAmount = checkedList.reduce((a, b) => {
                return a + b.scanSettleAmount
            }, 0);
            const ids = checkedList.map((item) => item.id)
            return {
                ids,
                totalAmount
            }
        },
        recordAmountStatus() {
            return {
                text: this.recordAmount < 5000 ? `${this.recordAmount} 余额不足` : this.recordAmount,
                color: this.recordAmount < 5000 ? '#FF3B30' : '#333'
            }
        },
        isFinance() {
            return this.$store.state.loginUserInfo.roleName === "总部财务"
        },
        settlementAmount() {
            return Number(this.recordAmountStatus.text) + Number(this.settlementForm.totalAmount)
        }
    },
    //主件被加载完成
    mounted() {
        this.getTicketCheckRecordAmount();
    },
    //定义事件方法
    methods: {
        // 增加余额
        handleAddBalance() {
            this.balanceShow = true
        },
        // 对账余额增加额度
        async handleSubmitBalance() {
            if (this.amount) {
                let amount = keepTwoDecimal(this.amount)
                this.balanceLoading = true
                const res = await this.http(`/galaxyZslTicketOrderApi/addTicketCheckRecordAmount/${amount}`)
                if (res.success && res.data) {
                    this.$message({
                        message: '余额新增成功',
                        type: 'success'
                    });
                    this.handleCancelBalance()
                    this.getTicketCheckRecordAmount()
                }
                this.balanceLoading = false
            } else this.$message.error('请填写余额');
        },
        // 增加金额取消
        handleCancelBalance() {
            this.balanceShow = false
            this.balanceVisible = false
            this.amount = ''
        },
        //获取对账余额
        async getTicketCheckRecordAmount() {
            const ret = await this.http('/galaxyZslTicketOrderApi/getTicketCheckRecordAmount');
            this.recordAmount = 0;
            if (ret.success && ret.data)
                this.recordAmount = ret.data;
            else
                this.$message.error(ret.errors[0].message)
        },
        handleSubmit() {
            if (this.settlementForm.ids.length === 0)
                return this.$message.error('请勾选需要结算批次')
            this.dialogVisible = true;
        },
        //提交结算
        async handleSubmitSettlement() {
            this.loading = true;
            const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketCheckRecordManagerStatus', this.settlementForm, 'POST')
            if (ret.success && ret.data) {
                this.$emit('onSuccess')
                this.dialogVisible = false
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        }
    },
    //监听模版变量
    watch: {}

}
</script>