<style lang="scss" scoped>
.order-list {
    /deep/ .el-button {
        padding: 8px 20px;
    }

    .order-list-main {
        /*background: #ffffff;*/
        /*padding: 10px 20px 0;*/
        margin-top: 10px;
        margin-bottom: 15px;
        /*width: 1088px;*/
        /*display: inline-block;*/
        .order-list-detail {
            /*width: 1068px;*/
            border: 1px solid #87C3FF;
            margin-top: 10px;
            /*padding: 10px;*/
            background: #F0F8FF;
            overflow: scroll;
            margin-bottom: 15px;
            max-height: 200px;
            min-height: 50px;

            .order-detail {
                /*width: 100%;*/
                /*height: 100px;*/
                padding: 10px;
                background: #F0F8FF;
                font-size: 12px;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 10px;
                    right: 10px;
                    bottom: 0;
                    height: 1px;
                    background: #fff;
                }
            }

            .refund-record {
                /*width: 100%;*/
                min-height: 88px;
                background: #F0F8FF;
                font-size: 12px;
                padding: 10px;

                .refund-record-label {
                    font-weight: 700;
                    /*height: 35px;*/
                    line-height: 20px;
                    margin-bottom: 8px;
                }

                .refund-record-table {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .word-desc {
        line-height: 40px;
        padding-right: 20px;
        font-size: 14px;
        color: #909399;
    }

    .btn-border-319 {
        border-color: #319BF7;
        color: #319BF7;
        /*font-size: 14px;*/
    }

    .btn-border-e6a23c {
        /*border-color: #e6a23c;*/
        color: #004789;;
        font-size: 14px;

        &:hover {
            background: #004789;;
            /*border-color: #004789;;*/
            color: #fff;
        }
    }

    .btn-border-FF3B30 {
        border-color: #FF3B30;
        color: #FF3B30;
        /*font-size: 14px;*/
    }

    /deep/ .sect_btn_select {
        background: #409EFF;
        border-color: #409EFF;
        color: #fff;

        &.el-button {
            &:before {
                opacity: 1;
            }

            &:hover {
                background: #409EFF !important;
                border-color: #409EFF;
                color: #fff;
            }
        }
    }
}
</style>
<style lang="scss">
.order-list-table {
    width: 100%;

    tr {
        background: #fff;
        min-height: 30px;
        font-size: 12px;
        width: 100%;

        th, td {
            padding: 5px;
            border: 1px solid #F0F8FF;
            min-width: 70px;
            /*max-width: 120px;*/
            text-align: center;
            line-height: 22px;
            word-wrap: break-word;
        }

    }

    .font-title-w6 {
        font-weight: 600;
    }
}

</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont order-list">
                <slot>
                    <search-form @searchOrder="handleSearch" ref="searchForm"></search-form>
                </slot>
                <div class="order-list-main" v-for="(item, index) in orderList" :key="item.id">
                    <order-main :order-info="item"></order-main>
                    <el-row class="clear" style="background: #ffffff;">
                        <div style="float: left" class="btn-left-list">
                            <el-button type="text"
                                       :class="{'sect_btn_select': item.isShow&&item.showIndex==1}" size="small"
                                       @click="showChange(index, 1)">订单详情
                            </el-button>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isShow&&item.showIndex==2}"
                                       @click="showChange(index, 2)" v-if="userInfo.refType === '0'">
                                流水记录
                            </el-button>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isShow&&item.showIndex==3}"
                                       @click="showChange(index, 3)" v-if="userInfo.refType === '0'">
                                订单日志
                            </el-button>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isShow&&item.showIndex==4}"
                                       @click="showChange(index, 4)">
                                检票信息
                            </el-button>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isShow&&item.showIndex==5}"
                                       @click="showChange(index, 5)">
                                客服备注
                            </el-button>
                            <invoice-info-popover :info="item"
                                                  @click.native="showChange(index, 6)"
                                                  :selected="item.isShow&&item.showIndex==6"></invoice-info-popover>
                        </div>
                    </el-row>
                    <div class="order-list-detail" v-if="item.isShow&&item.showIndex==1">
                        <div class="refund-record" v-if="childOrderList&&childOrderList.length>0">
                            <table class="refund-record-table order-list-table">
                                <tr>
                                    <th style="width: 50px;min-width: 50px">序号</th>
                                    <th>子订单状态</th>
                                    <th>订单来源</th>
                                    <th>子订单ID</th>
                                    <th>下单时间</th>
                                    <th>资源名称</th>
                                    <th style="width: 50px;min-width: 50px">预订份数</th>
                                    <th style="width: 50px;min-width: 50px">取消份数</th>
                                    <th v-if="userInfo.refType === '0'">单价金额(¥)</th>
                                    <th v-if="userInfo.refType === '0'">订单金额(¥)</th>
                                    <th v-if="userInfo.refType === '0'">应收金额(¥)</th>
                                    <th v-if="userInfo.refType === '0'">实收金额(¥)</th>
                                    <th v-if="userInfo.refType === '0'">取消费(¥)</th>
                                    <th v-if="userInfo.refType === '0'">实退金额(¥)</th>
                                    <!--                                    <th>结算金额(¥)</th>-->
                                </tr>

                                <tr v-for="(or, idx) in childOrderList" :key="or.id">
                                    <td style="width: 50px;min-width: 50px">{{ idx + 1 }}</td>
                                    <td style="width: 90px">{{ getStatus(or.status).text }}</td>
                                    <td style="width: 80px">{{ or.createBy == 0 ? 'TDS' : '导游端' }}</td>
                                    <td style="width: 130px">{{ or.subOrderNo || or.id }}</td>
                                    <td style="width: 135px">{{ or.createDate | dateCus('yyyy-MM-dd hh:mm:ss') }}</td>
                                    <td style="width: 180px;min-width: 180px">{{ or.resourceName }}</td>
                                    <td style="width: 50px;min-width: 50px">{{ or.num }}</td> <!--预订份数-->
                                    <td style="width: 50px;min-width: 50px">{{ or.refundNum }}</td> <!--取消份数-->
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--单价金额-->
                                        <!--                                        {{or.detailsOrderAmount | currency}}-->
                                        {{ or.unitPrice | currency }}
                                    </td>
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--订单金额-->
                                        {{ or.detailsOrderAmount | currency }}
                                        <!--                                        {{ ((or.unitPrice * 100) * or.num) / 100  | currency}}-->
                                    </td>
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--应收金额-->
                                        {{ or.detailsOrderAmount | currency }}
                                        <!--                                        {{ ((or.unitPrice * 100) * or.num) / 100  | currency}}-->
                                    </td>
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--实收金额-->
                                        <span
                                            v-if="or.status === 11 || (or.status === 72 && (or.cancelAmount == 0 || or.refundAmount == 0))">0.00</span>
                                        <span v-else>{{ or.detailsOrderAmount | currency }}</span>
                                        <!--                                        {{ ((or.unitPrice * 100) * or.num) / 100  | currency}}-->
                                    </td>
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--取消费-->
                                        <span
                                            v-if="or.status === 11 || (or.status === 72 && or.cancelAmount == 0)">0.00</span>
                                        <!--                                        <span v-else>{{ // (or.detailsOrderAmount * 100 - or.refundAmount * 100) / 100 | currency}}</span>-->
                                        <span v-else>{{ or.cancelAmount | currency }}</span>
                                    </td>
                                    <td style="width: 65px" v-if="userInfo.refType === '0'"> <!--实退金额-->
                                        <span
                                            v-if="or.status === 11 || (or.status === 72 && or.refundAmount == 0)">0.00</span>
                                        <span v-else>{{ or.refundAmount | currency }}</span>
                                    </td>
                                    <!--                                    <td style="width: 65px">{{(or.num * or.settleUnitAmount) | currency}}</td> &lt;!&ndash;结算金额&ndash;&gt;-->
                                </tr>
                            </table>
                        </div>
                        <p v-else style="padding: 12px">没有相关记录</p>
                    </div>
                    <div class="order-list-detail" v-if="item.isShow&&item.showIndex==2">
                        <div class="refund-record" v-if="flowLogList&&flowLogList.length>0">
                            <table class="refund-record-table order-list-table">
                                <tr>
                                    <th colspan="2">收款金额：{{ item.totalOrderAmount | currency }}</th>
                                    <th colspan="3">
                                        实退总额：{{
                                            (item.guiderRefundAmount * 1000 + item.tdsRefundAmount * 1000) / 1000 | currency
                                        }}
                                    </th>
                                    <th>
                                        取消费总额：{{
                                            (item.tdsCutAmount * 1000 + item.guiderCutAmount * 1000) / 1000 | currency
                                        }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>日期时间</th>
                                    <th>订单来源</th>
                                    <th>流水类型</th>
                                    <th>支付方式</th>
                                    <th>金额(¥)</th>
                                    <th>流水号</th>
                                </tr>
                                <tr v-for="or in flowLogList" :key="or.id">
                                    <td style="width: 130px">{{ or.createDate | dateCus('yyyy-MM-dd hh:mm:ss') }}</td>
                                    <td style="width: 80px">{{ or.createBy == 0 ? 'TDS' : '导游端' }}</td>
                                    <td style="width: 90px">
                                        {{ or.type | formatDesc(flowTypes) }}
                                    </td>
                                    <td style="width: 90px">{{ or.paymentChannel | formatDesc(payChannelItem) }}</td>
                                    <td style="width: 90px" :style="{color:or.type==1?'#DB9117':'#333'}">
                                        {{ or.payAccount | currency }}
                                    </td>
                                    <td style="width: 150px">{{ or.id }}</td>
                                </tr>
                            </table>
                        </div>
                        <p v-else style="padding: 12px">没有相关记录</p>
                    </div>
                    <div class="order-list-detail" v-if="item.isShow&&item.showIndex==3">
                        <div class="refund-record" v-if="orderLogList&&orderLogList.length>0">
                            <table class="refund-record-table order-list-table">
                                <tr>
                                    <th>日期时间</th>
                                    <!--                                    <th>子订单ID</th>-->
                                    <!--                                    <th>操作平台</th>-->
                                    <th>操作人员</th>
                                    <th>操作内容</th>
                                </tr>
                                <tr v-for="or in orderLogList" :key="or.id">
                                    <td style="width: 200px">{{ or.operateTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</td>
                                    <!--                                    <td style="width: 200px">{{ or.subOrderNo || or.id }}</td>-->
                                    <!--                                    <td style="width: 200px">{{ or.createBy == 0 ? 'TDS' : '导游端' }}</td>-->
                                    <td style="width: 200px">{{ or.operateName }}</td>
                                    <td>{{ or.operateContent }}</td>
                                </tr>
                            </table>
                        </div>
                        <p v-else style="padding: 12px">没有相关记录</p>
                    </div>
                    <div class="order-list-detail" v-if="item.isShow&&item.showIndex==4">
                        <div class="refund-record" v-if="ticketUserInfoList&&ticketUserInfoList.length>0">
                            <!--                            <table class="refund-record-table order-list-table">-->
                            <!--                                <tr>-->
                            <!--                                    <th>时间</th>-->
                            <!--                                    <th>工号</th>-->
                            <!--                                    <th>套餐名称+订单来源</th>-->
                            <!--                                    <th>子订单号</th>-->
                            <!--                                    <th>检票站点</th>-->
                            <!--                                    <th>检票资源名称</th>-->
                            <!--                                </tr>-->
                            <!--                                <tr v-for="(or,index) in ticketInfo" :key="index">-->
                            <!--                                    <td style="width: 200px">{{or.createTime | dateCus('yyyy-MM-dd hh:mm:ss')}}</td>-->
                            <!--                                    <td style="width: 200px">{{or.createUserLoginNumber }}</td>-->
                            <!--                                    <td>{{or.resourceName}}({{or.createBy == 1 ? '导游端' : "TDS"}})</td>-->
                            <!--                                    <td>{{or.subOrderNo || or.subOrderId}}</td>-->
                            <!--                                    <td>{{or.stationName}}</td>-->
                            <!--                                    <td>{{getTicket(or.scanQrCodeType).name}}</td>-->
                            <!--                                </tr>-->
                            <!--                            </table>-->
                            <table class="refund-record-table order-list-table">
                                <tr>
                                    <th>资源名称</th>
                                    <th>子订单号</th>
                                    <th>扫码时间</th>
                                    <th>游客姓名</th>
                                    <th>手机号码</th>
                                    <th>证件类型</th>
                                    <th>证件号码</th>
                                    <th>核销状态</th>
                                </tr>
                                <tr v-for="(or,index) in ticketUserInfoList" :key="index">
                                    <td>{{ or.userResourceName }}</td>
                                    <td>{{ or.subOrderNo }}</td>
                                    <td>
                                        <span
                                            v-if="or.voucherUsedTime">{{
                                                or.voucherUsedTime | dateCus('yyyy-MM-dd hh:mm:ss')
                                            }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{ or.tripUserName }}</td>
                                    <td>{{ or.tripUserMobile }}</td>
                                    <td>{{ or.certificateType | formatDesc(certificateItem) }}</td>
                                    <td>{{ or.certificateNo }}</td>
                                    <td>{{ or.voucherStatus | formatDesc(voucherList) }}</td>
                                </tr>
                            </table>
                        </div>
                        <p v-else style="padding: 12px">没有相关记录</p>
                    </div>
                    <!--客服备注-->
                    <div class="order-list-detail" v-if="item.isShow&&item.showIndex==5">
                        <el-form size="small" :inline="true" style="margin: 16px">
                            <el-form-item>
                                <el-input type="textarea" :rows="4" style="width: 600px"
                                          v-model="item.remark"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitEditOrderRemark(item)"
                                           :loading="saveRemarkBtnLoading">保存
                                </el-button>
                                <el-button @click="showChange(index)">关闭</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-main>

        <pagination-foot
            ref="paginationFoot"
            :total="pageInfo.total"
            @update:pageInfo="handlePageInfo">
        </pagination-foot>
        <!--        <el-footer class="com-eTrip-footer" height="64px">-->
        <!--            <div class="eTrip-footer-cont table-page-number">-->
        <!--                <div class="block">-->
        <!--                    <el-pagination-->
        <!--                        @current-change="handleCurrentChange"-->
        <!--                        :current-page="currentPage"-->
        <!--                        :page-size="10"-->
        <!--                        layout="total, prev, pager, next, jumper"-->
        <!--                        :total="totalCount">-->
        <!--                    </el-pagination>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </el-footer>-->

    </div>


</template>

<script type="text/ecmascript-6">
import {sortBy} from '@/common/js/Utils'
import searchForm from '../components/searchForm/';
import orderMain from '../components/orderMain/'
import InvoiceInfoPopover from '../components/invoice-info-popover'
import {payWays, orderPayWays, flowTypes} from '../data/index'
import dataInfo from '@/data/orderData/data'
import {certificateItem} from '@/data/index'
import paginationFoot from "@/components/pagination-foot/index";

const voucherList = [
    {value: '0', label: '未使用'},
    {value: '1', label: '已使用'},
    {value: '2', label: '已取消'},
    {value: '3', label: '已过期'},
]

export default {
    name: 'orderList',
    //定义模版数据
    data() {
        return {
            flowTypes,
            form: {
                pageIndex: 1,
                pageSize: 10
            },
            certificateItem,
            voucherList,
            saveRemarkBtnLoading: false,
            currentPage: 1,
            pageInfo: {},
            orderList: [],
            childOrderList: [],
            flowLogList: [],
            orderLogList: [],
            orderStatusList: [
                {type: 11, text: '待付款'},
                {type: 31, text: '待使用'},
                {type: 40, text: '进行中'},
                {type: 41, text: '已使用'},
                {type: 51, text: '已过期'},
                {type: 72, text: '已取消'},
                {type: 73, text: '出票失败'},
                {type: 82, text: '已核销'}
            ],
            ticketInfo: [],
            ticketUserInfoList: [],
            ticketTypeList: [
                {name: '小交通', value: 1},
                {name: '音乐台', value: 2},
                {name: '讲解器', value: 3},
                {name: '梅花节', value: 4}
            ],
            payWays,
            orderPayWays,
            payChannelItem: dataInfo.payChannelItem,
        }
    },

    components: {searchForm, orderMain, InvoiceInfoPopover, paginationFoot},
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //定义事件方法
    methods: {
        handleSearch(data) {
            this.$refs.paginationFoot.changePage(1);
            this.pageInfo.currentPage = 1;
            this.getOrderList(data);
        },
        handlePageInfo(pageInfo) {
            this.pageInfo = {
                ...this.pageInfo,
                ...pageInfo
            }
            this.getOrderList(this.$refs['searchForm'].searchForm);
        },
        // search(data) {
        // this.currentPage = 1;
        // this.handleSearch()
        // console.log(333);
        // },
        async getOrderList(data) {//订单列表
            let form = data;
            form.pageSize = this.pageInfo.pageSize;
            form.pageIndex = this.pageInfo.currentPage;
            if (form.orderStatus === -1) delete form.orderStatus;
            if (form.admissionTime && form.admissionTime.length == 2) {
                form.startUseTime = form.admissionTime[0];
                form.endUseTime = form.admissionTime[1];
                delete form.admissionTime;
            }
            if (form.orderTime && form.orderTime.length == 2) {
                form.startCreateOrderTime = +new Date(form.orderTime[0]);
                form.createOrderTimeStart = +new Date(form.orderTime[0]);
                form.endCreateOrderTime = +new Date(form.orderTime[1] + " 23:59:59");
                delete form.orderTime;
            }
            this.loadingShow()
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketOrderList', form, 'POST');
            this.loadingHide()
            if (res.success && res.data) {
                this.pageInfo.total = res.data.total;
                let ticketData = res.data.data
                ticketData.forEach(item => {
                    item.cancelAmount = (item.totalOrderAmount * 1000 - (item.guiderRefundAmount * 1000 + item.tdsRefundAmount * 1000)) / 1000
                })
                this.orderList = ticketData;
            }
        },
        showChange(index, showIndex) {
            let data = this.orderList[index];
            this.orderList.forEach((item, idx) => {
                if (index != idx)
                    item.isShow = false;
                else {
                    if (item.showIndex != showIndex && showIndex)
                        item.isShow = true;//!this.orderList[index].isOrderChange;
                    else if (item.showIndex == showIndex && !item.isShow)
                        item.isShow = true;
                    else item.isShow = false;
                }
            })
            data.showIndex = showIndex;
            this.$set(this.orderList, index, data);
            if (this.orderList[index].isShow) {
                switch (showIndex) {
                    case 1:
                        this.getChildOrder(data);
                        break;
                    case 2:
                        this.getFlowLog(data);
                        break;
                    case 3:
                        this.getOrderLog(data);
                        break;
                    case 4:
                        // this.getChildOrder(data);
                        this.getUserInfoListByOrder(data);
                        break;
                    default:
                        break;
                }
            }
        },
        async getChildOrder(item) {
            let form = {orderId: item.id};
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketDetailOrderByOrderId', form, 'POST');
            // this.loadingHide();
            if (res.success && res.data) {
                this.ticketInfo = [];
                this.childOrderList = res.data;
                res.data.forEach(item => {
                    if (item.scanQrCodeInfoDtoList && item.scanQrCodeInfoDtoList.length > 0) {
                        item.scanQrCodeInfoDtoList.forEach(s => {
                            s.createTime = +new Date(s.createTime)
                            let data = s;
                            data.resourceName = item.resourceName;
                            data.createBy = item.createBy;
                            this.ticketInfo.push(data);
                        })
                    }
                })
                this.ticketInfo = sortBy(this.ticketInfo, 'createTime')
                this.ticketInfo = sortBy(this.ticketInfo, 'createTime', 1)
            }
        },
        // 获取中山陵游客信息列表
        async getUserInfoListByOrder(item) {
            let form = {orderId: item.id};
            const res = await this.http('/galaxyZslTicketOrderApi/selectTripUserInfoListByOrderId', form, 'POST');
            // this.loadingHide();
            if (res.success && res.data) {
                this.ticketUserInfoList = res.data
            }
        },

        async getFlowLog(item) {
            let form = {orderId: item.id};
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketPayDetailByOrderId', form, 'POST');
            // this.loadingHide();
            if (res.success && res.data) {
                let data = res.data;
                this.flowLogList = data;
            }
        },
        async getOrderLog(item) {
            let form = {
                orderId: item.id
            };
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketOrderLog', form, 'POST');
            // this.loadingHide();
            if (res.success && res.data) {
                this.orderLogList = res.data;
            }
        },
        async getTicketInfo(item) {
            let form = {
                orderId: item.id
            };
            let res = await this.http('/galaxyZslTicketOrderApi/listTicketOrderLog', form, 'POST');
            // this.loadingHide();
            if (res.success && res.data) {
                this.orderLogList = res.data;
            }
        },
        getStatus(status) {
            const info = this.orderStatusList.find(item => {
                return status == item.type
            }) || {};
            return info;
        },
        getTicket(type) {
            const typeInfo = this.ticketTypeList.find(item => {
                return (type == item.value)
            }) || {};
            return typeInfo;
        },
        //添加客服备注
        async submitEditOrderRemark(item) {//添加客服信息
            let form = {
                id: item.id,
                remark: item.remark
            };
            this.saveRemarkBtnLoading = true
            let res = await this.http('/galaxyZslTicketOrderApi/updateTicketOrderInfo', form, 'POST');
            if (res.success)
                this.$message.success('保存成功!')
            else
                this.messageError(res.errors[0].message)
            this.saveRemarkBtnLoading = false;
        }
    },
    //监听模版变量
    watch: {}

}
</script>

