<template>
    <el-main class="com-eTrip-section booking-and-modification-rules">
        <div class="eTrip-section-cont">
            <el-form size="small" :model="form" :rules="rules" ref="formRef" label-position="left" v-loading="loading"
                     :disabled="!editable">
                <fieldset>
                    <h4>积分基本规则</h4>
                    <h4>1、初始分值</h4>
                    <el-form-item label-width="300px" prop="initValue" :rules="[{required:true,message:'请填写'}]">
                        <span class="mr10" slot="label">上线初期及旅行社重置后，积分初始分值为</span>
                        <el-input-number style="width: 200px" :min="0" v-model="form.initValue"></el-input-number>
                        <span class="ml10">分</span>
                    </el-form-item>
                    <el-form-item label-width="410px" prop="initDate" :rules="[{required:true,message:'请填写'}]">
                        <span class="mr10" slot="label">每年指定日期，所有帐号积分自动恢复至初始值。指定日期为</span>
                        <el-date-picker v-model="form.initDate" type="date" format="每年M月d日"
                                        value-format="yyyy-MM-dd"
                                        placeholder="请选择"></el-date-picker>
                    </el-form-item>
                    <el-form-item label-width="380px" prop="restoreValue" :rules="[{required:true,message:'请填写'}]">
                        <span class="mr10" slot="label">等级不佳旅行社，下线7天惩罚期满后，积分自动恢复至 </span>
                        <el-input-number style="width: 200px" v-model="form.restoreValue"></el-input-number>
                        <span class="ml10">分</span>
                    </el-form-item>
                </fieldset>
                <fieldset>
                    <h4>2、等级区间</h4>
                    <div style="display: flex;align-items: center">
                        <span class="mr10 mb15">优秀：积分 ≥</span>
                        <el-form-item prop="grade.excellent"
                                      :rules="[{required:true,message:'请填写'}]">
                            <el-input-number style="width: 200px"
                                             v-model="form.grade.excellent"
                                             :min="0" :max="100"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15">分， 100分封顶</span>
                    </div>
                    <div style="display: flex;align-items: center">
                        <span class="mr10 mb15">良好：</span>
                        <el-form-item prop="grade.good.max"
                                      :rules="[{required:true,message:'请填写'}]">
                            <el-input-number disabled style="width: 200px" v-model="form.grade.good.max" :min="0"
                                             :max="100"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15 mr10">分 ＞ 积分 ≥</span>
                        <el-form-item prop="grade.good.min">
                            <el-input-number style="width: 200px"
                                             v-model="form.grade.good.min" :min="0"
                                             :max="(form.grade.good.max-1)||100"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15">分</span>
                    </div>
                    <div style="display: flex;align-items: center">
                        <span class="mr10 mb15">一般：</span>
                        <el-form-item prop="grade.fair.max"
                                      :rules="[{required:true,message:'请填写'}]">
                            <el-input-number disabled style="width: 200px"
                                             :max="100"
                                             v-model="form.grade.fair.max"
                                             :min="0"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15 mr10">分 ＞ 积分 ≥</span>
                        <el-form-item prop="grade.fair.min">
                            <el-input-number style="width: 200px" v-model="form.grade.fair.min"
                                             :min="0" :max="(form.grade.fair.max-1)||100"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15 ">分</span>
                    </div>
                    <div style="display: flex;align-items: center">
                        <span class="mr10 mb15">不佳：积分 &lt;</span>
                        <el-form-item prop="grade.poor"
                                      :rules="[{required:true,message:'请填写'}]">
                            <el-input-number disabled style="width: 200px" v-model="form.grade.poor" :min="0"
                                             :max="100"></el-input-number>
                        </el-form-item>
                        <span class="ml10 mb15">分</span>
                    </div>
                </fieldset>
                <fieldset>
                    <h4>积分加减规则</h4>
                    <h4>1、履约入园</h4>
                    <div class="admission-rules">
                        <ul class="mb10">
                            <li><span>履约率：</span></li>
                            <li><span>分值：</span></li>
                            <li><span>更新方式和频率：</span></li>
                        </ul>
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">上一自然周入园团队数 ≥ </span>
                                    <el-form-item prop="performanceRate.performance.num"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 140px"
                                                         v-model="form.performanceRate.performance.num"
                                                         :min="0"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15 mr10">个且履约率达到</span>
                                    <el-form-item prop="performanceRate.performance.performanceRate"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 140px"
                                                         v-model="form.performanceRate.performance.performanceRate"
                                                         :min="0"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15">%</span>
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.performance.action"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.performance.action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10"
                                                  prop="performanceRate.performance.fraction"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.performance.fraction"
                                                         :max="100" :min="0"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，每周一0时更新</span>
                            </li>
                        </ul>
                        <ul class="mb10">
                            <li><span>入园人次：</span></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">上一自然周入园游客人数 ≥ </span>
                                    <el-form-item prop="performanceRate.oneTripUserCount.num"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 200px"
                                                         :min="0"
                                                         v-model="form.performanceRate.oneTripUserCount.num"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15 mr10">人次</span>
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item>
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.oneTripUserCount.action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.oneTripUserCount.fraction"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.oneTripUserCount.fraction"
                                                         :min="0" :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，每周一0时更新</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">上一自然周入园游客人数 ≥ </span>
                                    <el-form-item prop="performanceRate.towTripUserCount.num"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 200px" :min="0"
                                                         v-model="form.performanceRate.towTripUserCount.num"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15 mr10">人次</span>
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.towTripUserCount.action"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.towTripUserCount.action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.towTripUserCount.fraction"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.towTripUserCount.fraction"
                                                         :min="0" :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，每周一0时更新</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">上一自然周入园游客人数 ≥ </span>
                                    <el-form-item prop="performanceRate.thirdTripUserCount.num"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 200px" :min="0"
                                                         v-model="form.performanceRate.thirdTripUserCount.num"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15 mr10">人次</span>
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.thirdTripUserCount.action"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.thirdTripUserCount.action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.thirdTripUserCount.fraction"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.thirdTripUserCount.fraction"
                                                         :min="0" :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，每周一0时更新</span>
                            </li>
                        </ul>
                        <!--                        <ul class="mb10">-->
                        <!--                            <li><span>有责取消：</span></li>-->
                        <!--                            <li></li>-->
                        <!--                            <li></li>-->
                        <!--                        </ul>-->
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">有责取消：每有责取消 1 次</span>
                                    <!--                                    <el-form-item>-->
                                    <!--                                        <el-input-number style="width: 200px" value="1" disabled></el-input-number>-->
                                    <!--                                    </el-form-item>-->
                                    <!--                                    <span class="ml10 mb15 mr10">次</span>-->
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.cancelAction"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.cancelAction"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.cancel"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px" :min="0" :max="100"
                                                         v-model="form.performanceRate.cancel"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，实时生效</span>
                            </li>
                        </ul>
                        <!--                        <ul class="mb10">-->
                        <!--                            <li><span>爽约：</span></li>-->
                        <!--                            <li></li>-->
                        <!--                            <li></li>-->
                        <!--                        </ul>-->
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">爽约：每爽约 1 次</span>
                                    <!--                                    <el-form-item>-->
                                    <!--                                        <el-input-number style="width: 200px" :min="0" :value="1"-->
                                    <!--                                                         disabled></el-input-number>-->
                                    <!--                                    </el-form-item>-->
                                    <!--                                    <span class=" mb15 ">次</span>-->
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.nonPerformanceAction"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.nonPerformanceAction"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.nonPerformance"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.nonPerformance" :min="0"
                                                         :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，实时生效</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">未履约下线：每触发下线1次</span>
                                    <!--                                    <el-form-item>-->
                                    <!--                                        <el-input-number style="width: 200px" :min="0" :value="1"-->
                                    <!--                                                         disabled></el-input-number>-->
                                    <!--                                    </el-form-item>-->
                                    <!--                                    <span class=" mb15 ">次</span>-->
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item prop="performanceRate.updateRatioAction"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.performanceRate.updateRatioAction"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" prop="performanceRate.updateRatio"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px"
                                                         v-model="form.performanceRate.updateRatio" :min="0"
                                                         :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，实时生效</span>
                            </li>
                        </ul>
                    </div>
                </fieldset>
                <fieldset>
                    <h4>2、团队管理</h4>
                    <div class="admission-rules">
                        <ul class="mb10">
                            <li><span>规范停车：</span></li>
                            <li><span>分值：</span></li>
                            <li><span>更新方式和频率：</span></li>
                        </ul>
                        <ul v-for="vm in creditRatingOptions" :key="vm.value">
                            <li>
                                <div style="display: flex;align-items: center">
                                    <span class="mr10 mb15">上一自然周，在景区停车场规范停车 ≥</span>
                                    <el-form-item :prop="'groupManage.'+vm.key+'.count'"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 200px" :min="0"
                                                         v-model="form.groupManage[vm.key].count"></el-input-number>
                                    </el-form-item>
                                    <span class="ml10 mb15 mr10">次</span>
                                </div>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item :prop="'groupManage.'+vm.key+'.action'"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="form.groupManage[vm.key].action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" :prop="'groupManage.'+vm.key+'.fraction'"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px" :min="0" :max="100"
                                                         v-model="form.groupManage[vm.key].fraction"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li class="mb15">
                                <span>系统判定，每周一0时更新</span>
                            </li>
                        </ul>
                        <ul class="mb10">
                            <li>
                                <span>自定义积分规则：</span>
                            </li>
                            <li></li>
                            <li></li>
                        </ul>
                        <ul style="align-items: flex-start" class="custom-points-rules"
                            v-for="(otherRule,index) in form.otherRule" :key="otherRule.flg">
                            <li style="background: #FFFFFF;border-radius: 2px;">
                                <el-form-item :prop="'otherRule.'+index+'.name'"
                                              :rules="[{required:true,message:'请填写积分项目名称，10字以内'}]">
                                    <div style="display: flex;align-items: center;">
                                        <el-input style="flex: 1;"
                                                  v-model="otherRule.name"
                                                  :maxlength="10"
                                                  placeholder="填写积分项目名称，10字以内">
                                            <template slot="prepend">
                                                <span>项目名称：</span>
                                            </template>
                                            <template slot="append">
                                                <el-link type="danger" :disabled="!editable"
                                                         @click="deleteOtherRule(index)">删除
                                                </el-link>
                                            </template>
                                        </el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item :prop="'otherRule.'+index+'.info'"
                                              :rules="[{required:true,message:'请填写关于积分规则的具体描述'}]">
                                    <div style="margin-top: 1px">
                                        <el-input type="textarea"
                                                  v-model="otherRule.info"
                                                  style="border: none"
                                                  placeholder="填写关于积分规则的具体描述"
                                                  show-word-limit :maxlength="150"></el-input>
                                    </div>
                                </el-form-item>
                            </li>
                            <li>
                                <div style="display: flex;align-items: center">
                                    <el-form-item :prop="'otherRule.'+index+'.action'"
                                                  :rules="[{required:true,message:'请选择'}]">
                                        <ApiSelect :options="integrateOptions"
                                                   v-model="otherRule.action"></ApiSelect>
                                    </el-form-item>
                                    <el-form-item class="ml10" :prop="'otherRule.'+index+'.fraction'"
                                                  :rules="[{required:true,message:'请填写'}]">
                                        <el-input-number style="width: 120px" :max="100" :min="0"
                                                         v-model="otherRule.fraction"></el-input-number>
                                    </el-form-item>
                                </div>
                            </li>
                            <li style="padding-top: 5px">
                                <span>手动录入，实时生效</span>
                            </li>
                        </ul>
                    </div>
                </fieldset>
            </el-form>
        </div>
        <el-footer class="footer-bar">
            <p>积分规则编辑</p>
            <div>
                <el-button type="primary" style="width: 200px" plain v-if="editable"
                           :disabled="form.otherRule.length>=10" @click="handleAddOtherRule">+
                    自定义积分加减规则
                </el-button>
                <el-button type="primary" style="width: 120px;margin-left: 10px" v-if="editable" :loading="loading"
                           @click="handleSubmit">保存
                </el-button>
                <el-button type="primary" style="width: 120px;margin-left: 10px" v-else
                           @click="handleEdit">编辑
                </el-button>
            </div>
        </el-footer>
    </el-main>
</template>
<script>
import {defineComponent} from 'vue'
import {creditRatingOptions} from '@/data/creditRating'
import {createCreditRuleApi, selectCreditRuleApi, updateCreditRuleApi} from "@/www/urls/credit";

export default defineComponent({
    data() {
        return {
            loading: false,
            editable: false,
            creditRatingOptions,
            integrateOptions: [{label: '加', value: 0}, {label: '减', value: 1}],
            form: {
                initValue: undefined,
                initDate: undefined,
                restoreValue: undefined,
                grade: {
                    excellent: undefined,
                    good: {
                        max: undefined,
                        min: undefined
                    },
                    fair: {
                        max: undefined,
                        min: undefined
                    },
                    poor: undefined
                },
                performanceRate: {// 积分加减规则
                    cancel: undefined,// 有责取消减分
                    cancelAction: undefined,
                    nonPerformance: undefined,// 爽约减分
                    nonPerformanceAction: undefined,// 爽约减分
                    updateRatio: undefined,
                    updateRatioAction: undefined,//未履约减分
                    performance: {
                        num: undefined,//  团队数
                        performanceRate: undefined,// 履约率
                        fraction: undefined,// 分数
                        action: undefined
                    },
                    oneTripUserCount: {// 上一周入园游客数 第一行
                        num: undefined,// 数量
                        fraction: undefined,// 分数
                        action: undefined
                    },
                    towTripUserCount: {
                        num: undefined,// 数量
                        fraction: undefined,// 分数
                        action: undefined
                    },
                    thirdTripUserCount: {
                        num: undefined,// 数量
                        fraction: undefined,// 分数
                        action: undefined
                    }
                },
                groupManage: {//停车规范
                    excellent: {
                        count: undefined,// 次数
                        fraction: undefined// 分数
                    },
                    good: {
                        count: undefined,// 次数
                        fraction: undefined// 分数
                    },
                    fair: {
                        count: undefined,// 次数
                        fraction: undefined// 分数
                    },
                    poor: {
                        count: undefined,// 次数
                        fraction: undefined// 分数
                    }
                },
                otherRule: [
                    {
                        flg: +new Date(),
                        name: undefined,//   名称
                        info: undefined,//   详细
                        fraction: undefined//  分数
                    }
                ]
            },
            rules: {
                'grade.good.min': [{
                    validator: (rule, value, callback) => {
                        const {max} = this.form.grade.good;
                        if (!value) {
                            callback(new Error('请填写'));
                        } else if (max && value >= max) {
                            callback(new Error('值必须小于最大值'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'blur'
                }],
                'grade.fair.min': [{
                    validator: (rule, value, callback) => {
                        const {max} = this.form.grade.fair;
                        if (!value) {
                            callback(new Error('请填写'));
                        } else if (max && value >= max) {
                            callback(new Error('值必须小于最大值'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'blur'
                }]
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const ret = await this.getData()
            if (ret && this.$route.query.type === '1')
                this.editable = true;
        },
        async getData() {
            try {
                const ret = await selectCreditRuleApi();
                if (ret.success) {
                    let f = {};
                    for (let key in ret.data) {
                        try {
                            f[key] = JSON.parse(ret.data[key]);
                        } catch (err) {
                            f[key] = ret.data[key]
                        }
                    }
                    if (f.performanceRate) {
                        f.performanceRate.cancelAction = f.performanceRate.cancel < 0 ? 1 : 0;
                        f.performanceRate.cancel = Math.abs(f.performanceRate.cancel);
                        f.performanceRate.nonPerformanceAction = f.performanceRate.nonPerformance < 0 ? 1 : 0;
                        f.performanceRate.nonPerformance = Math.abs(f.performanceRate.nonPerformance);
                        f.performanceRate.updateRatioAction = f.performanceRate.updateRatio < 0 ? 1 : 0;
                        f.performanceRate.updateRatio = Math.abs(f.performanceRate.updateRatio);
                        f.performanceRate.performance.action = f.performanceRate.performance.fraction < 0 ? 1 : 0;
                        f.performanceRate.performance.fraction = Math.abs(f.performanceRate.performance.fraction);
                        f.performanceRate.oneTripUserCount.action = f.performanceRate.oneTripUserCount.fraction < 0 ? 1 : 0;
                        f.performanceRate.oneTripUserCount.fraction = Math.abs(f.performanceRate.oneTripUserCount.fraction);
                        f.performanceRate.towTripUserCount.action = f.performanceRate.towTripUserCount.fraction < 0 ? 1 : 0;
                        f.performanceRate.towTripUserCount.fraction = Math.abs(f.performanceRate.towTripUserCount.fraction);
                        f.performanceRate.thirdTripUserCount.action = f.performanceRate.thirdTripUserCount.fraction < 0 ? 1 : 0;
                        f.performanceRate.thirdTripUserCount.fraction = Math.abs(f.performanceRate.thirdTripUserCount.fraction);
                        f.groupManage.excellent.action = f.groupManage.excellent.fraction < 0 ? 1 : 0;
                        f.groupManage.excellent.fraction = Math.abs(f.groupManage.excellent.fraction);
                        f.groupManage.good.action = f.groupManage.good.fraction < 0 ? 1 : 0;
                        f.groupManage.good.fraction = Math.abs(f.groupManage.good.fraction);
                        f.groupManage.fair.action = f.groupManage.fair.fraction < 0 ? 1 : 0;
                        f.groupManage.fair.fraction = Math.abs(f.groupManage.fair.fraction);
                        f.groupManage.poor.action = f.groupManage.poor.fraction < 0 ? 1 : 0;
                        f.groupManage.poor.fraction = Math.abs(f.groupManage.poor.fraction);
                        f.otherRule.forEach((item, i) => {
                            item.flg = +new Date() + i;
                            item.action = item.fraction < 0 ? 1 : 0;
                            item.fraction = Math.abs(item.fraction);
                        })
                    }
                    this.credit_rule = f;
                    if (this.credit_rule.id) {
                        this.form = Object.assign({}, this.form, this.credit_rule);
                    }
                    return true
                } else {
                    this.$message.error(ret.errors[0].message)
                    return false
                }
            } catch (err) {
                console.log(err);
                this.$message.error(err.toString())
                return false
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            this.loading = true
            try {
                await this.$refs['formRef'].validate();
                const form = JSON.parse(JSON.stringify(this.form));
                form.performanceRate.cancel = Number(`${form.performanceRate.cancelAction === 1 ? '-' : ''}${form.performanceRate.cancel}`)
                form.performanceRate.nonPerformance = Number(`${form.performanceRate.nonPerformanceAction === 1 ? '-' : ''}${form.performanceRate.nonPerformance}`)
                form.performanceRate.updateRatio = Number(`${form.performanceRate.updateRatioAction === 1 ? '-' : ''}${form.performanceRate.updateRatio}`)
                form.performanceRate.performance.fraction = Number(`${form.performanceRate.performance.action === 1 ? '-' : ''}${form.performanceRate.performance.fraction}`)
                form.performanceRate.oneTripUserCount.fraction = Number(`${form.performanceRate.oneTripUserCount.action === 1 ? '-' : ''}${form.performanceRate.oneTripUserCount.fraction}`)
                form.performanceRate.towTripUserCount.fraction = Number(`${form.performanceRate.towTripUserCount.action === 1 ? '-' : ''}${form.performanceRate.towTripUserCount.fraction}`)
                form.performanceRate.thirdTripUserCount.fraction = Number(`${form.performanceRate.thirdTripUserCount.action === 1 ? '-' : ''}${form.performanceRate.thirdTripUserCount.fraction}`)
                form.groupManage.excellent.fraction = Number(`${form.groupManage.excellent.action === 1 ? '-' : ''}${form.groupManage.excellent.fraction}`)
                form.groupManage.good.fraction = Number(`${form.groupManage.good.action === 1 ? '-' : ''}${form.groupManage.good.fraction}`)
                form.groupManage.fair.fraction = Number(`${form.groupManage.fair.action === 1 ? '-' : ''}${form.groupManage.fair.fraction}`)
                form.groupManage.poor.fraction = Number(`${form.groupManage.poor.action === 1 ? '-' : ''}${form.groupManage.poor.fraction}`)
                form.otherRule.forEach((item) => {
                    item.flg = undefined;
                    item.fraction = Number(`${item.action === 1 ? '-' : ''}${item.fraction}`)
                })
                let f = {};
                for (let key in form) {
                    if (typeof form[key] === 'object')
                        f[key] = JSON.stringify(form[key]);
                    else
                        f[key] = form[key]
                }
                await this.$confirm('保存后立即生效', '确认保存？')
                if (this.credit_rule)
                    await updateCreditRuleApi(f)
                else
                    await createCreditRuleApi(f)
                this.$message.success('保存成功！')
                this.editable = false;
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        handleEdit() {
            this.editable = true;
            this.getData();
        },
        deleteOtherRule(index) {
            this.form.otherRule.splice(index, 1);
        },
        handleAddOtherRule() {
            this.form.otherRule.push({
                flg: +new Date()
            })
        }
    },
    watch: {
        'form.grade.excellent'(n) {
            this.form.grade.good.max = n;
            if (this.form.grade.good.min) {
                this.$nextTick(() => {
                    this.$refs['formRef'].validateField(['grade.good.min'])
                })
            }
        },
        'form.grade.good.min'(n) {
            this.form.grade.fair.max = n;
            if (this.form.grade.fair.min) {
                this.$nextTick(() => {
                    this.$refs['formRef'].validateField(['grade.fair.min'])
                })
            }
        },
        'form.grade.fair.min'(n) {
            this.form.grade.poor = n;
        }
    }
})
</script>


<style scoped lang="scss">
.custom-points-rules::v-deep {
    li:first-child {
        .el-form-item__content {
            border: 1px solid #D8DCE6;
            border-radius: 2px;
        }

        .el-form-item__content > div:first-child {
            border-bottom: 1px solid #D8DCE6;
        }

        textarea {
            height: 80px;
        }

        textarea, input {
            border: none;
        }

        .el-input-group__prepend,
        .el-input-group__append {
            background-color: #fff;
            border: none;
        }
    }
}

.booking-and-modification-rules {
    fieldset {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 20px;
        padding-left: 45px;

        h4 {
            margin-bottom: 10px;
            margin-left: -25px;
        }

        span {
            font-size: 14px;
            color: #333;
        }

    }

    .footer-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.06);
        width: calc(100% + 60px);
        height: 100%;
        margin-left: -30px;
        padding: 0 30px;
    }

    .admission-rules {
        ul {
            display: flex;
            align-items: center;

            li:first-child {
                flex: 1;
            }

            li:nth-child(2n) {
                width: 250px;
                margin-left: 20px;
            }

            li:last-child {
                width: 180px;
                margin-left: 20px;
            }
        }
    }
}
</style>
