<style lang="scss" scoped>
</style>
<template>
    <span>
        <span @click="handleShow" v-loading="loading">
            <slot></slot>
        </span>
        <el-dialog
            title="数量明细"
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            width="800px">
            <el-form inline>
                <el-form-item>
                     <el-date-picker :value="info.accountDate" disabled type="date"></el-date-picker>
                </el-form-item>
                <el-form-item v-if="list && list.length>0">
                    <excel-export-btn
                        type="primary"
                        title="导出对账明细"
                        fileTitle="中山陵对账明细"
                        :fileDate="info.accountDate"
                        requestType="GET"
                        :url="`${exportTicket}${info.id}`"
                    >
                    </excel-export-btn>
                </el-form-item>
            </el-form>
            <el-table :data="list"

                      style="width: 100%"
                      show-summary
                      :summary-method="getSummaries"
                      :border="true">
                <el-table-column prop="stationName" label="单品名称">
                </el-table-column>
                   <el-table-column label="结算单价（元）" prop="scanStationAmount">
                        <template slot-scope="scope">
                            {{ scope.row.scanStationAmount | currency(1, '¥') }}
                        </template>
                </el-table-column>
                  <el-table-column label="核销数量（份）" prop="scanStationNum">
                     <template slot-scope="scope">
                         <item-detail :id="scope.row.scanAccountManagerId"
                                      :date="info.accountDate"
                                      :resourceType="scope.row.resourceType"
                                      :resourceName="scope.row.stationName">
                              <el-link type="primary">
                                    {{ scope.row.scanStationNum }}份
                                </el-link>
                         </item-detail>
                     </template>
                </el-table-column>
                 <el-table-column label="金额（元）" prop="totalAmount">
                        <template slot-scope="scope">
                            {{ scope.row.totalAmount | currency(1, '¥') }}
                        </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script type="text/ecmascript-6">
import ItemDetail from './item-details-dialog.vue'
import excelExportBtn from '@/components/excel-export-btn/index'
import {exportTicket} from '@/www/urls/zslBill'
export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            options: [],
            dialogVisible: false,
            list: [],
            exportTicket,
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        ItemDetail,
        excelExportBtn
    },
    //定义事件方法
    methods: {
        async getData() {
            this.loading = true;
            const ret = await this.http(`/galaxyZslTicketOrderApi/getTicketCheckRecordDetail/${this.info.id}`);
            if (ret.success && ret.data) {
                this.list = ret.data;
                this.dialogVisible = true;
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        },
        handleShow() {
            this.getData();
        },
        getSummaries({columns, data}) {
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return
                }
                if (index === 1) {
                    sums[index] = '';
                    return
                }
                const values = data.map(item => Number(item[column.property]));
                if (index === 2)
                    sums[index] = values.reduce((a, b) => a + b, 0)
                if (index === 3)
                    sums[index] = '¥' + (values.reduce((a, b) => a + b, 0)).toFixed(2)
            })
            return sums;
        },
    },
    //监听模版变量
    watch: {}

}
</script>