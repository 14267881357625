<style lang="scss" scoped>
.cascader-city {

    .province-city-box{
        display: flex;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        .province-content{
            position: relative;
            &:before{
                position: absolute;
                content: '';
                display: block;
                top: 0;
                bottom: 0;
                right: 0;
                width: 1px;
                background:#DCDFE6;
            }
        }
    }
    .province-content{
        width: 170px;
    }
    .city-content{
        width: 200px;
    }
    &::v-deep{
        .el-cascader{
            width: 100%;
        }
        .province-city-box{
            .is-disabled{
                .el-input__inner{
                    background: #F5F7FA;
                }
            }
            .el-input__inner{
                border: 0;
                border-radius: 0;
                background: transparent;
            }
        }
    }
}
</style>
<template>
    <div class="cascader-city">
        <el-cascader
            v-if="showType === '0'"
            :options="cityOptions"
            :value="value"
            clearable
            filterable
            :disabled="disabled"
            :style="containerStyle"
            :placeholder="placeholder"
            @change="handleChange">
        </el-cascader>
        <div class="province-city-box" v-if="showType === '1'">
            <el-select
                class="province-content"
                @change="handleProvinceCity(provinceCode)"
                v-model="provinceCode"
                :placeholder="provinceTitle">
                <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-select
                class="city-content"
                @change="handleProvinceCity(provinceCode, cityCode)"
                v-model="cityCode"
                :disabled="provinceCode ? false : true"
                :placeholder="cityTitle">
                <el-option
                    v-for="item in cityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCascaderCityData} from '@/urls/'

export default {
    //定义模版数据
    data() {
        return {
            cityOptions: [],  // 联级城市信息
            cityList: [], // 市列表
            provinceCode: '', // 省短码
            cityCode: '', // 市短码
        }
    },
    props: {
        showType: {
            type: String,
            default: '0'
        },
        value: {
            type: Array,
            default: () => null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        containerStyle: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: '请选择省市'
        },
        provinceTitle: {
            type: String,
            default: '请选择省'
        },
        cityTitle: {
            type: String,
            default: '请选择市'
        }
    },
    //主件被加载完成
    mounted() {
        this.getData();
    },
    //定义事件方法
    methods: {
        async getData() {
            const ret = await getCascaderCityData();
            if (ret) {
                this.cityOptions = ret.map((p) => {
                    return {
                        ...p,
                        label: p.name,
                        value: p.shortCode,
                        children: p.cityDtos.map((c) => ({
                            ...c,
                            label: c.name,
                            value: c.shortCode
                        }))
                    }
                })
            }
        },
        handleProvinceCity(provinceCode, cityCode) {
            let data = {}
            if (provinceCode) data.provinceCode = provinceCode
            if (cityCode) data.cityCode = cityCode
            this.cityList = this.cityOptions.find(item => item.value === provinceCode).children
            this.$emit('update:provinceCity', data)
        },
        handleClear() {
            this.provinceCode = ''; // 省短码
            this.cityCode = ''; // 市短码
        },
        handleChange(data) {
            this.$emit('onChange', data)
            this.$emit('input', data)
        }
    },
    //监听模版变量
    watch: {
        provinceCode(n) {
            if (n) this.cityCode = ''
        }
    }

}
</script>
