<style lang="scss" scoped>
.list-content {
    height: auto;
    display: flex;
    border-bottom: 1px solid #F0F2F5;
    padding-bottom: 20px;
    background: #ffffff;
    padding-top: 10px;
    word-wrap: break-word;
    word-break: break-all;

    .label {
        color: #909399;
        display: inline-block;
    }

    p {
        /*line-height: 24px;*/
        span {
            color: #909399;
            display: inline-block;

            &:first-child {
                width: 85px;
            }
        }
    }

    .list-content-first {
        width: 350px;
        border-right: 1px solid #F0F2F5;
        padding: 0 10px;
        font-size: 12px;

        p {
            line-height: 22px;
        }

        .first-top {
            /*height: 100px;*/
        }

        .first-bottom {
            /*height: 72px;*/
            margin-top: 20px;
        }
    }

    .list-content-second {
        width: 250px;
        /*margin-left: 10px;*/
        /*height: 100%;*/
        border-right: 1px solid #F0F2F5;
        font-size: 14px;

        p {
            line-height: 24px;
        }

        .second-content {

            padding: 0 10px; /*height: calc(100% - 20px);*/
        }
    }

    .list-content-third {
        width: 296px;
        /*margin-left: 10px;*/
        border-right: 1px solid #F0F2F5;
        padding: 0 10px;
        font-size: 14px;

        p {
            line-height: 24px;
        }

        .third-top {
            /*height: 104px;*/
        }

        .third-bottom {
            margin-top: 20px;
            /*height: 104px;*/
        }
    }

    .list-content-fourth {
        width: calc(100% - 952px);
        height: 100%;
        padding: 0 10px;
        font-size: 14px;

        p {
            line-height: 18px;
        }

        span:first-child {
            width: auto;
        }

        .fourth-top {
            /*height: 105px;*/
            /*padding: 10px;*/
            margin-bottom: 20px;
        }

        .fourth-bottom {
            margin-bottom: 20px;
            /*height: 112px;*/
            /*padding: 10px;*/
        }
    }

    .content {
        /*width: calc(100% - 100px);*/
        line-height: 18px;
    }
}

.item-bar {
    display: flex;
}

.item-bar-label {
    min-width: 85px;
    color: #909399;
}

.list-content /deep/ {
    .finance-confirm {
        margin-top: 5px;

        .el-input-group {
            border-radius: 4px;
            width: 155px;
        }

        .el-input__inner {
            height: 30px;
            line-height: 30px;
            padding: 0 12px;
            font-size: 12px;
        }

        .el-input-group__append {
            vertical-align: baseline;
        }

        .el-button {
            padding: 9px 12px;
            background: #1989FA;
            border-color: #1989FA;
            color: #fff;
            border-radius: 0 4px 4px 0;
            font-size: 12px;
        }
    }
}

</style>
<template>

    <div class="list-content clear">
        <div class="list-content-first">
            <div class="first-top">
                <p><span>订单状态：</span><span style="font-weight: 600;color:#333">{{ getOrderInfo.statusName }}</span>
                </p>
                <!--<p><span>订单编号：</span><span style="font-weight: 600;color:#333">{{orderInfo.orderNo}}</span></p>-->
            </div>
            <div class="first-bottom">
                <div class="item-bar">
                    <div class="item-bar-label">下单机构：</div>
                    <div>
                        {{ getOrderInfo.companyName || '-' }}
                    </div>
                </div>
                <p style="display: flex">
                    <span>订单ID：</span>{{ getOrderInfo.ticketOrderNo }}
                </p>
                <div style="display: flex">
                    <span class="label">源平台订单号：</span>
                    <div style="flex: 1">
                        <p v-for="vm in getOrderInfo.thirdOrderIds" :key="vm">
                            {{ vm }}
                        </p>
                    </div>
                </div>
                <p style="display: flex">
                    <span>产品名称：</span>{{ getOrderInfo.productNames.join('，') }}
                </p>
            </div>
        </div>
        <div class="list-content-second">
            <div class="second-content">
                <p><span>团号：</span>{{ getOrderInfo.groupNo || '-' }}</p>
                <p><span>入园日期：</span>{{ getOrderInfo.useTime | dateCus('yyyy-MM-dd') }}</p>
                <p><span>入园时段：</span>{{ getOrderInfo.tripStartTime }} - {{ getOrderInfo.tripEndTime }}</p>
                <p><span>导游姓名：</span>{{ getOrderInfo.guiderName || '-' }}</p>
                <p><span>手机号：</span>{{ getOrderInfo.guiderMobile || '-' }}</p>
                <p><span>下单时间：</span>{{ getOrderInfo.createDate | dateCus('yyyy-MM-dd hh:mm:ss') }}</p>
            </div>
        </div>
        <div class="list-content-third">
            <p style="font-weight: 600;font-size: 14px">
                <span style="color: #333; width: auto">订单金额：</span>
                <span style="color: #ff3b30;"
                      v-if="userInfo.refType === '0'">
                    {{ getOrderInfo.totalOrderAmount | currency }}
                </span>
                <span v-else>
                    {{ getOrderInfo.settleAmount | currency }}
                </span>
            </p>
            <!--            <p style="font-weight: 600;font-size: 14px" v-if="userInfo.refType === '0'">-->
            <!--                <span style="color: #333;">订单金额：</span><span-->
            <!--                style="color: #ff3b30"> </span>-->
            <!--            </p>-->
            <!--            <p style="font-weight: 600;font-size: 14px" v-else>-->
            <!--                <span style="color: #333;">订单金额：</span><span-->
            <!--                style="color: #ff3b30"> {{getOrderInfo.settleAmount | currency}}</span>-->
            <!--            </p>-->

            <!--            <p>应收金额：{{ getOrderInfo.totalOrderAmount | currency }}</p>-->
            <p>应收金额：{{ getOrderInfo.totalOrderAmount | currency }}</p>

            <p v-if="getOrderInfo.orderStatus === 72">
                实收金额：
                <span style="color: #333"
                      v-if="getOrderInfo.guiderRefundAmount * 1000 > 0 || getOrderInfo.tdsRefundAmount * 1000 > 0 || getOrderInfo.tdsCutAmount * 1000 > 0 || getOrderInfo.guiderCutAmount * 1000 > 0">
                    {{ getOrderInfo.totalOrderAmount | currency }}
                </span>
                <span style="color: #333" v-else>0.00</span>
            </p>
            <p v-if="getOrderInfo.orderStatus != 72">
                实收金额：
                <span style="color: #333" v-if="getOrderInfo.orderStatus != 11">
                    {{ getOrderInfo.totalOrderAmount | currency }}
                </span>
                <span style="color: #333" v-else>0.00</span>
            </p>
            <p>
                实退金额：{{
                    (getOrderInfo.guiderRefundAmount * 100 + getOrderInfo.tdsRefundAmount * 100) / 100 | currency
                }}</p>
            <!--            <p>取消费：{{getOrderInfo.cancelAmount | currency}}</p>-->
            <p>取消费：{{
                    (getOrderInfo.tdsCutAmount * 1000 + getOrderInfo.guiderCutAmount * 1000) / 1000 | currency
                }}</p>
            <!--            <p>景区结算金额：</p>-->

        </div>

        <div class="list-content-fourth">
            <div class="third-top">
                <p v-if="getOrderInfo.totalBorrowAudioNum">
                    <span>讲解器出借总数：</span>{{ getOrderInfo.totalBorrowAudioNum }}份
                </p>
                <p>
                    讲解器借出站点：{{ getOrderInfo.borrowAudioStationName || '-' }}
                </p>
                <p v-if="getOrderInfo.totalReturnAudioNum">
                    <span>讲解器归还总数：</span>{{ getOrderInfo.totalReturnAudioNum }}份
                </p>
                <p>
                    讲解器归还站点：{{ getOrderInfo.returnAudioStationName || '-' }}
                </p>
            </div>
        </div>


    </div>

</template>

<script type="text/ecmascript-6">
import {keepTwoDecimal} from '@/common/js/Utils';
import {getSeatModel} from '@/data/index'
import {customerServiceInterventionOrderStatus} from '@/data/creditRating'

export default {
    //定义模版数据
    data() {
        return {
            orderStatusList: [
                {value: 11, label: '待付款'},
                {value: 31, label: '待使用'},
                {value: 40, label: '进行中'},
                {value: 41, label: '已使用'},
                {value: 72, label: '已取消'},
                {value: 73, label: '出票失败'},
                {value: 51, label: '已过期'},
                ...customerServiceInterventionOrderStatus
            ],
        }
    },
    props: {
        orderInfo: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    //计算属性
    computed: {
        getOrderInfo() {
            let orderInfo = JSON.parse(JSON.stringify(this.orderInfo));
            const statusName = this.orderStatusList.find(item => {
                return item.value === orderInfo.orderStatus;
            })
            if (orderInfo.thirdOrderId) {
                try {
                    orderInfo.thirdOrderIds = JSON.parse(orderInfo.thirdOrderId)
                } finally {
                    console.log(323232);
                }
            }
            orderInfo.statusName = statusName.label;
            return orderInfo;
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //主件被加载完成
    mounted() {


    },
    //定义事件方法
    methods: {
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
                // console.log(info, type);
            }
            return info;
        },
        budgetPerChange(type, value) {
            switch (type) {
                case 1:
                    this.orderInfo.paymentAmount = keepTwoDecimal(value);
                    break;
                case 2:
                    this.orderInfo.actualSettleAmount = keepTwoDecimal(value);
                    break;
            }


        }, // 人均价格// 金额小数点格式化

        async updatePayAmountByOrder(orderId, paymentAmount) {
            let form = {
                id: orderId,
                paymentAmount: paymentAmount
            }
            let res = await this.http('/galaxyOrder/updatePayAmountByOrderId', form, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: '订单实收金额更新成功',
                    type: 'success'
                });
                this.$emit('updateOrderList')
            }

        }, // 更新订单实收金额
        async updateActualSettleAmount(orderId, actualSettleAmount) {
            let form = {
                id: orderId,
                actualSettleAmount: actualSettleAmount,
            }
            let res = await this.http('/galaxyOrder/updateActualSettleAmount', form, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: '订单实付金额更新成功',
                    type: 'success'
                });
                this.$emit('updateOrderList')

            }

        }, // 更新订单实付金额
    },
    //监听模版变量
    watch: {}

}
</script>
