<style lang="scss" scoped>
    .com-eTrip-section {
        height: calc(100vh - 210px);
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont supplier-list">
                <search-form @search="handleSearch" @export="handleExport"></search-form>
                <result-list :list="list"></result-list>
                <footer-table ref="footerTable" :detail="detail"></footer-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="detail.accountManagerDto.accountStatus===0">
            <div class="eTrip-footer-cont">
                <div style="text-align: right">
                    <el-button type="primary"
                               v-if="query.type==='1'"
                               @click="handleSubmit"
                               class="edit-button"
                               size="small">确认
                    </el-button>
                </div>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
    import SearchForm from './search-form.vue'
    import ResultList from './result-list.vue'
    import FooterTable from './footer-table.vue'

    export default {
        name:'zslBillDetail',
        //定义模版数据
        data() {
            return {
                form: {
                    orderId: null
                },
                detail: {
                    accountManagerDto: {},
                    list: []
                }
            }
        },
        //计算属性
        computed: {
            list() {
                const data = this.detail.list;
                if (!this.form.orderId)
                    return data
                let list = [];
                for (let da of data) {
                    let subList = []
                    for (let item of da.accountBackendDtoList) {
                        if (item.subOrderNo === this.form.orderId)
                            subList.push(item)
                    }
                    if (subList.length > 0)
                        list.push({
                            ...da,
                            accountBackendDtoList: subList
                        })
                }
                return list;
            },
            query() {
                const query = this.$route.query
                query.date = Number(query.date)
                return query;
            }
        },
        components: {
            SearchForm,
            ResultList,
            FooterTable
        },
        //主件被加载完成
        mounted: function () {
            this.getData();
        },
        //定义事件方法
        methods: {
            async getData() {
                this.loadingShow();
                const ret = await this.http(`/galaxyZslTicketOrderApi/getTicketScanAccountDetail/${this.$route.params.id}`)
                if (ret.success) {
                    this.detail = ret.data;
                }
                this.loadingHide();
            },
            async handleExport() {
                this.loadingShow();
                const ret = await this.http(`/galaxyZslTicketOrderApi/exportTicketScanAccountDetail/${this.$route.params.id}`, null, 'GET', true, 'blob')
                this.loadingHide();
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '中山陵对账单' + d + '.xls';
                a.href = window.URL.createObjectURL(ret);
                a.click();
            },
            handleSearch(form) {
                this.form = form;
            },
            async handleSubmit() {
                try {
                    const data = this.$refs['footerTable'].getConfirmData();
                    const {
                        manualNum,
                        manualAmount,
                        confirmNum,
                        confirmAmount
                    } = data;
                    let msg = `<div>
<b>中山陵结算金额</b>
<p><span style="width: 140px;display: inline-block;margin-bottom: 10px">检票份数：${manualNum}</span> <span>结算金额：${manualAmount}</span></p>
<b>对账单确认结算金额</b>
<p><span style="width: 140px;display: inline-block">检票份数：${confirmNum}</span> <span>结算金额：${confirmAmount}</span></p>
</div>`
                    await this.$confirm(msg, '确认对账单', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        dangerouslyUseHTMLString: true
                    })
                    this.loadingShow();
                    const id = this.$route.params.id;
                    const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketScanAccountManager', {
                        id,
                        accountStatus: 1
                    }, 'POST')
                    if (ret.success && ret.data) {
                        this.$message.success('确认对账成功！');
                        this.$router.replace(`/zslBillDetail/${id}?type=0&status=1&date=${this.query.date}`)
                    }
                    this.loadingHide();
                } catch (e) {
                    console.log(e);
                }
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
