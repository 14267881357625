<style lang="scss" scoped>

</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont supplier-list">
            <com-list-search>
                <div slot="list-search-before">
                    <el-form :inline="true">
                        <el-form-item>
                            <el-radio-group v-model="form.accountStatus">
                                <el-radio-button :label="null">全部</el-radio-button>
                                <el-radio-button :label="idx" v-for="(vm,idx) in accountStatus" :key="idx">
                                    {{ vm }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker
                                v-model="form.dates"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="选择出行日期"
                                end-placeholder="选择出行日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="handleReset">重置</el-button>
                            <el-button type="primary" @click="search()" :loading="loading">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <!--暂时不要 中山陵对接九天达之后-->
                <!--<div slot="list-search-after" class="list-search-after_sec">-->
                <!--<el-button style="margin-bottom: 15px;margin-right: 40px"-->
                <!--type="primary"-->
                <!--@click="handleExport"-->
                <!--plain>月账单导出-->
                <!--</el-button>-->
                <!--</div>-->

            </com-list-search>
            <div class="user-list-table el-table_border_none" style="margin-top: 10px">
                <settlement-form :list="tabData.data" @onSuccess="handleSuccess"
                                 ref="settlement-form"></settlement-form>
                <el-table class="table-info"
                          border
                          :data="tabData.data"
                          style="width: 100%">
                    <el-table-column
                        width="120"
                        label="状态">
                        <template slot-scope="scope">
                            <p>{{ accountStatus[scope.row.accountStatus] }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="120"
                        label="批次">
                        <template slot-scope="scope">
                            <p>{{ scope.row.accountDate | dateCus('yyyy-MM-dd') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="900游预审数量/金额"
                        width="200">
                        <template slot-scope="scope">
                            <div style="display: flex;align-items: center">
                                <quantity-details-dialog :info="scope.row">
                                    <el-link type="primary">
                                        {{ scope.row.totalNum }}份
                                    </el-link>
                                </quantity-details-dialog>
                                /
                                {{ scope.row.orderSettleAmount | currency(1, '¥') }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="中山陵核销份数/金额"
                        width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.zslAmount!=null">
                            {{ scope.row.zslNum }}份/
                            {{ scope.row.zslAmount | currency(1, '¥') }}
                            </span>
                            <span v-else style="color: #999">
                                请编辑
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="200"
                        label="确认结算数量/金额">
                        <template slot-scope="scope">
                            <span v-if="scope.row.scanSettleAmount!=null">
                                  {{ scope.row.useNum }}份/
                            {{ scope.row.scanSettleAmount | currency(1, '¥') }}
                            </span>
                            <span v-else style="color: #999">
                                请编辑
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="operateUserName"
                        label="确认人">
                    </el-table-column>
                    <el-table-column
                        prop="operateUserName"
                        label="确认单">
                        <template slot-scope="scope">
                            <look-images style="max-height: 120px;overflow: hidden"
                                :id="'confirmations' + scope.$index"
                                :imagesUrl="scope.row.recordManagerPhoto"
                            ></look-images>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作">
                        <template slot-scope="scope">
                            <edit-dialog :info="scope.row"
                                         v-if="!isFinance"
                                         @editSuccess="handleSuccess"></edit-dialog>
                            <el-checkbox v-if="isFinance&&scope.row.accountStatus===1"
                                         v-model="scope.row.checked">结算
                            </el-checkbox>
                            <el-button
                                class="ml10"
                                @click="handleConfirmations(scope.row, scope.$index)"
                                type="text">
                                上传确认单
                            </el-button>
                            <confirmations-dialog
                                :form="confirmations"
                                :index="scope.$index"
                                @confirmationsSuccess="handleConfirmationsSuccess"
                                @onClose="handleCloseConfirmations">
                            </confirmations-dialog>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-page-number">
                    <div class="block">
                        <el-pagination
                            @current-change="handelPageChange"
                            :current-page.sync="form.pageIndex"
                            :page-size="form.pageSize"
                            layout="total, prev, pager, next"
                            :total="tabData.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </el-main>

</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../../components/comListSearch/index.vue'
import EditDialog from './edit-dialog.vue'
import SettlementForm from './settlement-form.vue'
import QuantityDetailsDialog from './quantity-details-dialog.vue'
// import imageUpload from '@/components/imageUpload'
// import {fileIsPdf} from '@/common/js/common'
import confirmationsDialog from './confirmations-dialog'
import lookImages from '@/components/look-images/index'

const accountStatus = ["待确认", "待结算", "已结算"]

export default {
    name: 'zslBill',
    //定义模版数据
    data() {
        return {
            loading: false,
            settlementLoading: false,
            seeBusiness: false,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            form: {
                pageIndex: 1,
                pageSize: 10,
                startAccountDate: null,
                endAccountDate: null,
                accountStatus: null,
                dates: null,
            },
            tabData: {
                data: [],
                total: 0
            },
            confirmations: {
                show: false,
                recordManagerPhoto: []
            }
        }
    },
    //计算属性
    computed: {
        accountStatus() {
            return accountStatus
        },
        isFinance() {
            return this.$store.state.loginUserInfo.roleName === "总部财务"
        }
    },
    components: {
        comListSearch,
        EditDialog,
        SettlementForm,
        QuantityDetailsDialog,
        // imageUpload,
        confirmationsDialog,
        lookImages
    },
    //主件被加载完成
    mounted() {
        this.setDefaultState();
        this.search();
    },
    //定义事件方法
    methods: {
        handleReset() {
            this.form.startAccountDate = null;
            this.form.endAccountDate = null;
            this.form.accountStatus = null;
            this.form.dates = null;
        },
        getForm() {
            let form = {
                ...this.form
            }
            if (form.dates && form.dates.length > 0) {
                form.startAccountDate = +new Date(form.dates[0])
                form.endAccountDate = +new Date(form.dates[1])
            }
            delete form.dates;
            return form
        },
        async search() {
            this.loading = true;
            const ret = await this.http('/galaxyZslTicketOrderApi/listTicketCheckRecordManager', this.getForm(), 'POST');
            if (ret.success && ret.data) {
                this.tabData = ret.data;
            } else
                this.$message.error(ret.errors[0].message)
            this.loading = false;

        },
        handelPageChange(index) {
            this.form.pageIndex = index;
            this.search();
        },
        async handleExport() {
            this.loadingShow();
            const ret = await this.http('/galaxyZslTicketOrderApi/exportTicketScanAccountManager', this.getForm(), 'POST', true, 'blob')
            this.loadingHide();
            let d = new Date().getTime();
            let a = document.createElement('a');
            a.download = `中山陵月对账账单-${d}.xls`;
            a.href = window.URL.createObjectURL(ret);
            a.click();
        },
        //设置默认暂时状态
        setDefaultState() {
            this.form.accountStatus = null;
            if (this.isFinance)
                this.form.accountStatus = 1
        },
        //编辑成功
        handleSuccess() {
            this.search();
            this.$refs["settlement-form"].getTicketCheckRecordAmount();
        },
        //
        // imageUploadBusiness(item) {
        //     let {
        //         imageUrl,
        //         index
        //     } = item[0]
        //     // this.determineForm.businessLincensePhoto = img;
        //     this.tabData.data[index].imgUrl = imageUrl
        //     this.seeBusiness = fileIsPdf(imageUrl);
        // },  //营业执照
        // 上传确认单
        handleConfirmations(item) {
            this.confirmations = {
                show: true,
                id: item.id,
                recordManagerPhoto: item.recordManagerPhoto
            }
        },
        // 关闭弹窗
        handleCloseConfirmations() {
            this.confirmations = {
                show: false,
                recordManagerPhoto: []
            }
        },
        // 上传成功
        handleConfirmationsSuccess(){
            this.search();
            this.handleCloseConfirmations()
        }
    },
    watch: {
        isFinance() {
            this.setDefaultState();
        }
    }

}
</script>
