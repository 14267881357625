<style lang="scss" scoped>
.search-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.item-date-width{
    margin-right: 10px;
    width: 260px;
}

.data-details {
    background: #fff;
    padding: 0 12px;
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont data-details">
                <div class="search-box">
                    <search-date is-yesterday @update:dateBtn="handleDateBtn"></search-date>
                    <excel-export-btn
                        type="primary"
                        size="small"
                        title="导出报表"
                        fileTitle="中山陵订单数据明细"
                        :form="dateForm"
                        url="/galaxyZslTicketOrderApi/exportZslOrderInfoNumList"
                    >
                    </excel-export-btn>
                </div>


<!--                <el-date-picker-->
<!--                    class="item-date-width"-->
<!--                    v-model="form.salesDate"-->
<!--                    type="daterange"-->
<!--                    range-separator="至"-->
<!--                    start-placeholder="起始日期"-->
<!--                    end-placeholder="截止日期"-->
<!--                    :picker-options="pickerOptions"-->
<!--                    @change="changeDatePicker"-->
<!--                    size="small"-->
<!--                    :clearable="false">-->
<!--                </el-date-picker>-->
<!--                <el-button-->
<!--                    type="text"-->
<!--                    v-for="item in dateBtnList"-->
<!--                    :key="item.value"-->
<!--                    :class="{'select' : dateSelect === item.value}"-->
<!--                    @click="handleDateBtn(item)">-->
<!--                    {{ item.label }}-->
<!--                </el-button>-->

                <div class="mt10">
                    <park-data-table :form="parkForm" class="mb10"></park-data-table>
                    <order-date-table :list="orderDateTable" :loading="loading" class="mb15"></order-date-table>
                </div>
            </div>


        </el-main>
    </div>
</template>

<script type="text/ecmascript-6">
import {dateTimeStamp, latestWeekArr} from "@/common/js/dayjsDate";
import parkDataTable from './components/park-data-table/index'
import orderDateTable from './components/order-date-table/index'
import http from "@/common/js/http";
import {decimalMul} from "@/common/js/decimal";
import SearchDate from "../reportForm/components/search-date/index";
import excelExportBtn from '@/components/excel-export-btn/index'

// const dateBtnList = [{value: 0, label: '近7日'}]
export default {
    //定义模版数据
    name: "guideList",
    data() {
        return {
            // dateSelect: 0,
            // dateBtnList,
            pickerOptions: {
                disabledDate: (current) => {
                    return current > +new Date()
                }
            },
            form: {
                salesDate: latestWeekArr()
            },
            parkForm: {},
            orderDateTable: [],
            loading: false
        };
    },
    components: {
        SearchDate,
        parkDataTable,
        orderDateTable,
        excelExportBtn
    },
    //计算属性
    computed: {
        dateForm(){
            let form = {
                startTime: dateTimeStamp(this.form.salesDate[0], '00:00:00'),
                endTime: dateTimeStamp(this.form.salesDate[1], '23:59:59'),
            }
            return form
        }
    },
    //主件被加载完成
    mounted: function () {
        this.init()
    },
    //定义事件方法
    methods: {
        init(){
            this.zslOrderInfoNum()
            this.zslOrderInfoNumList()
        },

        // handleDateBtn(item) {
        //     this.dateSelect = item.value
        //     switch (item.value) {
        //         case 0:
        //             this.form.salesDate = latestWeekArr()
        //             break;
        //     }
        //     this.init()
        // },

        handleDateBtn(dateArr) {
            this.form.salesDate = dateArr
            this.init()
        },
        // changeDatePicker(){
        //     this.dateSelect = ''
        //     this.init()
        // },
        // 中山陵订单数据概况
        async zslOrderInfoNum() {
            const res = await http('/galaxyZslTicketOrderApi/zslOrderInfoNum', this.dateForm, 'POST')
            if (res.success && res.data) {
                let resData = res.data
                resData.orderCancelRate = decimalMul(resData.orderCancelRate, 100)
                resData.noShowRate = decimalMul(resData.noShowRate, 100)
                resData.orderCompletionRate = decimalMul(resData.orderCompletionRate, 100)
                this.parkForm = resData
            }
        },
        // 中山陵订单数据按日期概况
        async zslOrderInfoNumList() {
            this.loading = true
            const res = await http('/galaxyZslTicketOrderApi/zslOrderInfoNumList', this.dateForm, 'POST')
            if (res.success && res.data) {
                this.orderDateTable = res.data
            }
            this.loading = false
        }
    },
    //监听模版变量
    watch: {}
};
</script>

