<style lang="scss" scoped>
.look-big-images{
    display: inline-block;
    .el-button{
        padding: 0;
    }
    .original-image-btn {
        position: absolute;
        left: 10px;
        top: 10px;

        a {
            color: #409EFF;
        }
    }


    &::v-deep{
        .dialog-middle-section{
            .notice-container{
                min-height: 300px;
                max-height: 600px;
            }
        }

    }
}
</style>
<template>
    <div class="look-big-images">
        <el-button type="text" @click="handleShow" :disabled="disabled">{{ title }}</el-button>
        <dialog-middle-section
            :show="show"
            :width="900"
            @onClose="handleShow"
        >
            <div slot="dialog-section">
                <el-button class="original-image-btn" type="text">
                    <a :href="imagesUrl" target="_blank">点击查看原图</a>
                </el-button>
                <look-images
                    :id="id"
                    :imagesUrl="imagesUrl"
                ></look-images>
            </div>
        </dialog-middle-section>

    </div>
</template>

<script type="text/ecmascript-6">
import dialogMiddleSection from '@/components/dialog-middle-section/index'
import lookImages from '@/components/look-images/index'

export default {
    //定义模版数据
    data() {
        return {
            show: false
        }
    },
    props: {
        title: {
            type: String,
            default: '查看大图'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 必传
        id: {
            type: String,
            default: ''
        },
        // 图片url
        imagesUrl: {
            type: String,
            default: ''
        }
    },
    components: {
        dialogMiddleSection,
        lookImages
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
    },
    //定义事件方法
    methods: {
        handleShow() {
            this.show = !this.show
        },
    },
    //监听模版变量
    watch: {}

}
</script>
