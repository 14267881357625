<style lang="scss" scoped>
.ticket-record::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        margin-bottom: 0;
        height: 32px
    }

    .common-list-search {
        /*padding: 10px 20px 20px 20px;*/
        .list-search-before {
            width: 100%;
        }

        .list-search-after {
            width: 0;
            display: none;
        }
    }
}

.eTrip-footer-cont {
    padding-right: 0;
}

.ticket-record-foot {
    display: flex;
    align-items: center;

    .ticket-record-statistics {
        /*flex: 1;*/
        width: 450px;

        span {
            margin-right: 30px;
            font-size: 14px;
        }
    }

    .table-page-number {
        width: calc(100% - 450px);
    }
}
</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont ticket-record">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form" size="small">
                            <el-form-item label="" style="width: auto">
                                <el-radio-group v-model="form.scanQrCodeType" @change="getScanQrCodeInfo(1)">
                                    <el-radio-button label="">全部</el-radio-button>
                                    <el-radio-button v-for="item in resourceOptions" :key="item.value"
                                                     :label="item.value">{{ item.label }}
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="">
                                <el-select v-model="form.stationName" placeholder="全部站台"
                                           @change="getScanQrCodeInfo(1)">
                                    <el-option label="全部站台" value=""></el-option>
                                    <el-option
                                        v-for="item in platformOptions"
                                        :key="item.type"
                                        :label="item.title"
                                        :value="item.title">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="" style="width: auto">
                                <el-date-picker
                                    value-format="yyyy-MM-dd 00:00:00"
                                    v-model="form.ticketDate"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="getScanQrCodeInfo(1)">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item style="width: auto">
                                <el-button @click="handleReset()">重置</el-button>
                                <!--<el-button @click="orderExport()" class="btn-border-319">务导出-->
                                <!--</el-button>-->
                            </el-form-item>
                            <el-form-item style="width: auto">
                                <!--<el-button @click="handleReset()">重置</el-button>-->
                                <el-button @click="orderExport()" type="primary">检票记录导出
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </com-list-search>
                <div class="mt10 el-table_border_none">
                    <el-table
                        border
                        :data="recordInfoList"
                        :height="tableH"
                        v-loading="loading"
                        style="width: 100%">
                        <el-table-column
                            label="序号"
                            width="80"
                            type="index">
                        </el-table-column>
                        <el-table-column
                            label="资源名称"
                            width="150">
                            <template slot-scope="scope">
                                <p>{{ scope.row.scanQrCodeType | resourceTypeDesc }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="订单ID"
                            prop="subOrderNo"
                            width="150">
                            <template slot-scope="scope">
                                <p>{{ scope.row.subOrderNo || scope.row.subOrderId }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="检票时间">
                            <template slot-scope="scope">
                                <p>{{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="站台">
                            <template slot-scope="scope">
                                <p>{{ scope.row.stationName }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="检票员工号"
                            width="110">
                            <template slot-scope="scope">
                                <p>{{ scope.row.createUserLoginNumber }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="份数"
                            width="110">
                            <template slot-scope="scope">
                                <p>{{ scope.row.scanMun }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="结算金额(￥)"
                            width="110">
                            <template slot-scope="scope">
                                <p>{{ scope.row.scanAmount }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont ticket-record-foot">
                <div class="ticket-record-statistics" v-if="userInfo.refType != '9'">
                    <span>总份数：{{ recordInfo.totalNum }}</span><span>结算金额：￥{{ recordInfo.totalAmount | currency }}</span>
                </div>
                <div class="table-page-number" :style="{'width':userInfo.refType == '9'?'100%':''}">
                    <div class="block">
                        <el-pagination
                            @current-change="currentPageChange"
                            :current-page.sync="tablePage.pageIndex"
                            :page-size="tablePage.pageSize"
                            layout="total, prev, pager, next"
                            :total="tablePage.totalPage">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-footer>

    </div>

</template>

<script type="text/ecmascript-6">
import {findItem} from '@/common/js/common'
import comListSearch from '../../../components/comListSearch/index.vue'
import Vue from 'vue';

const dateCus = Vue.filter('dateCus');
const resourceOptions = [
    {value: 1, label: '小交通'},
    {value: 2, label: '音乐台'},
    {value: 3, label: '讲解器'},
    {value: 4, label: '明孝陵(梅花节)'}
];
const platformOptions = [
    {type: 1, title: '中山陵停车场站'},
    {type: 2, title: '中山陵东站'},
    {type: 3, title: '灵谷寺站'},
    {type: 4, title: '音乐台站'},
    {type: 5, title: '东沟租赁站'},
    {type: 6, title: '灵谷寺租赁站'}
]
export default {
    //定义模版数据
    data() {
        return {
            tableH: 'calc(100vh - 306px)',
            form: {
                scanQrCodeType: '',
                stationName: ''
            },
            resourceOptions,
            platformOptions,
            recordInfo: {},
            recordInfoList: [],
            loading: false,
            tablePage: {  // 列表分页
                pageSize: 10,  // 每页显示条目个数
                totalPage: 1,  // 总条目个数
                pageIndex: 1,  // 当前页数
            },
        }
    },
    filters: {
        resourceTypeDesc(value) {
            return findItem(resourceOptions, value);
        }
    },
    components: {comListSearch},
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    //主件被加载完成
    mounted: function () {
        this.getScanQrCodeInfo(1)

    },
    //定义事件方法
    methods: {
        currentPageChange(index) {
            this.getScanQrCodeInfo(index);
        },
        handleReset() {
            this.form = {
                scanQrCodeType: '',
                stationName: ''
            }
            this.getScanQrCodeInfo(1)
        },
        async getScanQrCodeInfo(pageIndex) {  //
            let {
                    scanQrCodeType,
                    ticketDate,
                    stationName
                } = this.form,
                form = {
                    pageSize: this.tablePage.pageSize,
                    pageIndex: pageIndex,
                    scanQrCodeType,
                    stationName,
                };

            if (ticketDate) {
                form.scanDateStart = dateCus(ticketDate[0], 'yyyy-MM-dd');
                form.scanDateEnd = dateCus(ticketDate[1], 'yyyy-MM-dd');
            }
            this.loading = true;
            let res = await this.http('/galaxyZslTicketOrderApi/listBmsTicketOrderScanQrCodeInfo', form, 'POST');
            if (res.success && res.data) {
                this.recordInfo = res.data;
                if (res.data.scanQrCodeInfoDtos.data) {
                    this.recordInfoList = this.recordInfo.scanQrCodeInfoDtos.data;
                    this.tablePage.totalPage = this.recordInfo.scanQrCodeInfoDtos.total
                }
            } else {
                this.messageError('系统出错，请稍后!');
            }

            this.loading = false;

        },
        async orderExport() {
            this.loadingShow();
            let form = JSON.parse(JSON.stringify(this.form));
            console.log(form, "99999999999999999999");
            if (form.ticketDate && form.ticketDate.length == 2) {
                form.scanDateStart = form.ticketDate[0];
                form.scanDateEnd = form.ticketDate[1];
                delete form.admissionTime;
            }
            if (!form.stationName) delete form.stationName;
            if (!form.scanQrCodeType) delete form.scanQrCodeType;

            let url = '/galaxyZslTicketOrderApi/exportListBmsTicketScanQrInfo';
            try {
                let res = await this.http(url, form, 'POST', true, 'blob');
                console.log(res);
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '检票记录出表' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();

                // }
                this.loadingHide();
            } catch (e) {
                this.loadingHide();
                this.messageError('下载失败')
            }
        },
    },
    //监听模版变量
    watch: {}

}
</script>

