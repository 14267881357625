<style lang="scss" scoped>
.search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.report-table {
    &::v-deep {
        &.el-table {
            background-color: #fff;

            td, th {
                border-color: #DCDFE6;
            }

            tr {
                background-color: transparent;
            }

            .el-table__fixed {
                //    background-color: #F0F2F5;
            }

            .el-table__fixed-right {
                background-color: #F0F2F5;
                font-weight: 600;
                color: #999;
            }

            .el-table__header, .el-table__footer {
                th, td {
                    background-color: #F0F2F5;
                    font-weight: 600;
                    color: #999;
                }
            }

            .el-table__body {
                td {
                    padding: 4px 0;
                }

                .el-table__row {
                    td {
                        &:first-child {
                            background-color: #F0F2F5;
                            font-weight: 600;
                            color: #999;
                        }
                    }
                }
            }
        }

        .el-table__header {
            tr {
                th {
                    height: 57px;
                    padding: 5px 0;
                    width: 120px;
                    min-width: 120px;
                }

                th:first-child {
                    &:before {
                        content: '月份';
                        text-align: center;
                        position: absolute;
                        width: 120px;
                        height: 1px;
                        bottom: 22px;
                        right: 40px;
                    }

                    &:after {
                        content: '销量(份)';
                        text-align: center;
                        position: absolute;
                        width: 120px;
                        top: 5px;
                        left: 28px;
                    }

                    .cell {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 136px;
                        height: 1px;
                        background-color: #DCDFE6;
                        display: block;
                        text-align: center;
                        transform: rotate(24deg);
                        transform-origin: top left;
                    }
                }
            }
        }


    }
}
</style>
<template>

    <div>
        <div class="search-box">
            <el-date-picker
                type="year"
                size="small"
                format="yyyy年"
                v-model="sales.year"
                @change="changeDatePicker"
                :picker-options="pickerOptions"
                placeholder="选择年份"
                :clearable="false">
            </el-date-picker>
        </div>
        <el-table
            class="report-table"
            :data="sales.list"
            v-loading="loading"
            border
            show-summary
            :summary-method="getSummaries"
            style="width: 100%">
            <el-table-column
                label="月份/销量(份)"
                fixed
                width="123">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.month }}月
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                v-for="item in resourceList"
                :key="item.value"
                :label="item.label"
                width="150"
            >
                <template slot-scope="scope">
                    <div v-for="(numDtoList, numIdx) in scope.row.zslResourceNumDtoList" :key="numIdx">
                        <p>{{ numDtoList.resourceType === item.value ? numDtoList.num : '' }}</p>
                    </div>
                </template>

            </el-table-column>
            <el-table-column
                fixed="right"
                prop="summation"
                label="合计">
            </el-table-column>
        </el-table>
    </div>


</template>

<script type="text/ecmascript-6">
import {startEndYearArr} from '@/common/js/dayjsDate/index'
import {formatDesc} from "@/common/js/filter";
import {zslResourceItem} from "@/data/zslResourceData";

export default {
    //定义模版数据
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - new Date().getFullYear();
                }
            },
            zslResourceItem,
            sales: {
                year: new Date(),
                list: []
            },
            loading: false
        }
    },
    //计算属性
    computed: {
        dateForm() {
            let selectYear = startEndYearArr(this.sales.year)
            let form = {
                startTime: selectYear[0],
                endTime: selectYear[1]
            }
            return form
        },
        resourceList() {
            let list = zslResourceItem
            return list
        }
    },
    components: {},
    //主件被加载完成
    mounted() {
        this.getSalesList();
    },
    //定义事件方法
    methods: {
        changeDatePicker(obj) {
            this.sales.year = obj
            this.getSalesList()
        },
        totalSales(arr, type) {
            let data = []
            arr.forEach(item => {
                if (item.resourceType === type) data.push(item.num)
            })
            return data
        },
        //获取月销售报表
        async getSalesList() {
            this.loading = true
            const res = await this.http('/galaxyZslTicketOrderApi/zslProductSaleMonthNum', this.dateForm, 'POST')
            let resourceList = []
            if (res.success && res.data) {
                let listData = res.data.map(item => {
                    item.summation = 0
                    resourceList = [
                        ...resourceList,
                        ...item.zslResourceNumDtoList
                    ]
                    item.zslResourceNumDtoList.map(numItem => {
                        numItem.title = formatDesc(numItem.resourceType, zslResourceItem)
                        item.summation += numItem.num
                        return numItem
                    })
                    return item
                })
                this.sales.list = listData
                // console.log(listData);
                // this.sales.list = [...res.data.mouthSellDataDtoList];
                // const {
                //     totalNum: monthTotalNum,
                //     musicStandTotalNum: musicStandNum,
                //     musicAndTrafficTotalNum: musicAndTrafficNum,
                //     musticAndAudioTotalNum: musticAndAudioNum,
                //     musicTranfficAudioTotalNum: musicTranfficAudioNum,
                // } = res.data;
                // this.sales.list.push({
                //     month: '总销量',
                //     musicStandNum,
                //     musicAndTrafficNum,
                //     musticAndAudioNum,
                //     monthTotalNum,
                //     musicTranfficAudioNum,
                // })
            } else this.sales.list = []
            this.loading = false
        },
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            let totalObj = {}
            data.forEach(item => {
                item.zslResourceNumDtoList.forEach(item1 => {
                    totalObj[item1.resourceType] = totalObj[item1.resourceType] ? totalObj[item1.resourceType] + item1.num : item1.num
                })
            })
            const zslResourceList = JSON.parse(JSON.stringify(zslResourceItem))
            let totalObjList = zslResourceList.map(item => {
                if (totalObj[item.value]) return totalObj[item.value]
                else return 0
            })
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总销量';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                } else {
                    // sums[index] = Object.values(totalObj)[index - 1];
                    sums[index] = totalObjList[index - 1];
                }
            })
            return sums;
        }

    },
    //监听模版变量
    watch: {}

}
</script>
