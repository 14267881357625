/**
* Created by hansxing on 2019/4/10.
*/
<style lang="scss" scoped>
.guide-list {
    .guide-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .guide-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }
}

.guide-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }

}
</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.guide-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}

.guide-tour-list {
    .common-list-search .list-search-before {

        .el-form-item.el-form-item--small {
            width: auto;
            margin-bottom: 0;
            /*min-width: 320px;*/
            &.list-state {
                width: 175px;

                .el-form-item__label {
                    padding: 0;
                }

                .el-form-item__content {
                    width: calc(100% - 71px);
                }
            }

            &.list-state-1 {
                width: 185px;

                .el-form-item__label {
                    padding: 0;
                }

                .el-form-item__content {
                    width: calc(100% - 85px);
                }
            }
        }

        .el-form-item__content {
            /*width: auto;*/
        }
    }
}
</style>
<template>
    <div class="guide-tour-list">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="guide-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form" size="small">
                            <el-form-item style="width: 150px">
                                <el-input v-model="form.orderNo" placeholder="母订单号"></el-input>
                            </el-form-item>
                            <el-form-item label="母订单状态：" label-width="85px" class="list-state-1">
                                <el-select v-model="form.orderStatus" placeholder="母订单状态" style="width: 100%">
                                    <el-option label="全部" :value="null"></el-option>
                                    <el-option v-for="(item, idx) in orderStatusList" :key="idx" :label="item.text"
                                               :value="item.type"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-date-picker
                                    v-model="form.travelDate"
                                    type="daterange"
                                    value-format="yyyy/MM/dd"
                                    range-separator="至"
                                    start-placeholder="选择出行日期"
                                    end-placeholder="选择出行日期"
                                    style="width: 260px">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="预订渠道：" label-width="71px" class="list-state">
                                <el-select v-model="form.createBy" placeholder="是否可用">
                                    <el-option label="全部" :value="null"></el-option>
                                    <el-option label="tds端" :value="0"></el-option>
                                    <el-option label="导游端" :value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="handleReset()">重置</el-button>
                        <el-button type="primary" @click="handleSearch()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="guide-list-table">
                    <div style="line-height: 40px;font-size: 14px;font-weight: 600;padding-left: 10px">
                        导游：<span
                        v-if="guiderInfo.guiderName">{{ guiderInfo.guiderName }}（{{ guiderInfo.guiderMobile }}）</span>
                    </div>
                    <el-table
                        type="index"
                        class="table-info"
                        border
                        :data="guideTourList"
                        v-loading="tableLoading"
                        style="width: 100%"
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            width="50">
                        </el-table-column>
                        <el-table-column prop="orderNo" label="母订单号" width="110"></el-table-column>
                        <el-table-column width="120" label="母订单状态">
                            <template slot-scope="scope">
                                <p>{{ scope.row.ticketOrderStatus | orderStatusDesc }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="120" label="子订单号">
                            <template slot-scope="scope">
                                <p>{{ scope.row.subOrderNo ? scope.row.subOrderNo : scope.row.id }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="resourceName" width="120" label="产品名称"></el-table-column>
                        <el-table-column prop="num" width="120" label="份数"></el-table-column>
                        <el-table-column width="120" label="子订单金额">
                            <template slot-scope="scope">
                                <p>{{ scope.row.detailsOrderAmount | currency }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="出行日期" min-width="120">
                            <template slot-scope="scope">
                                <p>{{ scope.row.useTime | dateCus('yyyy-MM-dd') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="预订渠道" width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.createBy==0">tds端</p>
                                <p v-if="scope.row.createBy==1">导游端</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div style="width: 260px">
                子订单金额总计：￥{{ guiderInfo.totalAmount | currency }}
            </div>
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="handleSearch"
                        :current-page.sync="pageData.currentPage"
                        :page-size="pageData.pageSize"
                        layout="total, prev, pager, next"
                        :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import comListSearch from "../../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";

const orderStatusList = [
    {type: 11, text: '待付款'},
    {type: 21, text: '待确认'},
    {type: 31, text: '待使用'},
    {type: 40, text: '进行中'},
    {type: 41, text: '已使用'},
    {type: 51, text: '已过期'},
    {type: 72, text: '已取消'}
]
export default {
    //定义模版数据
    name: "guideTourList",
    data() {
        return {
            form: {
                orderStatus: null,
                isAvailable: null
            },
            guideTourList: [],
            tableLoading: false,
            orderStatusList,
            guiderInfo: {},

            pageData: {
                pageSize: 10, // 每页显示条目个数
                totalPage: 1, // 总条目个数
                currentPage: 1 // 当前页数
            },
        };
    },
    components: {
        SectionContent,
        comListSearch,
    },
    filters: {
        orderStatusDesc(value) {
            let ret = orderStatusList.find(({type}) => type === value)
            return (ret || {}).text
        }
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.handleSearch(1);
    },
    //定义事件方法
    methods: {
        handleReset() {
            this.form = {
                orderNo: '', //
                orderStatus: null,
                travelDate: [],
                createBy: null,
            };
            this.handleSearch(1);
        },
        async handleSearch(page) {
            this.tableLoading = true;
            let {
                    orderNo,
                    orderStatus,
                    createBy,
                    travelDate,
                } = this.form,
                form = {
                    orderNo,
                    orderStatus,
                    createBy,
                    guiderId: this.$route.params.id,
                    pageIndex: page,
                    pageSize: this.pageData.pageSize,
                };
            if (travelDate && travelDate[0]) {
                form.beginUseTime = new Date(travelDate[0]).getTime(); //搜索开始时间
                form.endUseTime = new Date(travelDate[1]).getTime(); // 搜索截至时间
            }
            let res = await this.http("/galaxyGuiderApi/getTicketOrderDetailsByGuiderId", form, "POST");
            this.tableLoading = false;
            if (res.success && res.data) {
                let info = res.data;
                this.guideTourList = info.orderData.data;
                this.pageData.totalPage = info.orderData.total;

                this.guiderInfo.guiderMobile = info.guiderMobile;
                this.guiderInfo.guiderName = info.guiderName;
                this.guiderInfo.totalAmount = info.totalAmount;
            }
        },
    },
    //监听模版变量
    watch: {}
};
</script>

