<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            有footer则添加class="section-has-footer"
            <div class="eTrip-section-cont">
                内容放这
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                footer 放这
            </div>
        </el-footer>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            async aaa() {  //
                let form = {};
                try {
                    let res = await this.http('', form, 'POST');
                    if (res.success) {
                        console.log(res);
                    } else {
                        this.messageError('系统出错，请稍后!');
                    }

                } catch (error) {
                    this.messageError()
                }


            },

        },
        //监听模版变量
        watch: {}

    }
</script>

