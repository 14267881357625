<style lang="scss" scoped>
.report-container{
    background: #fff;
    margin-bottom: 10px;
    padding: 15px;
    &-t{
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
        margin-bottom: 10px;
        position: relative;
        &:before{
            content: "";
            display: block;
            width: 2px;
            height: 14px;
            background: #329BF7;
            position: absolute;
            top: 50%;
            margin-top: -7px;
            border-radius: 2px;
            left: 0;
        }
        .sub-title{
            font-size: 14px;
            font-weight: normal;
            margin-left: 5px;
        }
    }
    &-s{
        padding: 0 10px;
    }
}
</style>


<template>
    <div class="report-container">
        <div class="report-container-t">
            {{ title }}<span class="sub-title">{{ subTitle }}</span>
        </div>
        <div class="report-container-s">
            <slot></slot>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">

export default {
    name: 'report-container',
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
