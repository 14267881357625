<style lang="scss" scoped>
    .status-text {
        color: #319bf7;
        margin-top: 20px;
        margin-bottom: 10px;
    }
</style>
<template>
    <el-popover
        placement="right"
        width="400"
        trigger="click">
        <h3>发票信息</h3>
        <div v-if="invoiceInfo">
            <p class="status-text">{{invoiceInfo.status | formatDesc(ticketOpenStatus)}}</p>
            <p>开票方式：电子票</p>
            <p>开票内容</p>
            <p>{{invoiceInfo.invoiceType | formatDesc(invoiceTypes)}}</p>
            <p>抬头：{{invoiceInfo.invoiceHead}}</p>
            <el-Divider></el-Divider>
            <p>推送手机：{{invoiceInfo.mobile}}</p>
            <p>推送邮箱：{{invoiceInfo.email}}</p>
            <el-Divider></el-Divider>
            <p>开票金额：{{invoiceInfo.invoiceAmount | currency(1, '¥')}}</p>
            <p>开票流水号：{{invoiceInfo.invoiceSerialNum}}</p>
            <p>开票时间：{{invoiceInfo.createTime | dateCus}}</p>
            <p>开票税号：{{invoiceInfo.invoiceTax}}</p>
            <p>开票代码：{{invoiceInfo.invoiceCode}}</p>
            <p>开票号码：{{invoiceInfo.invoiceNumber}}</p>
            <!--<p>发票pdf:{{invoiceInfo}}</p>-->
        </div>
        <p v-else style="color: #999;margin-top: 20px">
            无用户开票信息
        </p>
        <el-button type="text" slot="reference"
                   size="small"
                   :class="{'sect_btn_select': selected}">发票信息
        </el-button>
    </el-popover>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                ticketOpenStatus: [
                    {
                        label: '待开票',
                        value: 0
                    },
                    {
                        label: '已开票',
                        value: 1
                    }
                ],
                invoiceTypes: [
                    {
                        label: '个人',
                        value: 1
                    },
                    {
                        label: '公司普通',
                        value: 2
                    }
                ]
            }
        },
        props: {
            info: {
                type: Object,
                default: () => ({})
            },
            selected: Boolean
        },
        //计算属性
        computed: {
            invoiceInfo() {
                return this.info.orderInvoiceZslDto
            }
        }
    }
</script>
