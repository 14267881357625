<style lang="scss" scoped>
.search-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.panel-count-num {
    .panel-list {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        margin-bottom: 15px;
        li {
            width: 94px;
            height: 102px;
            background: #F0F2F6;
            border-radius: 6px;
            margin: 0 10px 10px 0;
            padding: 16px 0;
        }

        .list-num {
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;
        }
        .list-title{
            font-size: 12px;
        }
    }
}
</style>


<template>
    <div class="panel-count-num">
        <div class="search-box">
            <search-date @update:dateBtn="handleDateBtn"></search-date>

            <excel-export-btn
                type="primary"
                size="small"
                title="导出报表"
                fileTitle="产品日销量报表"
                :form="dateForm"
                url="/galaxyZslTicketOrderApi/exportZslProductSaleMonthNum"
            >
            </excel-export-btn>
        </div>

        <ul class="panel-list">
            <li v-for="(item, index) in productNumList" :key="index">
                <p class="list-num">
                    <panel-count
                        :style="{color: item.color, fontSize: '22px', fontWeight: '500'}"
                        :start-val="0"
                        :end-val="item.num"
                        :duration="item.duration"
                        separator=""/>
                </p>
                <p class="list-title" v-for="(tItem, tIndex) in item.title" :key="tIndex">{{ tItem }}</p>
            </li>
        </ul>
        <div>
            <ve-histogram :data="chartData" :settings="chartSettings" :extend="extend" :colors="bgColorArr"></ve-histogram>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
import PanelCount from 'vue-count-to'
import {latestWeekArr} from '@/common/js/dayjsDate/index'
import http from '@/common/js/http'
import {dateCus, formatDesc} from '@/common/js/filter'
import {zslResourceItem, zslResourceTypes} from '@/data/zslResourceData/index'
import VeHistogram from 'v-charts/lib/histogram.common'
import SearchDate from "./components/search-date/index";
import excelExportBtn from '@/components/excel-export-btn/index'
// import VeLine from 'v-charts/lib/line.common'
// require('v-charts/lib/line.common')

const bgColorArr = ['#759FF8', '#36E0B2', '#7685A4', '#FFC600', '#FF7459', '#63D1F3', '#A584D7', '#FFB26D', '#21A5A1', '#FFA8CC', '#8ED875']

export default {
    name: '',
    data() {
        return {
            dateSelect: 0,
            pickerOptions: {
                disabledDate: (current) => {
                    return current > +new Date()
                }
            },
            form: {
                salesDate: latestWeekArr()
            },
            bgColorArr,
            testDate: [],
            dateBtnList: [
                {
                    value: 0,
                    label: '最近一周'
                },
                {
                    value: 1,
                    label: '最近一月'
                },
                {
                    value: 2,
                    label: '本月'
                },
                {
                    value: 3,
                    label: '上月'
                },
            ],
            productNumList: [],
            chartSettings: {
                // showLine: ['中山陵陵寝预约']
            },
            extend: {
                series: {
                    type: 'bar',
                    barWidth: 10,
                    label: {show: true, position: "top", color: '#333',}
                }
            },
            chartData: {
                columns: ['日期'],
                rows: []
            }
        }
    },
    components: {
        SearchDate,
        PanelCount,
        VeHistogram,
        excelExportBtn
    },
    computed: {
        dateForm(){
            let form = {
                startTime: this.form.salesDate[0],
                endTime: this.form.salesDate[1]
            }
            return form
        }
    },
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.zslSaleNum();
            this.zslProductNum()
        },
        handleDateBtn(arr) {
            this.chartData = {
                columns: ['日期'],
                rows: []
            }
            this.form.salesDate = arr
            this.init()
        },
        // 产品销量汇总值 POST
        async zslProductNum() {
            const res = await http('/galaxyZslTicketOrderApi/zslProductSaleTotalNum', this.dateForm, 'POST')
            if (res.success && res.data) {
                let duration = 2600
                let list = JSON.parse(JSON.stringify(zslResourceItem))
                let listData = list.map((item, index) => {
                    item.color = bgColorArr[index]
                    item.duration = duration + 200 * index
                    let numInfo = res.data.find(numItem => numItem.resourceType == item.value)
                    if (numInfo && numInfo.num > 0) item.num = numInfo.num
                    else item.num = 0
                    item.title = item.label.split("+")
                    return item
                })
                this.productNumList = listData
            }
        },

        // 产品销量动态图
        async zslSaleNum() {
            const res = await http('/galaxyZslTicketOrderApi/zslProductSaleNum', this.dateForm, 'POST')
            if (res.success && res.data) {
                let listData = res.data.map(item => {
                    item.zslResourceNumDtoList.map(numItem => {
                        numItem.title = formatDesc(numItem.resourceType, zslResourceItem)
                        return numItem
                    })
                    return item
                })
                let columns = zslResourceItem.map(item => {
                    return item.label
                })

                this.chartData.columns = [...this.chartData.columns, ...columns]
                // let resourceList = JSON.parse(JSON.stringify(zslResourceItem))
                let types = zslResourceTypes()
                let dtoNumList = listData.map(item => {
                    let obj = {
                        '日期': dateCus(item.useTime, 'yyyy-MM-dd')
                    }
                    // resourceList.map(resourceItem => {
                    //     obj[types[resourceItem.value]] = 0
                    // })
                    item.zslResourceNumDtoList.map(dtoItem => {
                        obj[types[dtoItem.resourceType]] = dtoItem.num
                    })
                    return obj
                })
                this.chartData.rows = dtoNumList
            }
        }
    },
    watch: {},
}
</script>
