<template>
    <el-main class="com-eTrip-section intervention-order-detail" :class="showFooter?'section-has-footer':''">
        <div class="eTrip-section-cont" v-if="record">
            <h3><span v-if="[74,75,76].includes(record.ticketOrderInfoDto.orderStatus)">{{
                    record.ticketOrderInfoDto.orderStatus | formatDesc(customerServiceInterventionOrderStatus)
                }}</span><span v-else>已通过</span> |
                申请详情</h3>
            <el-descriptions :column="1" :colon="false">
                <el-descriptions-item label="申请理由">
                    {{ record.ticketOrderInfoDto.customerCreateInfo.reason }}
                </el-descriptions-item>
                <el-descriptions-item label="凭证图片">
                    <div style="position: relative" v-if="record.ticketOrderInfoDto.customerCreateInfo.image">
                        <el-image style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;opacity: 0;"
                                  :src="record.ticketOrderInfoDto.customerCreateInfo.image"
                                  :preview-src-list="[record.ticketOrderInfoDto.customerCreateInfo.image]"></el-image>
                        <el-button plain type="primary" size="small">内容预览</el-button>
                    </div>
                    <span v-else>无</span>
                </el-descriptions-item>
            </el-descriptions>
            <div style="display: flex;border-top: 1px solid #D8DCE6;padding-top: 20px;margin-top: 20px ">
                <el-descriptions :column="1">
                    <el-descriptions-item label="商品名称">{{ record.orderDetailsDtoList[0].resourceName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="团队编号">{{ record.ticketOrderInfoDto.groupNo }}
                    </el-descriptions-item>
                    <el-descriptions-item label="导游信息">
                        {{ record.ticketOrderInfoDto.guiderName }}，{{ record.ticketOrderInfoDto.guiderMobile }}
                        {{ record.ticketOrderInfoDto.guiderIdCardNumber }}
                    </el-descriptions-item>
                    <el-descriptions-item label="入园日期">
                        {{ record.ticketOrderInfoDto.useTime | dateCus('yyyy-MM-dd') }}
                    </el-descriptions-item>
                    <el-descriptions-item label="入园时段">
                        {{ record.ticketOrderInfoDto.tripStartTime }}-{{ record.ticketOrderInfoDto.tripEndTime }}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="1" :colon="false">
                </el-descriptions>
            </div>
            <div>
                <p class="table-title">游客信息：{{ record.orderTripUserDtoList.length }}人（含导游）</p>
                <el-table :data="orderTripUserDtoList" border max-height="500px">
                    <el-table-column label="序号" type="index" width="100"></el-table-column>
                    <el-table-column label="游客姓名" prop="tripUserName">
                        <template slot-scope="scope">
                            {{ scope.row.tripUserName }} {{ scope.row.isGuider ? '（导游）' : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="性别">
                        <template slot-scope="scope">
                            {{ scope.row.sex | formatDesc(sexs) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="证件类型">
                        <template slot-scope="scope">
                            {{ scope.row.certificateType | formatDesc(certificateList) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="证件号码" prop="certificateNo"></el-table-column>
                    <el-table-column label="手机号码" prop="tripUserMobile"></el-table-column>
                </el-table>
            </div>
        </div>
        <el-footer class="footer-bar" v-if="showFooter">
            <div class="footer-buttons">
                <el-button type="primary" style="width: 110px" plain
                           v-if="[74,75].includes(record.ticketOrderInfoDto.orderStatus)"
                           @click="handleToExamine(0)"
                           :loading="loading">拒绝
                </el-button>
                <el-button type="primary" style="width: 110px;margin-left: 10px"
                           v-if="record.ticketOrderInfoDto.orderStatus===74"
                           @click="handleToExamine(1)" :loading="loading">通过
                </el-button>
                <el-button type="primary" style="width: 110px;margin-left: 10px"
                           v-if="record.ticketOrderInfoDto.orderStatus===75" :loading="loading"
                           @click="handleConfirm">
                    确认
                </el-button>
            </div>
        </el-footer>
    </el-main>
</template>
<script>
import {defineComponent} from 'vue'
import {
    confirmTicketCustomerOrderApi,
    getTicketCustomerOrderDetailApi, reconfirmTicketCustomerOrderApi,
    refuseTicketCustomerOrderApi
} from "@/www/urls/zslManage";
import {certificateList, customerServiceInterventionOrderStatus, sexs} from '@/data/creditRating'

export default defineComponent({
    data() {
        return {
            loading: false,
            record: undefined,
            customerServiceInterventionOrderStatus,
            sexs,
            certificateList,
            type: 0
        }
    },
    computed: {
        showFooter() {
            return this.record && this.type != 0 && [74, 75].includes(this.record.ticketOrderInfoDto.orderStatus)
        },
        orderTripUserDtoList() {
            return this.record.orderTripUserDtoList.sort((a) => a.isGuider ? -1 : 1)
        }
    },
    mounted() {
        this.getData(this.$route.query.id)
        this.type = this.$route.query.type
    },
    methods: {
        async getData(id) {
            try {
                this.loading = true
                const ret = await getTicketCustomerOrderDetailApi({id})
                if (ret.success) {
                    this.record = {
                        ...ret.data,
                        ticketOrderInfoDto: {
                            ...ret.data.ticketOrderInfoDto,
                            customerCreateInfo: ret.data.ticketOrderInfoDto.customerCreateInfo ? JSON.parse(ret.data.ticketOrderInfoDto.customerCreateInfo) : {}
                        }
                    }
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleToExamine(flg) {
            try {
                this.loading = true;
                let refuse;
                if (flg === 0) {
                    const {value} = await this.$prompt('拒绝通过该项审核申请', '拒绝审核', {
                        inputPlaceholder: '请输入拒绝理由',
                        inputType: 'textarea',
                        maxlength: 10,
                        showWordLimit: true,
                        inputValidator(val) {
                            if (!val)
                                return '请输入拒绝理由'
                            if (val.length > 200)
                                return '限制输入200个字符'
                        }
                    })
                    refuse = value;
                } else {
                    await this.$confirm('确定通过该项审核申请', '通过审核')
                }
                let fn = flg === 0 ? refuseTicketCustomerOrderApi : confirmTicketCustomerOrderApi;
                const ret = await fn({
                    id: this.record.ticketOrderInfoDto.id,
                    refuse
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.getData(this.record.ticketOrderInfoDto.id);
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleConfirm() {
            try {
                this.loading = true;
                await this.$confirm('', '确认提交');
                const ret = await reconfirmTicketCustomerOrderApi({
                    id: this.record.ticketOrderInfoDto.id
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.getData(this.record.ticketOrderInfoDto.id)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
})
</script>


<style scoped lang="scss">
.eTrip-section-cont {
    padding: 20px;
    background-color: #fff;

    h3 {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 28px;
    }

    &::v-deep {
        .el-descriptions-item__label, .el-descriptions-item__content {
            font-size: 14px;
            color: #333333;
            font-weight: 400;
        }
    }

    .table-title {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        font-weight: 400;
        margin: 20px 0 10px 0;
    }


}

.footer-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: flex-end;
    box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.06);
    width: calc(100% + 60px);
    height: 100%;
    margin-left: -30px;
    padding: 0 30px;
}
</style>
