<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont credit-points-list">
            <com-list-search>
                <div slot="list-search-before">
                    <el-form :inline="true" size="small">
                        <el-form-item>
                            <el-radio-group v-model="form.orderStatus">
                                <el-radio-button label="-1">
                                    全部
                                </el-radio-button>
                                <el-radio-button :label="vm.value"
                                                 v-for="vm in customerServiceInterventionOrderStatus"
                                                 :key="vm.value">
                                    {{ vm.label }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker placeholder="入园日期" type="daterange" v-model="form.useTime"
                                            clearable start-placeholder="入园开始日期"
                                            end-placeholder="入园结束日期"></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="旅行社名称" v-model="form.merchantName" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="操作员姓名" v-model="form.createUserName" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="操作员手机号" v-model="form.createUserMobile" clearable></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="list-search-after">
                    <el-button type="primary" plain @click="handleReset" :loading="loading">重置</el-button>
                    <el-button type="primary" @click="search()" :loading="loading">搜索</el-button>
                </div>
            </com-list-search>
            <div class="user-list-table el-table_border_none" style="margin-top: 10px">
                <el-table class="table-info" border :data="tabData.data" style="width: 100%">
                    <el-table-column width="120" label="状态">
                        <template slot-scope="scope">
                            <span v-if="[74,75,76].includes(scope.row.orderStatus)"
                                  :class="scope.row.orderStatus | formatDesc(customerServiceInterventionOrderStatus,'value','color')">
                            {{ scope.row.orderStatus | formatDesc(customerServiceInterventionOrderStatus) }}
                            </span>
                            <span v-else>已通过</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="发起时间" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.createDate | dateCus('yyyy-MM-dd hh:mm') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="订单号" prop="ticketOrderNo" width="100"></el-table-column>
                    <el-table-column label="旅行社名称" prop="merchantName"></el-table-column>
                    <el-table-column label="团队编号" width="160" prop="groupNo"></el-table-column>
                    <el-table-column label="操作员姓名/手机" width="160">
                        <template slot-scope="scope">
                            <p>{{ scope.row.createUserName }}</p>
                            <p>{{ scope.row.createUserMobile }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="导游姓名/手机" width="160">
                        <template slot-scope="scope">
                            <p>{{ scope.row.guiderName }}</p>
                            <p>{{ scope.row.guiderMobile }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="入园日期/时段" width="160">
                        <template slot-scope="scope">
                            <p>{{ scope.row.useTime | dateCus('yyyy-MM-dd') }}</p>
                            <p>{{ scope.row.tripStartTime }}-{{ scope.row.tripEndTime }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="团队人数" width="160" prop="tripPersonNum"></el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="160">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="next(scope.row, 0)">
                                查看
                            </el-link>
                            <el-link v-if="scope.row.orderStatus===75" class="ml10"
                                     type="primary"
                                     @click="next(scope.row, 1)">
                                确认
                            </el-link>
                            <el-link v-if="scope.row.orderStatus===74" class="ml10"
                                     type="danger"
                                     @click="next(scope.row, 1)">
                                审核
                            </el-link>
                            <el-link v-if="scope.row.orderStatus===74" :actionLoading="actionLoading" class="ml10"
                                     type="warning"
                                     @click="handleRepeatGroupCheck(scope.row)">查重
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-page-number" v-if="tabData.total>10">
                    <div class="block">
                        <el-pagination
                            @current-change="handelPageChange"
                            :current-page.sync="form.pageIndex"
                            :page-size="form.pageSize"
                            layout="total, prev, pager, next"
                            :total="tabData.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </el-main>
</template>

<script>
import {defineComponent} from 'vue'
import comListSearch from "@/www/components/comListSearch/index.vue";
import lookImages from "@/components/look-images/index.vue";
import SettlementForm from "@/www/pages/zslManage/bill/list/settlement-form.vue";
import QuantityDetailsDialog from "@/www/pages/zslManage/bill/list/quantity-details-dialog.vue";
import {customerServiceInterventionOrderStatus} from '@/data/creditRating'
import {getListTicketCustomerOrderinfoListApi, repeatGroupCheckApi} from "@/www/urls/zslManage";
import dayjs from "dayjs";

export default defineComponent({
    name: "interventionOrders",
    components: {
        QuantityDetailsDialog,
        SettlementForm,
        lookImages,
        comListSearch,
    },
    data() {
        return {
            loading: false,
            actionLoading: false,
            customerServiceInterventionOrderStatus,
            form: {
                pageSize: 10,
                pageIndex: 1,
                orderStatus: -1,
                useTime: [],
                createUserName: undefined,
                createUserMobile: undefined
            },
            tabData: {
                total: 0,
                data: []
            },

        }
    },
    filters: {
        getStatusItem(status, key) {
            return (customerServiceInterventionOrderStatus.find((item) => item.value === status) || customerServiceInterventionOrderStatus.find(({label}) => label === '已通过'))[key]
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        handleReset() {
            this.form = {
                pageSize: 10,
                pageIndex: 1,
                orderStatus: -1,
                useTime: [],
                createUserName: undefined,
                createUserMobile: undefined
            }
            this.search()
        },
        async search() {
            try {
                this.loading = true
                const ret = await getListTicketCustomerOrderinfoListApi({
                    ...this.form,
                    orderStatus: this.form.orderStatus == -1 ? undefined : this.form.orderStatus,
                    startUseTime: this.form.useTime.length ? dayjs(this.form.useTime[0]).startOf('d').valueOf() : undefined,
                    endUseTime: this.form.useTime.length ? dayjs(this.form.useTime[1]).endOf('d').valueOf() : undefined,
                    useTime: undefined
                })
                if (ret.success) {
                    this.tabData = ret.data
                }
            } finally {
                this.loading = false
            }
        },
        handelPageChange(page) {
            this.form.pageIndex = page;
            this.search()
        },
        next(record, type) {
            this.$router.push(`/interventionOrderDetail?id=${record.id}&type=${type}`)
        },
        async handleRepeatGroupCheck({id}) {
            try {
                this.actionLoading = true
                const ret = await repeatGroupCheckApi({id})

                if (ret.success) {
                    this.$alert(`与订单${ret.data.repeatOrderNo}重复超${ret.data.repeatRatio}%`, '提示', {
                        type: 'warning'
                    })
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.actionLoading = false
            }
        }
    }
})
</script>
<style scoped lang="scss">
.credit-points-list::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small,
    .common-list-search .list-search-before .el-form-item__content {
        width: auto;
    }

    .common-list-search .list-search-after {
        width: 200px;
    }
}

</style>
