<style lang="scss" scoped>

</style>
<template>
    <div>
        <dialog-middle-section
            title="上传确认单"
            :width="500"
            :show="form.show"
            @onClose="onClose"
            is-footer>
            <div slot="dialog-section">
                <image-upload
                    @update:imageUploadCompleted="confirmationsImage"
                    :max="1"
                    accept="image/*, application/pdf"
                    height="80px"
                    width="104px"
                    :images="imgUrl"
                    :isFilePdf="isPdf">
                </image-upload>
            </div>
            <div slot="dialog-footer">
                <el-button @click="onClose">取消</el-button>
                <el-button
                    @click="submit"
                    :disabled="image ? false : true"
                    :loading="loading"
                >确认</el-button>
            </div>
        </dialog-middle-section>
    </div>
</template>

<script type="text/ecmascript-6">
import dialogMiddleSection from '@/components/dialog-middle-section/index'
import {fileIsPdf} from "@/common/js/common";
import imageUpload from '@/components/imageUpload'

export default {
    //定义模版数据
    data() {
        return {
            isPdf: false,
            image: '',
            loading: false
        }
    },
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
        index: {
            type: Number,
            default: null
        }
    },
    components: {
        dialogMiddleSection,
        imageUpload
    },
    //计算属性
    computed: {
        imgUrl(){
            let data = [{imagesUrl: this.form.recordManagerPhoto}]
            return data
        }
    },
    //主件被加载完成
    mounted: function () {
    },
    //定义事件方法
    methods: {
        onClose() {
            this.$emit('onClose')
        },
        confirmationsImage(item) {
            let {
                imageUrl
            } = item[0]
            this.isPdf = fileIsPdf(imageUrl);
            this.image = imageUrl
        },
        async submit() {
            this.loading = true
            const ret = await this.http('/galaxyZslTicketOrderApi/updateTicketCheckRecordManager', {
                id: this.form.id,
                recordManagerPhoto: this.image
            }, 'POST')
            if (ret.success && ret.data) {
                this.$emit("confirmationsSuccess")
            }
            this.loading = false
        }
    },
    //监听模版变量
    watch: {}

}
</script>
