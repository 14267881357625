<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table
            :data="listArr"
            v-loading="loading"
            border
            style="width: auto;display: inline-block"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80">
            </el-table-column>
            <el-table-column
                prop="orgName"
                label="旅行社名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="actualOrderTouristNum"
                label="实际入园游客数"
                width="180">
            </el-table-column>
            <el-table-column
                prop="shouldOrderTouristNum"
                label="游客总数"
                width="180">
            </el-table-column>
            <el-table-column
                label="入园数占比"
                width="180">
                <template slot-scope="scope">
                    <span>{{scope.row.actualOrderTouristRate + '%'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="整体占比"
                width="180">
                <template slot-scope="scope">
                    <span>{{scope.row.actualOrderTouristTotalRate + '%'}}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">

import {decimalMul} from "@/common/js/decimal";

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {
        listArr() {
            let data = this.list.map(item => {
                if (item.actualOrderTouristRate > 0) item.actualOrderTouristRate = decimalMul(item.actualOrderTouristRate, 100)
                else item.actualOrderTouristRate = 0
                if (item.actualOrderTouristTotalRate > 0) item.actualOrderTouristTotalRate = decimalMul(item.actualOrderTouristTotalRate, 100)
                else item.actualOrderTouristTotalRate = 0
                return item
            })
            return data
        }
    },
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
