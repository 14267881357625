/**
 * 审核状态
 */
export const auditStateList = [
    {
        value: 0,
        label: '待提交',
    },
    {
        value: 1,
        label: '待审核',
    },
    {
        value: 2,
        label: '已审核',
    },
    {
        value: 3,
        label: '已拒绝',
    },
]

/**
 * 状态 上线状态
 */
export const onlineStateList = [
    {
        value: '1',
        label: '已上线'
    },
    {
        value: '2',
        label: '手动下线7天'
    },
    {
        value: '0',
        label: '永久下线'
    },
    {
        value: '3',
        label: '第1次未履约下线'
    },
    {
        value: '4',
        label: '第2次未履约下线'
    },
    {
        value: '5',
        label: '第3次未履约下线'
    },
    {
        value: '6',
        label: '等级不佳下线7天'
    },
    {
        value: '7',
        label: '未履约等原因下线'
    },
    {
        value: '8',
        label: '第4次及以上未履约下线'
    },
]

/**
 * 状态 签约信息
 */
export const signStatusList = [
    {
        value: 0,
        label: '未签约'
    },
    {
        value: 1,
        label: '已签约'
    },
]
