<template>
    <el-main class="com-eTrip-section booking-and-modification-rules">
        <div class="eTrip-section-cont">
            <el-form size="small" :model="form" ref="formRef" v-loading="loading"
                     :disabled="!editable">
                <fieldset>
                    <h4>1、未履约次数定义</h4>
                    <el-form-item label-width="300px"
                                  prop="unfulf.cancel"
                                  :rules="[{required:true,message:'请输入'}]">
                        <span class="mr10" slot="label">任意旅行社，每有责取消1次，记为未履约</span>
                        <ApiSelect filterable clearable
                                   :options="numOptions"
                                   v-model="form.unfulf.cancel"></ApiSelect>
                        <span class="ml10">次</span>
                    </el-form-item>
                    <el-form-item label-width="290px"
                                  prop="unfulf.nonPerformance"
                                  :rules="[{required:true,message:'请输入'}]">
                        <span class="mr10" slot="label">任意旅行社，每爽约1次，记为未履约</span>
                        <ApiSelect filterable clearable :options="numOptions"
                                   v-model="form.unfulf.nonPerformance"></ApiSelect>
                        <span class="ml10">次</span>
                    </el-form-item>
                </fieldset>
                <fieldset>
                    <h4>2、未履约惩罚</h4>
                    <div style="display:flex;align-items: center">
                        <span class="mb15 mr10">近30天，任意旅行社未履约次数达</span>
                        <el-form-item prop="punish.nonPerformanceCount"
                                      :rules="[{required:true,message:'请输入'}]">
                            <ApiSelect filterable clearable :options="numOptions"
                                       v-model="form.punish.nonPerformanceCount"></ApiSelect>
                        </el-form-item>
                        <span class="mb15  ml10">次，即刻进入下线状态</span>
                    </div>
                    <div style="display:flex;align-items: center">
                        <span class="mb15 mr10">第1次未履约下线，时长为</span>
                        <el-form-item prop="punish.oneOffline"
                                      :rules="[{required:true,message:'请输入'}]">
                            <el-input-number style="width: 200px" :min="0" :max="99"
                                             v-model="form.punish.oneOffline"></el-input-number>
                        </el-form-item>
                        <span class="mb15  ml10">天</span>
                    </div>
                    <div style="display:flex;align-items: center">
                        <span class="mb15 mr10">第2次未履约下线，时长为</span>
                        <el-form-item prop="punish.towOffline"
                                      :rules="[{required:true,message:'请输入'}]">
                            <el-input-number style="width: 200px" :min="0" :max="99"
                                             v-model="form.punish.towOffline"></el-input-number>
                        </el-form-item>
                        <span class="mb15  ml10">天</span>
                    </div>
                    <div style="display:flex;align-items: center">
                        <span class="mb15 mr10">第3次未履约下线，时长为</span>
                        <el-form-item prop="punish.thirdOffline"
                                      :rules="[{required:true,message:'请输入'}]">
                            <el-input-number style="width: 200px" :min="0" :max="99"
                                             v-model="form.punish.thirdOffline"></el-input-number>
                        </el-form-item>
                        <span class="mb15  ml10">天</span>
                    </div>
                    <div style="display:flex;align-items: center">
                        <span class="mb15 mr10">第4次及以上未履约，下线至当年年底，即{{ dayjs().valueOf() | dateCus('yyyy年12月31日')}}</span>
                    </div>
                </fieldset>
            </el-form>
        </div>
        <el-footer class="footer-bar">
            <p>未履约规则编辑</p>
            <el-button type="primary" style="width: 200px" v-if="editable" @click="handleSubmit" :loading="loading">
                保存
            </el-button>
            <el-button type="primary" style="width: 200px" v-else @click="handleEdit">编辑</el-button>
        </el-footer>
    </el-main>
</template>
<script>
import {defineComponent} from 'vue'
import {creditRatingOptions} from '@/data/creditRating'
import {createCreditRuleApi, selectCreditRuleApi, updateCreditRuleApi} from "@/www/urls/credit";
import dayjs from "dayjs";

export default defineComponent({
    name: "unfulfilledRules",
    data() {
        return {
            loading: false,
            editable: false,
            creditRatingOptions,
            numOptions: new Array(200).fill('').map((item, i) => ({
                value: i / 2 + 0.5,
                label: `${i / 2 + 0.5}`
            })),
            form: {
                unfulf: {
                    cancel: undefined,//有责取消次数
                    nonPerformance: undefined//未履约次数
                },
                punish: {
                    nonPerformanceCount: undefined,//  未履约下线次数
                    oneOffline: undefined,//   第一次
                    towOffline: undefined,//  第二次
                    thirdOffline: undefined//  第三次
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        dayjs,
        async init() {
            const ret = await this.getData()
            if (ret && this.$route.query.type === '1')
                this.editable = true;
        },
        async getData() {
            try {
                const ret = await selectCreditRuleApi();
                if (ret.success) {
                    let f = {};
                    for (let key in ret.data) {
                        try {
                            f[key] = JSON.parse(ret.data[key]);
                        } catch (err) {
                            f[key] = ret.data[key]
                        }
                    }
                    this.credit_rule = f;
                    if (this.credit_rule.id)
                        this.form = Object.assign({}, this.form, this.credit_rule);
                    return true
                } else {
                    this.$message.error(ret.errors[0].message)
                    return false
                }
            } catch (err) {
                this.$message.error(err.toString())
                return false
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            this.loading = true
            try {
                let f = {};
                for (let key in this.form) {
                    if (typeof this.form[key] === 'object')
                        f[key] = JSON.stringify(this.form[key]);
                    else
                        f[key] = this.form[key]
                }
                await this.$refs['formRef'].validate();
                await this.$confirm('保存后立即生效', '确认保存？')
                if (this.credit_rule)
                    await updateCreditRuleApi(f)
                else
                    await createCreditRuleApi(f)
                this.$message.success('保存成功！')
                this.editable = false;
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        handleEdit() {
            this.editable = true;
            this.getData();
        }
    }
})
</script>


<style scoped lang="scss">
.booking-and-modification-rules {
    fieldset {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 20px;
        padding-left: 45px;

        h4 {
            margin-bottom: 10px;
            margin-left: -25px;
        }

        span {
            font-size: 14px;
            color: #333;
        }

    }

    .footer-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.06);
        width: calc(100% + 60px);
        height: 100%;
        margin-left: -30px;
        padding: 0 30px;

    }
}
</style>
