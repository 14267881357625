<style lang="scss" scoped>

.search-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.list {
    display: flex;
    margin-bottom: 20px;
    width: fit-content;

    li {
        min-width: 110px;
        padding: 15px 20px;
        text-align: center;
        background: #F0F2F5;
        border-radius: 6px;
        margin-right: 10px;
    }

    .list-num {
        height: 40px;
        position: relative;
        font-size: 22px;
        display: flex;
        justify-content: space-between;

        .num-up {
            font-size: 16px;
            color: #EB7E65;
        }
    }

    .list-title {
        font-size: 14px;
        font-weight: normal;
        position: relative;
        display: inline;
    }
}
</style>


<template>
    <div>
        <div class="search-box">
            <search-date is-yesterday @update:dateBtn="handleDateBtn"></search-date>
            <excel-export-btn
                type="primary"
                size="small"
                title="导出报表"
                fileTitle="旅行社概况"
                :form="dateForm"
                url="/galaxyZslTicketOrderApi/exportGalaxyOrgInfoNum"
            >
            </excel-export-btn>
        </div>

        <div>
            <ul class="list" v-loading="loading">
                <li v-for="item in list" :key="item.value">
                    <div class="list-num">
                        {{ item.num }}
                        <p class="num-up" v-if="item.numUp > 0">+{{ item.numUp }}</p>
                    </div>
                    <h4 class="list-title">{{ item.label }}</h4>
                </li>
            </ul>
        </div>
        <ve-histogram
            :data="chartData"
            :settings="chartSettings"
            :extend="extend"
            :colors="bgColorArr">
        </ve-histogram>
    </div>

</template>

<script type="text/ecmascript-6">

import SearchDate from "./components/search-date/index";
import VeHistogram from 'v-charts/lib/histogram.common'
import http from "@/common/js/http";
import {latestWeekArr} from "@/common/js/dayjsDate";
import {uniqueArray} from '@/common/js/Utils'
import excelExportBtn from '@/components/excel-export-btn/index'

export default {
    name: '',
    data() {
        return {
            loading: false,
            form: {
                salesDate: latestWeekArr()
            },
            list: [
                {
                    value: 0,
                    label: '旅行社总数(家)'
                },
                {
                    value: 1,
                    label: '已签约旅行社(家)'
                },
                {
                    value: 2,
                    label: '未签约旅行社(家)'
                },
                {
                    value: 3,
                    label: '已认证导游数(人)'
                },
            ],

            bgColorArr: ['#319bf7'],
            chartSettings: {
                // showLine: ['中山陵陵寝预约']
            },
            chartData: {
                columns: ['注册地', '注册数量'],
                rows: []
            },
            extend: {
                series: {
                    type: 'bar',
                    barWidth: 20,
                    label: {show: true, position: "top", color: '#333',}
                }
            }
        }
    },
    components: {
        SearchDate,
        VeHistogram,
        excelExportBtn
    },
    computed: {
        dateForm(){
            let form = {
                startTime: this.form.salesDate[0],
                endTime: this.form.salesDate[1]
            }
            return form
        }
    },
    //主件被加载完成
    mounted() {
        this.init()
        this.zslOrgInfoNum()
    },
    methods: {

        init() {
            this.zslSalesNum()
        },
        handleDateBtn(dateArr) {
            this.form.salesDate = dateArr
            this.init()
        },
        // 获取旅行社数据概况
        async zslSalesNum() {
            this.loading = true
            const res = await http('/galaxyZslTicketOrderApi/getGalaxyOrgInfoNum', this.dateForm, 'POST')
            if (res.success && res.data) {
                let data = res.data
                let listData = this.list.map(item => {
                    switch (item.value) {
                        case 0:
                            item.num = data.orgTotalNum;
                            item.numUp = data.orgNumUp;
                            break;
                        case 1:
                            item.num = data.signedNum;
                            item.numUp = data.signedNumUp;
                            break;
                        case 2:
                            item.num = data.notSignedNum;
                            item.numUp = data.notSignedNumUp;
                            break;
                        case 3:
                            item.num = data.guideNum;
                            item.numUp = data.guideNumUp;
                            break;
                    }
                    return item
                })
                this.list = listData
            }
            this.loading = false
        },
        // 获取旅行社分布
        async zslOrgInfoNum() {
            const res = await http('/galaxyZslTicketOrderApi/getGalaxyOrgInfoNumForCity')
            if (res.success && res.data) {
                let otherNum = 0
                let listData = res.data.map(item => {
                    if (!item.cityName) {
                        item.cityName = '其他城市'
                        otherNum += item.num
                    }
                    return item
                })
                listData.forEach(item => {
                    if (item.cityName === '其他城市') item.num = otherNum
                })
                listData = uniqueArray(listData, 'cityName')
                let rowsData = listData.map(item => {
                    let obj = {
                        '注册地': item.cityName,
                        '注册数量': item.num
                    }
                    return obj
                })
                this.chartData.rows = rowsData
            }
        },
    },
    watch: {},
}
</script>
