<style lang="scss" scoped>
</style>
<template>
    <div>
        <el-button size="mini" @click="dialogVisible=true" type="primary">
            {{!content ? '添加备注' : '修改备注'}}
        </el-button>
        <el-dialog title="订单备注" :visible.sync="dialogVisible" append-to-body>
            <el-form :model="form">
                <el-input type="textarea"
                          :rows="4"
                          placeholder="请输入内容"
                          v-model="form.remark">
                </el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="loading">确 定</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                dialogVisible: false,
                loading: false,
                form: {
                    remark: ''
                }
            }
        },
        props: {
            id: String,
            content: String
        },
        mounted(){
            this.form.remark = this.content;
        },
        //定义事件方法
        methods: {
            async handleSubmit() {
                this.loading = true;
                const ret = await this.http('/galaxyZslTicketOrderApi/updateCompareRemark', {
                    ...this.form,
                    id: this.id
                }, 'POST');
                if (ret.success && ret.data) {
                    this.$emit('success')
                    this.dialogVisible = false;
                } else this.$message.error(ret.errors[0].message)
                this.loading = false;

            }
        },
        //监听模版变量
        watch: {
            content(n) {
                console.log(n);
                this.form.remark = this.content;
            }
        }

    }
</script>