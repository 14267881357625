<style lang="scss" scoped>

</style>


<template>

    <el-button
        :size="size"
        :type="type"
        @click="handleExport"
    >
        {{ title }}
    </el-button>

</template>

<script type="text/ecmascript-6">
import http from "@/common/js/http";

export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: '导出'
        },
        fileTitle: {
            type: String,
            default: ''
        },
        fileDate: {
            type: Number,
            default: 0
        },
        size: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        form: {
            type: Object,
            default: () => ({})
        },
        // 请求方式   POST   GET
        requestType: {
            type: String,
            default: 'POST'  //    POST   GET
        }
    },
    methods: {
        async handleExport() {
            this.loadingShow();
            try {
                let res = await http(this.url, this.form, this.requestType, true, 'blob')
                let d = this.filterDate(new Date().getTime(), 'yyyyMMddhhmmss');
                if(this.fileTitle) d = `${this.fileTitle}${d}`
                if(this.fileDate > 0) d = `${this.filterDate(this.fileDate, 'yyyyMMdd')}${this.fileTitle}`
                let a = document.createElement('a');
                a.download = `${d}.xls`;
                a.href = window.URL.createObjectURL(res);
                a.click();
            } catch (e) {
                this.messageError('下载失败')
            }
            this.loadingHide();
        }
    },
    watch: {},
}
</script>
