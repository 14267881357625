<template>
    <el-dialog title="未履约记录" :visible.sync="visible" width="60%">
        <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
            <p>旅行社名称：{{ travelAgencyInfo.companyName }}</p>
            <p><b>当前等级：{{ travelAgencyInfo.creditGrade | formatDesc(creditRatingOptions) }}</b></p>
            <p><b>近30天未履约：{{ travelAgencyInfo.punishCount }}次</b></p>
        </div>
        <el-table border :data="tableData.data" height="calc(100vh - 500px)">
            <el-table-column label="日期时间" width="100">
                <template slot-scope="scope">
                    {{ scope.row.createDate | dateCus('yyyy-MM-dd hh:mm') }}
                </template>
            </el-table-column>
            <el-table-column label="未履约类型" prop="title"></el-table-column>
            <el-table-column label="次数" prop="num"></el-table-column>
            <el-table-column label="订单号" prop="orderNo"></el-table-column>
            <el-table-column label="入园日期" prop="tripDay"></el-table-column>
            <el-table-column label="入园时间" prop="timeSlot"></el-table-column>
            <el-table-column label="团队人数" prop="groupPeopleNum"></el-table-column>
        </el-table>
        <div style="display: flex;justify-content: flex-end">
            <el-pagination :total="tableData.total" :page-size="form.pageSize"
                           :current-page="form.pageIndex"
                           :pager-count="5"
                           @current-change="handlePageChange"></el-pagination>
        </div>
    </el-dialog>
</template>
<script>
import {defineComponent} from 'vue'
import {getCreditPunishListApi} from "@/www/urls/credit";
import {creditRatingOptions} from '@/data/creditRating'

export default defineComponent({
    name: "NonPerformanceRecordsDialog",
    data() {
        return {
            creditRatingOptions,
            loading: false,
            visible: false,
            travelAgencyInfo: {},
            tableData: {
                total: 0,
                data: []
            },
            form: {
                pageIndex: 1,
                pageSize: 10
            },
        }
    },
    methods: {
        show(record) {
            this.travelAgencyInfo = record;
            this.visible = true;
            this.form.id = record.creditId;
            this.getCreditPunishList();
        },
        async getCreditPunishList() {
            try {
                this.loading = true
                const ret = await getCreditPunishListApi(this.form);
                if (ret.success) {
                    this.tableData = ret.data;
                }
            } finally {
                this.loading = false
            }
        },
        handlePageChange(page) {
            this.form.pageIndex = page
            this.getCreditPunishList();
        }
    }
})
</script>

<style scoped lang="scss">

</style>
