<style lang="scss" scoped>
    .eTrip-section-cont {
        padding: 20px 0;
    }

    .eTrip-footer-cont {
        display: flex;
        justify-content: flex-end;
    }

    /deep/ .common-list-search .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }

    /deep/ .list-search-after {
        div {
            display: flex;
            justify-content: space-between;
        }
        width: 300px;
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="">
                                <el-date-picker v-model="form.createDate"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="流水开始"
                                                end-placeholder="流水结束">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="resetForm()">重置</el-button>
                        <el-button type="primary" @click="handleSearch" :loading="loading">筛选</el-button>
                        <el-button type="primary" @click="handleExport" :loading="loading">导出</el-button>
                    </div>
                </com-list-search>
                <el-table :data="result.data" style="width: 100%;margin-bottom: 40px">
                    <el-table-column label="结汇时间">
                        <template slot-scope="scope">
                            {{scope.row.createDate | dateCus}}
                        </template>
                    </el-table-column>
                    <el-table-column label="结汇金额">
                        <template slot-scope="scope">
                            {{scope.row.settleAmount | currency}}
                        </template>
                    </el-table-column>
                    <el-table-column label="对应批次">
                        <template slot-scope="scope">
                            <p v-for="vm in scope.row.scanAccountNumberList" :key="vm">
                                {{vm | dateCus('yyyy/MM/dd')}}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="预付款剩余金额">
                        <template slot-scope="scope">
                            {{scope.row.surplusAmount | currency}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作人员">
                        <template slot-scope="scope">
                            {{scope.row.refName}} | {{scope.row.createByName}}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <el-pagination @current-change="handlePaginationChange" :current-page.sync="form.pageIndex"
                               :page-size="form.pageSize" layout="total, prev, pager, next" :total="result.total">
                </el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../../components/comListSearch/index.vue'
    import {combinationForm} from '@/common/js/common'

    export default {
        //定义模版数据
        data() {
            return {
                loading: false,
                form: {
                    pageIndex: 1,
                    pageSize: 10
                },
                result: {
                    data: [],
                    total: 0
                }
            }
        },
        components: {comListSearch},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {
            this.getData()
        },
        //定义事件方法
        methods: {
            getForm() {
                let form = {
                    ...this.form
                }
                if (form.createDate) {
                    form.createDateStart = +new Date(form.createDate[0])
                    form.createDateEnd = +new Date(form.createDate[1]) + (24 * 60 * 60 * 1000) - 1000
                    delete form.createDate
                }
                return combinationForm(form);
            },
            async getData() {
                this.loading = true;
                const ret = await this.http('/galaxyZslTicketOrderApi/scanRecordPageList', this.getForm(), 'POST')
                if (ret.success && ret.data) {
                    this.result = ret.data
                } else this.result = []
                this.loading = false;
            },
            //导出
            async handleExport() {
                this.loading = true;
                const ret = await this.http('/galaxyZslTicketOrderApi/getScanRecordList', this.getForm(), 'POST', true, 'blob')
                this.loading = false;
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = `结汇列表-${d}.xls`;
                a.href = window.URL.createObjectURL(ret);
                a.click();
            },
            //分页
            handlePaginationChange(page) {
                this.form.pageIndex = page;
                this.getData();
            },
            //重置
            resetForm() {
                this.form = {
                    pageIndex: this.form.pageIndex,
                    pageSize: this.form.pageSize
                }
            },
            //筛选
            handleSearch() {
                this.form.pageIndex = 1;
                this.getData();
            }
        },
        //监听模版变量
        watch: {}

    }
</script>