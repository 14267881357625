<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table
            :data="regionalList"
            v-loading="loading"
            border
            style="width: auto;display: inline-block"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80">
            </el-table-column>
            <el-table-column
                prop="cityName"
                label="城市"
                width="180">
            </el-table-column>
            <el-table-column
                prop="num"
                label="数量"
                width="180">
            </el-table-column>
            <el-table-column
                label="整体占比"
                width="180">
                <template slot-scope="scope">
                    {{scope.row.cityNumRate + '%'}}
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">
import {decimalMul} from '@/common/js/decimal/index'

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {
        regionalList(){
            // let num = 0
            // let otherNum = 0
            let list = this.list.map(item => {
                if(!item.cityName) {
                    item.cityName = '其他城市'
                }
                if(item.cityNumRate > 0) item.cityNumRate = decimalMul(item.cityNumRate, 100)
                else item.cityNumRate = 0
                return item
            })
            return list
        }
    },
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
