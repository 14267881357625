<style lang="scss" scoped>
    .sub-container {
        display: flex;
        background-color: #fff;
        margin-top: 10px;
        padding: 15px;
        margin-bottom: 40px;
        h3 {
            font-size: 14px;
            color: #333;
            margin-bottom: 10px;

        }
        & > div {
            margin-right: 40px;
        }
        .table-header {
            background-color: #DCDFE6;
        }
    }
</style>
<template>

    <div class="sub-container">
        <div>
            <h3>900游结算金额（数据来源：订单列表）</h3>
            <el-table border
                      size="small"
                      :data="detail.subTotalAccountList"
                      header-row-class-name="table-header"
                      style="max-width: 600px">
                <el-table-column width="140px" prop="stationName" label="站点名称/总计">
                </el-table-column>
                <el-table-column prop="resourceName" label="资源名称">
                </el-table-column>
                <el-table-column prop="scanMum" label="检票份数">
                </el-table-column>
                <el-table-column prop="scanAmount" label="结算金额">
                </el-table-column>
            </el-table>
        </div>
        <div>
            <h3>中山陵结算金额（数据来源：手工检票记录）</h3>
            <el-table border
                      :data="list1"
                      size="small"
                      header-row-class-name="table-header"
                      style="max-width: 300px">
                <el-table-column>
                    <template>
                        <span>总计</span>
                    </template>
                </el-table-column>
                <el-table-column label="检票份数">
                    <template slot-scope="scope">
                        <edit-input name="manualNum"
                                    :type="2"
                                    :id="scope.row.id"
                                    v-model="scope.row.manualNum"
                                    :value="scope.row.manualNum"></edit-input>
                    </template>
                </el-table-column>
                <el-table-column
                        label="结算金额">
                    <template slot-scope="scope">
                        <edit-input name="manualAmount"
                                    :type="2"
                                    :id="scope.row.id"
                                    v-model="scope.row.manualAmount"
                                    :value="scope.row.manualAmount"></edit-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div>
            <h3>对账单确认结算金额</h3>
            <el-table border :data="list2"
                      size="small"
                      header-row-class-name="table-header"
                      style="max-width: 300px">
                <el-table-column>
                    <template>
                        <span>总计</span>
                    </template>
                </el-table-column>
                <el-table-column label="确认份数">
                    <template slot-scope="scope">
                        <edit-input name="confirmNum"
                                    :type="2"
                                    :id="scope.row.id"
                                    v-model="scope.row.confirmNum"
                                    :value="scope.row.confirmNum">
                        </edit-input>
                    </template>
                </el-table-column>
                <el-table-column label="确认结算金额"
                                 width="120">
                    <template slot-scope="scope">
                        <edit-input name="confirmAmount"
                                    :type="2"
                                    :id="scope.row.id"
                                    v-model="scope.row.confirmAmount"
                                    :value="scope.row.confirmAmount">
                        </edit-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import EditInput from './edit-input.vue'

    export default {
        //定义模版数据
        data() {
            return {
                list1: [],
                list2: []
            }
        },
        props: {
            detail: {
                type: Object,
                value: {}
            }
        },
        //计算属性
        computed: {},
        components: {EditInput},
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {
            getConfirmData() {
                return {
                    manualNum: this.list1[0].manualNum,
                    manualAmount: this.list1[0].manualAmount,
                    confirmNum: this.list2[0].confirmNum,
                    confirmAmount: this.list2[0].confirmAmount
                }
            }
        },
        //监听模版变量
        watch: {
            detail() {
                const {id, manualAmount = 0, manualNum = 0, confirmAmount = 0, confirmNum = 0} = this.detail.accountManagerDto || {}
                this.list1 = JSON.parse(JSON.stringify([{id, manualAmount, manualNum}]));
                this.list2 = JSON.parse(JSON.stringify([{id, confirmAmount, confirmNum}]));
            }
        }
    }
</script>
